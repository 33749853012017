export default class CampaignCheckout {

    constructor() {
        
        this.id = 0;
        this.campaignTypeId = 0;
        this.minimumDonationValue = 0;
        this.allowUsdDonation = false;
    }

}