<template>
  <v-dialog
    v-model="dialog"
	width="unset"
    persistent
    hide-orverlay="true"
    :max-width="monthsToShow*300"
  >
    <template v-slot:activator="{ on }">
      <div class="p-relative" :class="width">
        <a href="javascript:;" class="clean-date" @click="clearDate">
          <Icon :name="links.icon" :size="16" colorFont="#80849A" />
        </a>
        <v-text-field
          v-on="on"
          :label="textLabel"
          prepend-icon="event"
          readonly
          :id="'datepicker-trigger' + index"
          :placeholder="placeHolder"
          :value="formatDates"
          ref="input"
        ></v-text-field>
        <v-flex justify-space-between d-flex>
          <label
            v-if="showValidationMessage"
            class="d-flex justify-end msg-validation"
            :key="showValidationMessage"
          >{{invalidInputMessage}}</label>
          <label
            v-if="helpLabel && !showValidationMessage"
            class="d-flex justify-end labelHelp"
          >{{helpLabel}}</label>
        </v-flex>
      </div>
    </template>
    <div class="datepicker-trigger">
      <AirbnbStyleDatepicker
        :trigger-element-id="'datepicker-trigger' + index"
        :mode="'range'"
        :inline="true"
        :fullscreen-mobile="true"
        :date-one="dateFrom"
        :date-two="dateTo"
        :min-date="min"
        :end-date="max"
        :monthsToShow="monthsToShow"
        :showShortcutsMenuTrigger="false"
        @date-one-selected="val => { selectedDateFrom = val }"
        @date-two-selected="val => { selectedDateTo = val }"
        @cancelled="onCancelMethod"
        @apply="onApplyMethod"
      />
    </div>
  </v-dialog>
</template>

<script type="plain/text">
import format from "date-fns/format";
import ValidatableInput from "@/components/base/ValidatableInput.vue";

export default {
  extends: ValidatableInput,
  props: {
    date: "",
    monthsToShow: {
      type: Number,
      default: 2
    },
    helpLabel: "",
    placeHolder: "",
    textLabel: "",
    allowSingleDate: true,
    index: 0,
    maxWidth: null,
    min: null,
    max: null,
    width: {
      default: "w-100"
    }
  },
  data() {
    return {
      dateFormat: "DD/MM/YYYY",
      dateFrom: "",
      // dateFrom: new Date(),
      dateTo: "",
      selectedDateFrom: "",
      selectedDateTo: "",
      dialog: false,
      links: {
        icon: "fal fa-times",
        text: "CleanDate"
      }
    };
  },
  computed: {
    formatDates: function() {
      if (this.validate()) {
        this.dateFrom = this.value[0];
        this.dateTo = this.value[1];
      }
      let formattedDates = "";
      if (this.dateFrom) {
        formattedDates = format(this.dateFrom, this.dateFormat);
      }
      if (this.dateTo) {
        formattedDates += " - " + format(this.dateTo, this.dateFormat);
      }

      return formattedDates;
    }
  },
  methods: {
    onApplyMethod() {
      let dateOne;
      let dateTwo;
      if (
        this.allowSingleDate ||
        (this.selectedDateFrom &&
          this.selectedDateTo &&
          this.selectedDateFrom != this.selectedDateTo)
      ) {
        if (this.selectedDateFrom === this.selectedDateTo) {
          dateOne = this.selectedDateFrom;
        } else if (!this.selectedDateFrom && this.selectedDateTo) {
          dateOne = this.selectedDateTo;
        } else {
          dateOne = this.selectedDateFrom;
          dateTwo = this.selectedDateTo;
        }

        this.dateFrom = dateOne;
        this.dateTo = dateTwo;
        this.$emit("input", [this.dateFrom, this.dateTo]);
        this.dialog = false;
        return this.formatDates;
      }
    },
    onCancelMethod() {
      this.dialog = false;
    },
    validate() {
      if (this.allowSingleDate) {
        if (!this.value || !(this.value instanceof Array)) {
          return false;
        }
        /*if (!this.value[0]) {
          return false;
        }*/
      } else {
        if (
          !this.value ||
          !(this.value instanceof Array) ||
          this.value.length != 2
        ) {
          return false;
        }

        /*if (!this.value[0] || !this.value[1]) {
          return false;
        }*/
      }

      return true;
    },
    clearDate() {
      this.dateFrom = "";
      this.dateTo = "";
      this.$emit("input", null);
    }
  },
  watch: {
    value() {
      if (this.validate()) {
        this.dateFrom = this.value[0];
        this.dateTo = this.value[1];
      }
    }
  }
};
</script>
