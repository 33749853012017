import Entity from "@/scripts/models/entity.model";
import File from "@/scripts/models/file.model";

export default class Company{

    constructor(){

        this.id = 0;
        this.entity = new Entity();
        this.companyGroupId = 0;
        this.employeeQuantity = 0;
        this.file = new File();
        this.fileCompanyList = [];

    }
}