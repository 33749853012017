<template>
  <div class="text-xs-left input-comp">
    <v-flex d-flex justify-space-between w-100>
      <label v-if="textLabel" class="d-flex">{{textLabel}}</label>
      <label v-if="labelCharLimit > 0" class="d-flex justify-end">{{labelCharLimit - value.length}}</label>
    </v-flex>
    <div class="inside-input search">
      <input
        @blur="$emit('blur', $event.target.value)"
        @input="$emit('input', $event.target.value)"
        :type="type"
        :value="value"
        :maxlength="labelCharLimit"
        :placeholder="placeHolder"
      />
      <template class="loader" v-if="showLoader">
				<svg
					class="loader-input"
					version="1.1"
					id="loader-1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					width="40px"
					height="40px"
					viewBox="0 0 40 40"
					enable-background="new 0 0 40 40"
					xml:space="preserve"
				>
					<path
						opacity="0.7"
						fill="#C3C6D5"
						d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
					/>
					<path
						fill="#02ACB4"
						d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
					>
						<animateTransform
							attributeType="xml"
							attributeName="transform"
							type="rotate"
							from="0 20 20"
							to="360 20 20"
							dur="0.5s"
							repeatCount="indefinite"
						/>
					</path>
				</svg>
			</template>
      <i v-else class="fal fa-search pr-2" @click="search()"></i>
      <template  v-if="infoVisible">
        <i class="fal fa-info-circle"></i>
        <div>
          <h5>{{titleInfo}}</h5>
          <p>{{subTitleInfo}}</p>
        </div>
      </template>
    </div>
    <v-flex justify-space-between d-flex>
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
      >{{invalidInputMessage}}</label>
      <label
        v-if="helpLabel && !showValidationMessage"
        class="d-flex justify-end labelHelp text-color"
      >{{helpLabel}}</label>
    </v-flex>
  </div>
</template>
<script type="plain/text">
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
  extends: ValidatableInput,
  props: {
    type: "",
    helpBoxText: "",
    placeHolder: "",
    labelCharLimit: 0,
    infoVisible: false,
    titleInfo: null,
    subTitleInfo: null,
    showLoader: true
  },
  methods: {
    search(){
      this.$emit("search")
    }
  },
};
</script>
