const CartStatus = {
	Initiated: 1,
	WaitingForApproval: 2,
	Cancelled: 3,
	PaymentProcessInitiated: 4,
	Approved: 5,
	Expired: 6,
	Refunded: 7,
	ApprovedAndConfirmed: 8,
	PaymentError: 9,
	getName(status) {
		if (status == this.Initiated)
			return "Iniciado";
		else if (status == this.WaitingForApproval)
			return "Aguardando Pagamento";
		else if (status == this.Cancelled)
			return "Cancelado";
		else if (status == this.PaymentProcessInitiated)
			return "Processo de pagamento iniciado";
		else if (status == this.Approved)
			return "Aprovado";
		else if (status == this.Expired)
			return "Vencido";
		else if (status == this.Refunded)
			return "Reembolsado";
		else if (status == this.ApprovedAndConfirmed)
			return "Confirmado";
		else if (status == this.PaymentError)
			return "Erro ao efetuar pagamento";
		return "";
	}
};

export default CartStatus;