
export default class CampaignPaymentMethodProvider {

    constructor() {
      this.campaignId = 0;
      this.ticketPaymentMethodProviderId = 0;
      this.creditCardPaymentMethodProviderId = 0;
      this.accountDebitPaymentMethodProviderId = 0;
    }

}