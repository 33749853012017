<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="700px">
      <template v-slot:activator="{ on }">
        <a v-on="on" id="showContact" @click="gaFormContact(); $gtag('send', 'event', 'Contato com Sucesso', 'Contato com sucesso', 'Contato com Sucesso')">
          <Icon :name="'uil uil-envelope'" :size="24" />
        </a>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>
            <div>Fale Conosco</div>
          </h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="close(); $gtag('send', 'event', 'Contato sem Sucesso', 'Contato', 'Contato sem Sucesso')">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 60vh;" column>
          <v-layout row wrap w-100 list-scroll pa-4>
            <v-flex xs12>
              <InputText
                ref="nameInput"
                type="text"
                place-holder="Informe seu nome completo"
                textLabel="Nome"
                invalidInputMessage="Informe seu nome completo"
                v-model="contact.name"
              />
            </v-flex>
            <v-flex xs12>
              <InputText
                ref="emailInput"
                type="email"
                place-holder="Digite seu e-mail"
                textLabel="E-mail"
                invalidInputMessage="Por favor informe um e-mail válido"
                v-model="contact.email"
                :validation="emailValidation"
              />
            </v-flex>
            <v-flex xs12>
                  <InputText
                    
                    type="tel"
                    place-holder="Informe o telefone do contato"
                    textLabel="Telefone"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    v-model="contact.phone"
                    :validation="validateCellPhone"
                    invalidInputMessage="Preencha o telefone"
                  />
            </v-flex>
            <v-flex xs12>
              <InputText
                ref="subjectInput"
                type="text"
                place-holder="Escreva o assunto da sua mensagem"
                textLabel="Assunto"
                invalidInputMessage="Por favor preencha o assunto"
                v-model="contact.subject"
              />
            </v-flex>
            <v-flex xs12 mt-2>
              <InputTextArea
                ref="messageInput"
                type="text"
                rows="1"
                place-holder="Escreva a sua mensagem"
                textLabel="Mensagem"
                invalidInputMessage="Por favor preencha o assunto"
                v-model="contact.message"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout row wrap w-100 px-4 py-3>
            <v-flex xs12 text-xs-right>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 ma-0"
                large
                @click="send(); $gtag('send', 'event', 'lightbox', 'Contato', 'Enviar')"
              >Enviar</v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import Contact from "@/scripts/models/contact.model";
import ProjectService from "@/scripts/services/project.service.js";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  extends: ValidatableComponent,
  data() {
    return {
      dialog: false,
      showForm: false,
      contact: new Contact(),
      projectService: new ProjectService()
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.$refs.nameInput.showValidationMessage = false;
        this.$refs.emailInput.showValidationMessage = false;
        this.$refs.subjectInput.showValidationMessage = false;
        this.$refs.messageInput.showValidationMessage = false;
      }
    }
  },
  methods: {
    create() {
      this.contact = new Contact();
    },
    send() {
      if (this.isValid()) {
        this.projectService.sendContact(this.sendCallback, this.contact);
      }
    },
    validateCellPhone() {
      return CommonHelper.validateCellPhone(this.contact.phone);
    },
    sendCallback() {
      this.contact = new Contact();
      this.close();
    },
    emailValidation() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.contact.email).toLowerCase());
    },
    close() {
      this.dialog = false;
      },
    gaFormContact() {
        return this.$gtag('send', 'pageview', '/home/lightbox/contato');
    }
  }
};
</script>