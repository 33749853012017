import BaseCrudService from "./baseCrud.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

export default class UserService extends BaseCrudService {

    constructor() {
        super('User');
    }

    activateUser(tempHash) {
        return this.postRequest('ActivateUser', { tempHash: tempHash });
    }

    getInformation(userId) {
        return this.getRequest(`GetInformation?userId=${userId}`);
    }

    resendCreationEmail(username) {
        return this.postRequest('ResendCreationEmail', { username: username });
    }

    resendRecoverPasswordEmail(username) {
        return this.postRequest('ResendRecoverPasswordEmail', { username: username });
    }

    sendCreatePassword(username) {
        return this.postRequest('SendCreatePassword', { username: username });
    }

    sendRecoverPasswordEmail(username, tokenRecaptcha) {
        return this.postRequest('SendRecoverPasswordEmail', { username: username, tokenRecaptcha: tokenRecaptcha })
    }

    sendChangePasswordEmail(username) {
        return this.postRequest('SendRecoverPasswordEmail', { username: username })
            .then(function (response) {
                if (response.success) {
                    this.router.push({ name: Routes.web.Login });
                }
                return response.success;
            }.bind(this));
    }

    recoverPassword(tempHash, password, confirmPassword, optin) {
        return this.postRequest('RecoverPassword', { tempHash: tempHash, password: password, confirmPassword: confirmPassword, optin: optin });
    }

    listCompanyGroup() {
        return this.getRequest('ListCompanyGroup');
    }

    listRolesOfLoggedUser() {
        return this.getRequest('ListRolesOfLoggedUser');
    }

    getEntityById(entityId) {
        return this.getRequest('GetEntityById', { entityId: entityId });
    }

    listUserEntities() {
        return this.getRequest('ListEntitiesOfLoggedUser');
    }

    setRoleActivationStatus(userRole) {
        return this.putRequest('SetRoleActivationStatus', userRole);
    }

    showOptIn(cpf, tempHash) {
        return this.postRequest('ShowOptIn', { cpf, tempHash });
    }

    confirmEmail(tempHash, email) {
        return this.getRequest('ConfirmEmail', { tempHash: tempHash, email: email });
    }

    getOptinByTempHash(tempHash){
        return this.getRequest('GetOptinByTempHash', { tempHash: tempHash });
    }

    findById(userId){
        return this.getRequest('FindById', { userId: userId });
    }

}