<template>
    <!-- class="input-comp mult-select noclass" -->
    <!-- class="form-input mult-select" -->
    <div>
        <label for v-if="textLabel">{{ textLabel }}</label>
        <v-select
            ref="multiSelectComponent"
            taggable
            multiple
            chips
            :value="value"
            :items="itemsList"
            :disabled="disabled"
            @input="changed($event)"
        >
            <!-- <template v-if="showAllOptions" v-slot:prepend-item>
              <v-list-item ripple @click="toggle">
                <v-list-item-action>
                  <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{allText}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>-->
            <template v-slot:selection="{ item, index }">
                <v-chip v-if="index <= (maxShowItems - 1)">
                    <span>{{ item.text }}</span>
                </v-chip>
                <span
                    v-if="index === maxShowItems"
                    class="grey--text caption"
                >(+{{ value.length - maxShowItems }} selecionados)</span>
            </template>
        </v-select>
        <label v-if="helpLabel" class="d-flex justify-end labelHelp text-color">{{ helpLabel }}</label>
    </div>
</template>

<script>
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
    extends: ValidatableInput,
    props: {
        textLabel: "",
        helpLabel: "",
        items: Array,
        disabled: Boolean,
        valueAttribute: "",
        textAttribute: "",
        showAllOptions: {
            type: Boolean,
            default: false
        },
        // allValue: {
        //   default: null
        // },
        allText: {
            type: String,
            default: "Todos"
        },
        maxShowItems: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            itemSelected: null,
            hasUnselectedValue: true
        };
    },
    computed: {
        itemsList: function () {
            let result = [];
            if (
                !this.items ||
                this.valueAttribute == null ||
                this.valueAttribute == ""
            )
                result = this.items;
            else {
                result = this.items.reduce((array, item) => {
                    array.push({
                        text: this.getText(item),
                        value: this.getValue(item)
                    });

                    return array;
                }, []);
            }
            // if (this.showAllOptions)
            //   result.unshift({ text: this.allText, value: this.allValue });
            return result;
        },
        allSelected() {
            return this.value.length === this.items.length;
        },
        someSelected() {
            return this.value.length > 0 && !this.allSelected;
        },
        icon() {
            if (this.allSelected) return "mdi-close-box";
            if (this.someSelected) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        }
    },
    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.value = [];
                } else {
                    this.value = this.items.slice();
                }
                this.changed(event);
            });
        },
        getText(option) {
            if (this.textAttribute == null || this.textAttribute == "") return option;
            let attributes = this.textAttribute.split(".");
            let val = option;
            for (let i = 0; i < attributes.length; i++) {
                val = val[attributes[i]];
            }
            return val;
        },
        getValue(option) {
            if (this.valueAttribute == null || this.valueAttribute == "")
                return option;
            let attributes = this.valueAttribute.split(".");
            let val = option;
            for (let i = 0; i < attributes.length; i++) {
                val = val[attributes[i]];
            }
            return val;
        },
        changed(event) {
            // if (this.showAllOptions && event.includes(this.allValue)) {
            //   for (let i = 0; i < this.items.length; i++) {
            //     let itemValue = this.getValue(this.items[i]);
            //     if (!event.includes(itemValue)) {
            //       event.push(itemValue);
            //     }
            //   }
            // }
            this.$emit("input", event);
        }
    }
};
</script>

<style>
</style>
