export default class File {

	constructor() {
		this.id = 0;
		this.userId = 0;
		this.name = '';
		this.path = '';
		this.size = 0;
		this.extension = '';
		this.origin = '';
		this.publicId = '';
		this.imageContent = '';
		this.public = false;
	}
}