const CampaignLandingPageBlockIdentifier = {
    NumberBlock: 1,
    ProjectBlock: 2,
    CaseBlock: 3,
    StepBlock: 4,
    SimulationBlock: 5,
    NewsBlock: 6,
    AppreciationBlock: 7,
    TestimonyBlock: 8,
    VideoBlock: 9,
    PhotoGalleryBlock: 10,
    PartnerBlock: 11,
    DonationVoucherBlock: 12,
    SimplifiedDonationBlock: 13,
    VolunteeringBlock: 14,
    PhotoCardsBlock: 15,
    EmptyBlock: 16,
    DonationProductsBlock: 17,
    MemberGetMemberBlock: 18,
};

export default CampaignLandingPageBlockIdentifier;
