const EntityType = {
    AUC: 0,
    Institution: 1,
    Company: 2,
    Council: 3
};

const EntityTypeList = [
    { id: EntityType.AUC, name: "AUC" },
    { id: EntityType.Institution, name: "Instituição" },
    { id: EntityType.Company, name: "Empresa" },
    { id: EntityType.Council, name: "Conselho" }
]

export { EntityType, EntityTypeList };