import Vue from 'vue';

Vue.filter('FormatNumber', (number) => {
    try {
        if (!number)
            return number
        number = number.toString().split(".").join("");
        var nvalue = Number(number).toLocaleString('pt-BR')
        return nvalue;
    } catch (e) {
        console.log(e);
    }
});