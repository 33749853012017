import BaseService from './base.service';

export default class BaseCrudService extends BaseService {

    constructor(controllerName) {
        super(controllerName);
    }

    create(model, showMessage = true) {
        return this.postRequest('Create', model, showMessage);
    }

    update(model, showMessage = true) {
        return this.putRequest('Update', model, showMessage);
    }

    delete(identifier, showMessage = true) {
        var data = { id: identifier };
        return this.deleteRequest('Delete', data, showMessage);
    }

    listAll(showMessage = true) {
        return this.get('ListAll', null, showMessage);
    }

    exist(identifier, showMessage = true) {
        var data = { id: identifier };
        return this.get('Exist', data, showMessage);
    }


    findById(identifier, showMessage = true) {
        var data = { id: identifier };
        return this.getRequest('FindById', data, showMessage);
    }
}