export default class USDDonationCheckout {

    constructor() {
        
        this.enabled = false;
        this.dollar = 0;
        this.coin = 'BRL';
        this.prefix = 'R$';
    }

}