
<!-- Esse componente é utilizado para supporters do projeto e parceiros da institituição --> 
<template>
  <v-flex xs5 pr-2>
    <div class="supporters-upload input-comp">
      <label for>Logo</label>
      <div class="d-flex align-center">
        <span>{{ value.file.name }}</span>
        <i class="fal fa-image"></i>
        <input ref="input" type="file" accept="image/*" @change="fileChanged($event)">
      </div>
    </div>
    <v-flex justify-space-between d-flex file-alert-input>
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
      >{{invalidInputMessage}}</label>
      <label
        v-if="helpLabel && !showValidationMessage"
        class="d-flex justify-end w-100 file-label-help"
      >{{helpLabel}}</label>
    </v-flex>
  </v-flex>
</template>

<script type="plain/text">
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
  extends: ValidatableInput,
  data() {
    return {

    };
  },
  methods: {
    fileChanged(event) {
      var file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function() {
          this.value.file.name = file.name;
          this.value.file.path = reader.result;
          this.$emit("input", this.value);
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    validate() {
      if (
        !this.value ||
        !this.value.file.name ||
        this.value.file.name == ""
      ) {
        return false;
      }
      return true;
    }
  },
};
</script>
