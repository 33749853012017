<template>
	<div class="loading-background">
        <div class="loading-progress">
            <div class="indeterminate"></div>
        </div>
    </div>
</template>

<script type="plain/text">
export default {}
</script>