var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"anime-proj",attrs:{"id":"animation-proj"}}),_vm._v(" "),_c('MyProfile',{model:{value:(_vm.profileDrawer),callback:function ($$v) {_vm.profileDrawer=$$v},expression:"profileDrawer"}}),_vm._v(" "),_c('nav',{staticClass:"nav-bar"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.app.CampaignLandingPage }},on:{"click":function($event){_vm.$gtag(
            'send',
            'event',
            'header',
            ("logo_" + (_vm.window.location.href.split('.', 1)[0].slice(8))),
            ("" + (_vm.window.location.href))
          )}}},[(_vm.getLogo() != null)?_c('FileImage',{staticClass:"logo",attrs:{"file":_vm.getLogo(),"useBg":true}}):_c('div',{staticClass:"logo"})],1),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.landingPageOptions == null || _vm.landingPageOptions.showMenu),expression:"landingPageOptions == null || landingPageOptions.showMenu"}],staticClass:"nav-main"},[(_vm.landingPageOptions != null && _vm.landingPageOptions.showCallToActioOnNav)?_c('v-btn',{staticClass:"btn-campaign btn-cta",attrs:{"id":"btn-cta-nav"},on:{"click":function($event){return _vm.scrollMeTo()}}},[_vm._v("\n          "+_vm._s(_vm.landingPageOptions.titleCallToActioOnNav)+"\n        ")]):_vm._e(),_vm._v(" "),_c('li',{attrs:{"title":"Página Inicial"}},[_c('router-link',{attrs:{"to":{ name: _vm.routeHome }},on:{"click":function($event){return _vm.$gtag('send', 'event', 'home', 'home', 'home')}}},[_c('Icon',{attrs:{"name":'uil uil-home-alt',"size":24}})],1)],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('li',{attrs:{"title":"Dúvidas Frequentes"},on:{"click":function($event){return _vm.$gtag('send', 'event', 'FAQ', 'FAQ', 'FAQ Header')}}},[_c('Faq')],1),_vm._v(" "),_c('li',{attrs:{"title":"Fale Conosco"},on:{"click":function($event){return _vm.$gtag('send', 'event', 'Contato', 'Contato', 'Botão de contato Header')}}},[_c('Contact')],1),_vm._v(" "),(_vm.showCart && !_vm.isSimplifiedCheckout)?_c('li',{staticClass:"notranslate",staticStyle:{"position":"relative"}},[_c('MyCart',{attrs:{"campaign":_vm.campaign},on:{"verifyCampaign":_vm.checkCampaignStatus}})],1):_vm._e(),_vm._v(" "),_c('li',{attrs:{"title":"Perfil"}},[(_vm.logged)?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.profileDrawer = !_vm.profileDrawer;
              _vm.$gtag('send', 'event', 'header', 'home', 'Perfil');}}},[_c('div',{staticClass:"avatar-profile sm"},[_c('img',{attrs:{"src":_vm.getUrl(),"alt":""}})])]):_vm._e()]),_vm._v(" "),_c('li',{staticClass:"pd-2",attrs:{"title":"Entrar"}},[(!_vm.logged)?_c('a',{on:{"click":function($event){_vm.showLogin();
              _vm.$gtag('send', 'event', 'header', 'home', 'Entrar');}}},[_vm._v("Entrar")]):_vm._e()])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticStyle:{"cursor":"pointer"},attrs:{"title":"Idioma"}},[_c('div',{attrs:{"id":"google_translate_element"}})])}]
export { render, staticRenderFns }