import CartStatus from './cartStatus.enum';
import DonationRecurrencePeriod from './donationRecurrencePeriod.enum';

const CreditCardCartDetailsStatus = {
    /**
     * Carrinho pronto para ter itens adicionados ou removidos.
     */
    Created: 1,

    /**
     * O comprador iniciou a fluxo de pagamento da
     * transação que originou a assinatura ou optou por
     * trocar o cartão de crédito atrelado a uma assinatura
     * existente mas até o momento o PagSeguro não
     * recebeu nenhuma confirmação da operadora
     * responsável pelo processamento da transação
     * validadora ou ela ainda está em análise.
     * Transições:
     * ACTIVE
     * CANCELLED
     */
    Pending: 2,

    /**
     * A transação que originou a assinatura foi paga pelo
     * comprador e o PagSeguro já recebeu uma
     * confirmação da operadora responsável pelo
     * processamento.
     * Transições:
     * EXPIRED
     * CANCELLED_BY_RECEIVER
     * CANCELLED_BY_SENDER
     * PENDING
     */
    Active: 3,

    /**
     * A transação que originou a assinatura foi cancelada
     * por não ter sido aprovada pelo PagSeguro ou pela
     * operadora.
     * Transições: nenhuma
     */
    Cancelled: 4,

    /**
     * A assinatura foi cancelada mediante solicitação do
     * vendedor.
     * Transições: nenhuma
     */
    CancelledByReceiver: 5,

    /**
     * A assinatura foi cancelada mediante solicitação do
     * comprador.
     * Transições: nenhuma
     */
    CancelledBySender: 6,

    /**
     * A assinatura expirou por ter atingido o tempo limite
     * de sua vigência (preApprovalFinalDate) ou por ter
     * atingido o valor definido em
     * preApprovalMaxTotalAmount.
     * Transições: nenhuma
     */
    Expired: 7,

    Refunded: 8,
    ApprovedAndConfirmed: 9,

    getName(status, cartStatusId, donationRecurrencePeriodId) {
        if (cartStatusId === CartStatus.PaymentError) {
            return "Erro ao efetuar pagamento"
        }
        else if(cartStatusId === CartStatus.Cancelled){
            return "Cancelado"
        }
        else if (status == this.Created)
            return "Criado";
        else if (status == this.Pending)
            return "Pendente";
        else if (status == this.Active)
            return "Ativo";
        else if (status == this.Cancelled) {
            if (donationRecurrencePeriodId === DonationRecurrencePeriod.Single) {
                return "Confirmado";
            }
            return "Cancelado";
        }

        else if (status == this.CancelledByReceiver) {
            if (donationRecurrencePeriodId === DonationRecurrencePeriod.Single) {
                return "Confirmado";
            }
            return "Cancelado pelo vendedor";
        }
        else if (status == this.CancelledBySender)
            return "Cancelado pelo comprador";
        else if (status == this.Expired) {
            if (donationRecurrencePeriodId === DonationRecurrencePeriod.Single) {
                return "Confirmado";
            }
            return "Expirou";
        }
        else if (status == this.Refunded)
            return "Reembolsado";
        else if (status == this.ApprovedAndConfirmed)
            return "Confirmado";
        return "";
    }
};

export default CreditCardCartDetailsStatus;