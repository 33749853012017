<template>
    <i :class="type+' fa-'+name" :style="{fontSize: size, color:colorFont}"></i>
</template>

<script type="plain/text">
export default {
    name:'Icon',
    props:{
        type: null,
        name: null,
        size: null,
        colorFont: null
    }
}
</script>
