const UsernameType = {
        /**
         * Email
         */
        Email: 1,
        /**
         * CPF
         */
        CPF: 2,
        /**
         * Id de matrícula
         */
        RegistrationId: 3
}

export default UsernameType;
