<template>
    <i :class="name" :style="{fontSize: size+'px', color:colorFont}" :title="(title == null ? '' : title)"></i>
</template>

<script type="plain/text">
export default {
    name:'Icon',
    props:{
        name: null,
        size: null,
        colorFont: null,
        title: null
    }
}
</script>
