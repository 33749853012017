import Phone from './pagSeguro/phone.model';

export default class PaymentSenderInfo {
    constructor() {
        this.cpf = '';
        this.name = '';
        this.birthDate = '';
        this.email = '';
        this.hash = '';

        this.documents = [];

        this.phone = new Phone();
    }


    set CPF(cpf) {
        this.cpf = cpf;
        this.documents = [
            {
                type: "CPF",
                value: cpf
            }
        ];
    }

    get CPF() {
        return this.cpf;
    }
}