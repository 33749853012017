import User from "@/scripts/models/user.model";
import Address from "@/scripts/models/address.model";

export default class Person {

    constructor() {
        this.cpf = "";
        this.cnpj = null;
        this.passport = "";
        this.name = "";
        this.socialReason = null;
        this.lastName = "";
        this.email = "";
        this.alternativeEmail = "";
        this.birthDate = null;
        this.gender = "";
        this.phone = "";
        this.alternativePhone = "";
        this.userId = null;
        this.addressId = null;
        this.user = new User();
        this.address = new Address();
        this.id = 0;
        this.isEmployee = false;
        this.memberCode = null;
        this.registrationWithMemberCode = null;
        this.companyName = "";
        this.departamentName = "";
        this.alreadyExist = true;
    }

}