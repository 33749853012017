// Enums
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";

// Services
import CartService from '@/scripts/services/cart.service.js'

const CardModule = {
    state: {
        cartService: new CartService(),
        PaymentMethod: PaymentMethod,
        PaymentMethodProvider: PaymentMethodProvider,
        creditCards: [],
        selectedCardId: 0,
        addNewCard: false,
    },
    mutations: {
        GET_CREDIT_CARDS: (state, payload) => {
            state.creditCards = payload;
        },
        SELECT_CREDIT_CARD: (state, payload) => {
            state.selectedCardId = payload
        },
        ADD_NEW_CARD: (state, payload) => {
            state.addNewCard = payload;
        },
    },
    getters: {
        creditCards: state => {
            return state.creditCards;
        },
        selectedCardId: state => {
            return state.selectedCardId;
        },
        addNewCard: state => {
            return state.addNewCard;
        },
    },
    actions: {
        getCreditCards: async state => {
            try {
                const creditCards = await state.state.cartService.getCreditCards(state.rootGetters.cartLocal.userId)
                let creditCard = state.state.creditCards.find((x) => x.isInUse);

                if (creditCard) {
                    state.commit('SELECT_CREDIT_CARD', creditCard.id);
                    state.state.cartLocal.checkoutRequest.creditCardInfo.cardNumber = creditCard.lastDigits;
                }

                if (creditCards)
                    state.commit('GET_CREDIT_CARDS', creditCards);

                if (creditCards.length == 0)
                    state.commit('ADD_NEW_CARD', true);
            } catch (error) {
                throw new Error(`Erro ao carregar dados do carrinho ${error}`);
            }
        }
    }
}

export default CardModule;