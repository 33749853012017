<template>
  <div>
    <InputAutocomplete
      ref="cityInput"
      :key="citiesNames.length"
      :items="citiesNames"
      :textLabel="textLabel"
      :required="required"
      v-model="city"
      @selection-changed="onCitySelected"
      placeHolder="Digite o nome da sua cidade"
      invalidInputMessage="Informe sua cidade"
    />
  </div>
</template>
<script>
import LocationService from "@/scripts/services/location.service";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  props: {
    textLabel: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      locationService: new LocationService(),
      cities: [],
      citiesNames: [],
      city: null
    };
  },
  async mounted() {
    this.cities = await this.locationService.listAllCities();
    this.citiesNames = this.cities.map((item) => {
      return item.displayName
    });
  },
  methods: {
    onCitySelected(city) {
      if(city != null && city != ""){
        this.$emit("city-selected", this.cities.find(c => c.displayName === city));
      } else {
        this.$emit("city-selected", null);
      }
      this.$emit("input", city);
    }
  }
}
</script>