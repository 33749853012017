const Routes = {
  NotFound: "not-found",
  app: {
    InstitutionProfile: "institution-profile",
    InstitutionAbout: "institution-about",
    InstitutionList: "institution-list",
    ProjectLp: "project-lp",
    ManagementUser: "management-user",
    ManagementSignature: "management-signature",
    ManagementManualSignature: "management-manual-signature",
    ManagementCompany: "management-company",
    ManagementInstitution: "management-institution",
    ManagementCouncil: "management-council",
    ManagementBankSlip: "management-bank-slip",
    ManagementTax: "management-tax",
    ManagementTicket: "management-ticket",
    ManagementGoal: "management-goal",

    CompanyProfile: "company-profile",
    CompanyList: "company-list",

    CouncilProfile: "council-profile",
    CouncilList: "council-list",

    CampaignEdit: "campaign-edit",
    CampaignFAQ: "campaign-faq",
    CampaignList: "campaign-list",
    CampaignLandingPage: "campaign-landing-page",
    CampaignLandingPageEdit: "campaign-landing-page-edit",
    CampaignLandingPageContato: "campaign-landing-page-contato",
    Congrats: "congrats",

    ProjectStep0: "project-step0",
    ProjectStep1: "project-step1",
    ProjectStep2: "project-step2",
    ProjectStep3: "project-step3",
    ProjectStep4: "project-step4",
    ProjectStep50: "project-step5-0",
    ProjectStep51: "project-step5-1",
    ProjectList: "project-list",
    ProjectGallery: "project-gallery",
    ProjectDocuments: "project-documents",
    ProjectAccountability: "project-accountability",

    VolunteeringLP: "volunteering-lp",
    VolunteeringList: "volunteering-list",
    VolunteeringStep1: "volunteering-step1",
    VolunteeringStep2: "volunteering-step2",
    VolunteeringStep3: "volunteering-step3",
    VolunteeringGallery: "volunteering-gallery",
    VolunteeringStep5: "volunteering-step5",
    VolunteeringStep6: "volunteering-step6",

    HrConciliation: "conciliacao-rh",
    ResourceFlowList: "fluxo-recursos",
    StatusZero: "status-zero",
    ConciliationCompany: "conciliation-company",
    TransferToAUC: "transfer-to-auc",
    ConciliedByCompany: "concilied-by-company",
    OnConciliationAUC: "on-conciliation-auc",
    ConciliedAUC: "concilied-auc",
    PendentAcceptToInstitution: "pendent-accept-to-institution",
    PendentTransferToInstitution: "pendent-tranfer-to-institution",
    OnConciliationToInstitution: "on-conciliation-institution",
    Concilied: "concilied",
    Declined: "declined",

    DashboardCompany: "dashboard-company",
    DashboardPF: "dashboard",
    MemberGetMember: "member-get-member",

    User: "user",

    Profile: "profile",
  },
  web: {
    Cart: "cart",
    CartLogged: "cart-logged",
    CheckoutV2: "checkout-v2",
    OrderApproved: "order-approved",
    OrderNotApproved: "order-not-approved",
    CheckoutDefault: "checkout-default",
    GenerateTicket: "generate-ticket",
    RetryDonation: "retry-donation",

    LoginPublic: "login-public",
    Login: "login",
    LoginByToken: "login-by-token",
    LoginGoogle: "login-google",
    LoginFacebook: "login-facebook",
    LoginForgotPassword: "login-forgot-password",
    LoginPasswordRegistration: "login-password-registration",
    LoginVerifyAccount: "login-verify-account",
    LoginVerifyEmail: "login-verify-email",
    LoginVerifyPassword: "login-verify-password",
    Profile: "profile",
    ProjectLP: "project-landing-page",
    ProjectAll: "all-project",
    ProjectAccountabilityLP: "project-accountability-lp",
    Receipt: "recibo",
    Income: "informe",
    PaymentMethods: "payment-methods",
  },
};

export { Routes };
