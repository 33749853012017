import { Routes } from "@/scripts/models/enums/routes.enum.js";
import CommonHelper from "@/scripts/helpers/common.helper.js";

const FacebookService = {

    init(version, appId, resourceToRedirect) {
        let protocol = window.location.protocol;
        let host = window.location.host;
        let subdomain = CommonHelper.getSubdomain() || '';

        if(subdomain){
            host = host.replace(subdomain + '.', '');
        }

        let redirectUri = protocol + '//' + host + '/' + resourceToRedirect;

        this.baseUrl = 'https://www.facebook.com/' + version + '/';
        this.version = version;
        this.appId = appId;
        this.redirectUri = redirectUri;
        this.state = subdomain;
    },

    login() {
        // console.log(this.redirectUri); return;
        window.location = this.baseUrl +
            'dialog/oauth?client_id=' + this.appId +
            '&redirect_uri=' + this.redirectUri +
            '&state=' + this.state +
            '&scope=' + 'email' +
            '&response_type=token';
    },

    verifyToken(callback, error) {
        this.getRequest('VerifyToken').then(callback).catch(error);
    },

    onLogin(success) {

        if (success) {
            this.router.push({ name: Routes.app.ProjectStep0 })
        }
    }

}

export default FacebookService;