<template>
	<div class="text-xs-left input-comp">
		<v-flex d-flex justify-space-between w-100>
			<label v-if="textLabel" class="d-flex">{{ textLabel }}</label>
		</v-flex>
		<div class="inside-input">
			<money ref="input" :value="value" :disabled="disabled" v-bind="money" :pattern="pattern" :id="id"
				@input="$emit('input', $event);" @blur.native="$emit('blur', $event)"
				@focus.native="$emit('focus', $event)" :required="required"></money>
			<template v-if="infoVisible">
				<i class="fal fa-info-circle"></i>
				<div>
					<h5>{{ titleInfo }}</h5>
					<p>{{ subTitleInfo }}</p>

				</div>
			</template>
		</div>
		<v-flex justify-space-between d-flex>
			<label v-if="showValidationMessage" class="d-flex justify-end msg-validation"
				:key="showValidationMessage">{{
					invalidInputMessage }}</label>
			<label v-if="helpLabel && !showValidationMessage" class="d-flex justify-end labelHelp">{{ helpLabel
				}}</label>
		</v-flex>
	</div>
</template>
<script type="plain/text">
import ValidatableInput from "@/components/base/ValidatableInput.vue";
import { Money } from "v-money";

export default {
	extends: ValidatableInput,
	components: { Money },
	props: {
		placeHolder: "",
		precision: 0,
		infoVisible: false,
		titleInfo: null,
		subTitleInfo: null,
		pattern: null,
		prefix: {
			type: String,
			default: "R$ "
		},
		suffix: {
			type: String,
			default: ""
		},
		id: String,
		required: Boolean
	},
	created() {
		this.money.precision = this.precision;
		this.money.prefix = this.prefix;
		this.money.suffix = this.suffix;
	},
	data() {
		return {
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "R$ ",
				suffix: "", //Tinha que ser feito assim, acredite
				precision: 0,
				masked: false
			}
		};
	},
	methods: {
		validate() {
			if (this.value == 0) {
				return false;
			}
			return true;
		}
	},
	watch: {
		prefix: function (newValue, oldValue) {
			this.money.prefix = newValue;
		}
	},
};
</script>
