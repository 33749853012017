import BaseService from '@/scripts/services/base.service';
// import CheckoutRequest from '@/scripts/models/checkoutRequest.model';

export default class PaymentInstallmentService extends BaseService {

  constructor() {
		super('PaymentInstallments');
	}

  getFirstInstallments(campaignId, value, paymentMethodId, includeTax = false) {
    return this.getRequest(`GetFirstInstallment?CampaignId=${campaignId}&Value=${value}&PaymentMethodId=${paymentMethodId}&IncludeTax=${includeTax}`, null, null, false);
  }

  createInstallments(campaignId, value, paymentMethodId, installment) {
    return this.getRequest(`CreateInstallments?CampaignId=${campaignId}&Value=${value}&PaymentMethodId=${paymentMethodId}&Installment=${installment}`);
  }

  makeInstallmentsByValueAndInstallment(value, installment) {
    return this.getRequest(`MakeInstallmentsByValueAndInstallment?Value=${value}&Installment=${installment}`, false, false, false);

  }

}