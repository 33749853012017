import axios from "axios";
import TokenService from "./token.service";

const ApiService = {
    initialized: false,

    init() {
        if (!this.initialized) {
            this.initialized = true;
            axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        }
    },

    setHeader() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${TokenService.getToken()}`;
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
    },

    setHeaderMultipart() {
        this.setHeader();
        axios.defaults.headers.common["Content-Type"] = `multipart/form-data`;
    },

    removeHeader() {
        axios.defaults.headers.common = {};
    },

    get(resource, data, config = {}) {
        this.setHeader();
        return axios.get(resource, { ...config, params: data });
    },

    post(resource, data) {
        this.setHeader();
        return axios.post(resource, data);
    },

    postFiles(resource, data) {
        this.setHeaderMultipart();
        return axios.post(resource, data);
    },

    requestWithOptions(data) {
        return axios(data);
    },

    put(resource, data) {
        this.setHeader();
        return axios.put(resource, data);
    },

    delete(resource, data) {
        this.setHeader();
        return axios.delete(resource, { params: data });
    },
};

ApiService.init();

export default ApiService;
