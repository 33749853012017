export default class Address {

    constructor() {

        this.id = 0;
        this.postalCode = "";
        this.street = "";
        this.number = null;
        this.complement = "";
        this.neighborhood = "";
        this.city = "";
        this.state = "";
        this.country = "Brasil";
    }

}