<template>
  <div class="input-comp">
    <label v-if="textLabel" class="d-flex">{{textLabel}}{{ required ? ' *' : '' }}</label>
    <!-- <v-select v-model="itemSelected" :items="itemsList"></v-select>
    {{itemSelected}}-->
    <select
      ref="input"
      @input="sendValue($event)"
      @focus="$emit('focus', helpBoxText)"
      :disabled="disabled"
    >
      <option v-if="placeholderText && choosenValue === null" :value="-1" disabled selected>{{placeholderText}}</option>
      <option
        v-if="hasUnselectedValue"
        :selected="value === getUnselectedValue()"
        :value="getUnselectedValue()"
      >{{getUnselectedText()}}</option>
      <option
        v-for="option in items"
        :value="getValue(option)"
        :key="getValue(option)"
        :selected="value === searchForSelected(option)"
        :disabled="option.active != null && !option.active "
      >{{getText(option)}}</option>
    </select>
    <v-flex justify-space-between d-flex>
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
      >{{invalidInputMessage}}</label>
      <label
        v-if="helpLabel && !showValidationMessage"
        class="d-flex justify-end labelHelp"
      >{{helpLabel}}</label>
    </v-flex>
  </div>
</template>

<script type="plain/text">
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
  extends: ValidatableInput,
  props: {
    value: "",
    items: Array,
    valueAttribute: "",
    textAttribute: "",
    helpBoxText: "",
    unselectedValue: null,
    useAnotherDefaultValue: false,
    hasUnselectedOption: true,
    placeholderText: "",
    placeholderValue: -1,
    unselectedText: "",
    forceSelected: "",
  },
  data() {
    return {
      itemSelected: null,
      hasUnselectedValue: true,
      hasPlaceholderValue: false,
      valueToSelect: null,
      choosenValue: null,
    };
  },
  async created() {
    if (this.hasUnselectedOption === false) this.hasUnselectedValue = false;
    if(this.placeholderText && this.placeholderText != "") this.hasPlaceholderValue = true;
  },
  computed: {
    itemsList: function() {
      return this.items.reduce((array, item) => {
        array.push({
          text: item.name,
          value: item
        });

        return array;
      }, []);
    }
  },
  methods: {
    getUnselectedText() {
      if (this.unselectedText) return this.unselectedText;
      return "Selecione...";
    },
    getUnselectedValue() {
      if (this.useAnotherDefaultValue) return this.unselectedValue;
      return 0;
    },
    validate() {
      if (!this.value || this.value == this.getUnselectedValue()) {
        return false;
      }
      return true;
    },
    getText(option) {
      if (this.textAttribute == null || this.textAttribute == "") return option;
      let attributes = this.textAttribute.split(".");
      let val = option;
      for (let i = 0; i < attributes.length; i++) {
        val = val[attributes[i]];
      }
      return val;
    },
    getValue(option) {
      if (this.valueAttribute == null || this.valueAttribute == "")
        return option;
      let attributes = this.valueAttribute.split(".");
      let val = option;
      for (let i = 0; i < attributes.length; i++) {
        val = val[attributes[i]];
      }
      return val;
    },
    searchForSelected(option) {
        if (!this.forceSelected) return this.getValue(option)
        if (option.name === this.forceSelected) {
          
          return option.id
        }
    },
    sendValue(event) {
      const value = event.target.value
      this.$emit('input', value)
      this.choosenValue = value
    }
  }
};
</script>
