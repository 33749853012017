import BaseCrudService from "./baseCrud.service";

export default class CheckoutService extends BaseCrudService {
    constructor() {
        super("Checkout");
    }

    getTicket(url) {
        return this.getRequest("GetTicket", { url }, true, true, {
            responseType: "blob",
        });
    }

    getCampaignDataForCheckout() {
        return this.getRequest("GetCampaignDataForCheckout");
    }

    postCheckout(checkout) {
        return this.postRequest("Checkout", checkout);
    }
}
