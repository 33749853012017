const PaymentMethodProvider = {
    /**
     * PagSeguro
     */
    PagSeguro: 1,

    /**
     * PagSeguro
     */
    PagarMe:  2,

    /**
     * Vindi
     */
    Vindi: 3,

    /**
     * Banco do Brasil
     */
    BancoBrasil: 4,

    /**
     * Banco do Brasil
     */
    Itau: 5,

    /**
     * Asaas
     */
    Asaas: 7
};

export default PaymentMethodProvider;