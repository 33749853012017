<template>
  <div>
    <Modal v-model="dialog" @input="hideModal" :cardTitle="false">
      <LoginBox v-bind="$props" @loginSuccess="loginSuccess" :campaign="campaign" @close="toggleDialog()" :inline="true"></LoginBox>
    </Modal>
  </div>
</template>
<script type="plain/text">
import LoginBox from "@/components/login/LoginBox.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import Modal from "@/components/Modal.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import CampaignService from "@/scripts/services/campaign.service";
export default {
  components: {
    LoginBox,
    Modal
  },
  props: [
    "value",
    "action",
    "email",
    "facebookId",
    "googleId",
    "name"
  ],
  data() {
    return {
      dialog: false,
      campaignService: new CampaignService(),
      campaign:  {}
    };
  },
  created() {
    this.campaignService.findBySubdomain(data => this.campaign = data);
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  computed: {
    ...mapGetters(['registerStep'])
  },
  methods: {
    ...mapActions(['setRegisterStep']),
    toggleDialog() {
      this.dialog = !this.dialog;
      this.$emit("input", this.dialog);
      if (!this.dialog) this.hideModal();
    },
    loginSuccess(data) {
      this.$emit("loginSuccess");
      this.toggleDialog();
    },
    hideModal() {
      this.$store.commit("HIDE_LOGIN");
      this.setRegisterStep(1)
    }
  }
};
</script>