import CreditCardInfo from "./creditCardInfo.model";
import PaymentSenderInfo from "./paymentSenderInfo.model";
import DebitAccountInfo from "./debitAccountInfo.model";
import TicketInfo from "./ticketInfo.model";
import PixInfo from "./pixInfo.model";

export default class CheckoutRequest {
    constructor() {        
        this.creditCardInfo = new CreditCardInfo();
        this.debitAccountInfo = new DebitAccountInfo();
        this.paymentSenderInfo = new PaymentSenderInfo();
        this.ticketInfo = new TicketInfo();
        this.pixInfo = new PixInfo();
        this.cartInformation = {
            response1: "",
            response2: "",
            response3: "",
        }
        this.indicationCode = null;
    }

    
}