const CampaignPhases = {
    Nova: 1,
    EmAndamento: 2,
    Pausada: 3,
    Encerrada: 4,
    Cancelada: 5,
};

const CampaignPhasesText = {
    Nova: { id: CampaignPhases.Nova, name: "Novas" },
    EmAndamento: { id: CampaignPhases.EmAndamento, name: "Em andamento" },
    Pausada: { id: CampaignPhases.Pausada, name: "Pausadas" },
    Encerrada: { id: CampaignPhases.Encerrada, name: "Encerradas" },
    Cancelada: { id: CampaignPhases.Cancelada, name: "Canceladas" }
}

export { CampaignPhases, CampaignPhasesText };