<template>
</template>
<script>
    export default {
        props: {
            value: "",
            validation: null,
            onValidationFail: null,
            onValidationSuccess: null,
            showValidationFailMessage: null,
            required: true
        },
        data() {
            return {
                validationMethod: this.validation,
                onValidationFailMethod: this.onValidationFail,
                onValidationSuccessMethod: this.onValidationSuccess,
                showValidationFailMessageMethod: this.showValidationFailMessage
            };
        },
        methods: {
            showMessageUsingVuex(invalidElements) {
                this.$store.commit(
                    "SET_SNACKBAR_MESSAGE",
                    "Você não preencheu todos os campos corretamente"
                );
            },
            isValid(performMethods = true) {
                var coomp = this.value;
                if (this.required != null && !this.required) return true;
                let result = true;
                if (this.validationMethod) {
                    this.validationMethod.bind(this);
                    result = this.validationMethod(performMethods);
                } else {
                    result = this.validateComponents(performMethods);
                }
                if (performMethods) {
                    if (!result) {
                        if (this.onValidationFailMethod) {
                            this.onValidationFailMethod.bind(this);
                            this.onValidationFailMethod();
                        }
                    } else {
                        if (this.onValidationSuccessMethod) {
                            this.onValidationSuccessMethod.bind(this);
                            this.onValidationSuccessMethod();
                        }
                    }
                }
                return result;
            },
            validateComponent(comp, performMethods) {
                if (comp && typeof comp.isValid === "function") {
                    comp.isValid.bind(this);
                    return comp.isValid(performMethods);
                }
                return true;
            },
            validateComponentArray(comp, performMethods) {
                let valid = true;
                for (let i = 0; i < comp.length; i++) {
                    valid = this.validateComponent(comp[i], performMethods);
                    if (!valid) return valid;
                }
                return true;
            },
            validateComponents(performMethods) {
                let result = true;
                let elements = [];
                let invalidElements = [];
                for (let element in this.$refs) {
                    elements.push(element);
                }
                for (let i = 0; i < elements.length; i++) {
                    let element = elements[i];
                    if (hasOwnProperty.call(this.$refs, element)) {
                        let comp = this.$refs[element];
                        let valid = true;
                        if (comp instanceof Array) {
                            valid = this.validateComponentArray(comp, performMethods);
                        } else {
                            valid = this.validateComponent(comp, performMethods);
                        }
                        result = valid && result;
                        if (!valid) {
                            invalidElements.push(element);
                            break;
                        }
                    }
                }
                if (!result && performMethods) {
                    if (this.showValidationFailMessageMethod) {
                        this.showValidationFailMessageMethod.bind(this);
                        this.showValidationFailMessageMethod(invalidElements);
                    } else {
                        this.showMessageUsingVuex(invalidElements);
                    }
                }
                return result;
            }
        }
    };
</script>
