const ProjectTypes = {
    ProjetoIncentivado: 1,
    ProjetoSemIncentivoFiscal: 2,
    Ticket: 3,
    Voluntariado: 4
};

const GetProjectTypeName = function(projectType) {
    if(projectType == ProjectTypes.ProjetoIncentivado) {
        return "Projeto incentivado";
    }
    else if(projectType == ProjectTypes.ProjetoSemIncentivoFiscal) {
        return "Projeto sem incentivo fiscal";
    }
    else if(projectType == ProjectTypes.Ticket) {
        return "Ticket";
    }
    else if(projectType == ProjectTypes.Voluntariado) {
        return "Voluntariado";
    }
    return "";
}

export {ProjectTypes, GetProjectTypeName};