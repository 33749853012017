const PaymentMethod = {
    /**
     * Desconto em folha de pagamento
     */
    PayrollDeduction: 1,

    /**
     * Cartão de crédito
     */
    CreditCard: 2,

    /**
     * Boleto
     */
    Ticket: 3,

    /**
     * Débito em conta
     */
    AccountDebit: 4,

    /**
     * Resgate de pontos
     */
    RedeemRewards: 5,

    /**
     * Pix
     */
    Pix: 6
};

export default PaymentMethod;