<template>
  <v-content>
    <UseTermsModal v-model="showServiceTerms"></UseTermsModal>
    <PrivacyPolicyTermsModal v-model="showPolicyTerms"></PrivacyPolicyTermsModal>
    <v-container>
      <v-layout>
        <div style="max-width: 500px;margin:0 auto">
          <v-layout row wrap>
            <v-flex pa-4>
              <div class="head-login mb-4">
                <p>Por favor entre com seu CPF</p>
              </div>
              <div class="box-xs-form">
                <InputText
                  type="text"
                  v-model="cpf"
                  place-holder="CPF"
                  mask="###.###.###-##"
                  @input="disabledButton"
                />
              </div>

              <v-layout row wrap>
                <div class="check-terms">
                  <v-checkbox
                    v-model="agreedWithTerms"
                    @change="disabledButton"
                    :label="`Aceito os `"
                  ></v-checkbox>
                </div>
                <div class="terms">
                  <a href="javascript:;" @click="showServiceTerms = !showServiceTerms">termos de uso</a>
                </div>
                <div class="terms terms-color">e</div>
                <div class="terms">
                  <a
                    href="javascript:;"
                    @click="showPolicyTerms = !showPolicyTerms"
                  >política de privacidade.</a>
                </div>
              </v-layout>

              <v-checkbox v-if="showOptin" v-model="agreedWithTerms" :label="optinText"></v-checkbox>

              <div class="text-xs-center">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 mt-4"
                  large
                  @click="createFromSocialMedia"
                  :disabled="!disableButtonLogin"
                >Entrar</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import LoginResultType from "@/scripts/models/enums/loginResultType.enum";
import CommonHelper from "@/scripts/helpers/common.helper";
import { mapGetters, mapState, mapActions } from "vuex";
import TypeOptin from "@/scripts/models/enums/typeOptin.enum.js";
import { LoginSocialMedia } from "@/scripts/models/enums/socialMedias.enum.js";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";

export default {
  props: ["person", "landingPageConfig"],
  components: {
    Container,
    UseTermsModal,
    PrivacyPolicyTermsModal,
    FileImage
  },
  data() {
    return {
      cpf: "",
      agreedWithTerms: false,
      showPolicyTerms: false,
      showServiceTerms: false,
      optin: true,
      showOptin: true,
      userService: new UserService(),
      personService: new PersonService(),
      campaignService: new CampaignService(),
      disableButtonLogin: true,
      optinText:
        "Aceito que meus dados sejam armazenados em sigilo na plataforma abraceumacausa.com.br e que em caso de acesso a outras campanhas, eu não precise criar uma nova conta."
    };
  },
  computed: {
    ...mapGetters(["socialMediaLogin"])
  },
  created() {
    this.disabledButton();
    this.userService
      .showOptIn(this.person.cpf, null)
      .then(data => (this.showOptin = data));

    if (
      this.landingPageConfig.campaign.typeOptin == TypeOptin.Default ||
      this.landingPageConfig.campaign.typeOptin == null
    ) {
      this.showOptin = true;
      this.optin = true;
    } else if (
      this.landingPageConfig.campaign.typeOptin == TypeOptin.DisableWithOptin
    ) {
      this.showOptin = false;
      this.optin = true;
    } else if (
      this.landingPageConfig.campaign.typeOptin == TypeOptin.DisableWithOptout
    ) {
      this.showOptin = false;
      this.optin = false;
    }
  },
  methods: {
    ...mapActions(["setLogged"]),
    disabledButton() {
      this.disableButtonLogin =
        CommonHelper.validateCPF(this.cpf) && this.agreedWithTerms;
    },
    createFromSocialMedia() {
      var { socialMediaUser, socialMedia } = this.socialMediaLogin;
      if (this.person) {
        this.person.user.optin = this.optin;
        this.person.cpf = this.cpf;
        if (socialMedia == LoginSocialMedia.Google) {
          this.person.user.googleId = socialMediaUser.id;
        } else if (socialMedia == LoginSocialMedia.Facebook) {
          this.person.user.facebookId = socialMediaUser.id;
        }
        this.person.email = socialMediaUser.email;
        this.person.name = socialMediaUser.name;
        this.person.user.username = socialMediaUser.email;
        this.person.user.usernames = [];
        this.personService
          .create(this.person)
          .then(this.onCreateFromSocialMedia);
      } else {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Usuário não encontrado.");
      }
    },
    onCreateFromSocialMedia(loginResult) {
      this.$store.commit("REMOVE_SOCIAL_MEDIA_LOGIN");
      this.$store.commit("HIDE_LOGIN");

      if (loginResult.success == LoginResultType.Success) {
        this.$store.commit("REMOVE_ROLES");
        this.userService.listRolesOfLoggedUser().then(
          function(data) {
            this.$store.commit("SET_ROLES", data);
            this.setLogged(true);
            
            this.personService.getLoggedPersonAddress().then((data) => {
              if (
                  data.address.postalCode == "" ||
                  data.address.postalCode == null
              )
                  this.$store.commit("SHOW_PERSON_INFO");
            });

            if (this.$router.currentRoute.name == Routes.web.Login) {
              this.$router.push({ name: Routes.app.DashboardPF });
            } else if (
              ![
                Routes.app.CampaignLandingPage,
                Routes.web.ProjectLP
              ].includes(this.$router.currentRoute.name)
            ) {
              this.$router.go();
            }
          }.bind(this)
        );
      } else {
        this.setLogged(false);
        if (loginResult.loginMessageWarning) {
          CommonHelper.swal("", loginResult.loginMessageWarning, "warning");
        }
      }
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    }
  }
};
</script>