export default class Contact {

	constructor() {
		this.id = 0;
		this.name = '';
        this.email = '';
        this.phone = '';
		this.subject = '';
		this.message = '';
		this.createdAt = new Date().toJSON();
	}
	
}