import ProjectStorage from "@/scripts/services/projectStorage.service";

const ProjectModule = {
    state: {
        newProject: null
    },
    mutations: {
        SET_NEW_PROJECT: function (state, value) {
			ProjectStorage.setProject(value);
			state.newProject = value;
		},
		CLEAR_PROJECT: function (state) {
			ProjectStorage.removeProject();
		}
    },
    getters: {
        newProject: state => {
            state.newProject = ProjectStorage.getProject();
			return state.newProject;
		}
    },
    actions: {
        setNewProject(state, value) {
			state.commit('SET_NEW_PROJECT', value);
		},
        clearProject(state) {
            state.commit('CLEAR_PROJECT');
        }
    }
}

export default ProjectModule;