import CampaignService from "@/scripts/services/campaign.service";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";
import PersonService from "@/scripts/services/person.service.js";

// Router
import router from "../../router";

const CampaignModule = {
    state: {
        landingPageConfig: null,
        service: new CampaignService(),
        personService: new PersonService(),
        campaignTypes: CampaignTypes,
        isIncetived: false,
        campaignProducts: null,
        campaignGifts: null,
        recurrencePeriodList: [
            {
                id: 1,
                name: "Única"
            },
            {
                id: 2,
                name: "Recorrência Mensal"
            },
        ],
        profile: null,
        campaignProductCall: null,
        projectButtons: {},
        campaignGiftsConfig: null,
        appreciationList: null,
        defaultConsentTermsText: [],
        consentTermsText: [],
        paymentTaxes: {},
        receiverParcelaments: {},
        incomeTax: "6%",
    },
    mutations: {
        INIT_CAMPAIGN_LANDINGPAGE_CONFIG: (state, payload) => {
            state.landingPageConfig = payload
        },
        SET_PROJECT_INCETIVED: (state, payload) => {
            state.isIncetived = payload
        },
        SET_INCOME_TAX: (state) => {
            if (state.landingPageConfig && state.landingPageConfig.campaign && state.landingPageConfig.campaign.simulatorPercentage)
                state.incomeTax = state.landingPageConfig.campaign.simulatorPercentage + '%';
            else
                state.incomeTax = '0%'; 
        },
        SET_RECURRENCE_PERIOD_LIST: (state) => {
            if (state.landingPageConfig.campaign === null) return
            state.recurrencePeriod = state.landingPageConfig.campaign.campaignDonationRecurrencePeriodList.map(recurrencePeriod => {
                return {
                    id: recurrencePeriod.donationRecurrencePeriodId,
                    name: recurrencePeriod.donationRecurrencePeriod.name
                }
            })
        },
        GET_PROFILE: (state) => {
            state.personService.getProfile((response) => {
                state.profile = response
            })
        },
        GET_APPRECIATION_LIST: (state) => {
            state.service.listAppreciationsByCampaignId().then((data) => {
                state.appreciationList = data
            }); 
        },
        SET_PRODUCT_CALL: (state, payload) => {
            state.campaignProductCall = payload
        },
        SET_PROJECT_BUTTON: (state, payload) => {
            state.projectButtons = {
                ...state.projectButtons,
                ['projectButton'+payload.id]: payload.status
            }
            //state.projectButtons['projectButton'+payload.id] = payload.status
        },
        REFRESH_CAMPAIGN_PRODUCTS: (state, payload) => {
            state.service.listCampaignProductByCampaignId(!(payload && payload.campaignId) ? router.currentRoute.query.campaignId : payload.campaignId).then((response) => {
                state.campaignProducts = response
            })
        },
        REFRESH_CAMPAIGN_GIFTS: (state, payload) => {
            state.service.listCampaignGiftByCampaignId(!(payload && payload.campaignId) ? router.currentRoute.query.campaignId : payload.campaignId).then((response) => {
                state.campaignGifts = response
            })
        },
        REFRESH_GIFTS_CONFIG: (state, payload) => {
            state.service.getGiftConfigByCampaignId(!(payload) ? router.currentRoute.query.campaignId : payload).then((response) => {
                state.campaignGiftsConfig = response
            })
        },
        CHANGE_PRODUCT_STATUS: (state, payload) => {
            payload.campaignId = router.currentRoute.query.campaignId;
            payload.active = !payload.active;
            state.service.saveCampaignProduct(payload)
        },
        CHANGE_GIFT_STATUS: (state, payload) => {
            payload.campaignId = router.currentRoute.query.campaignId;
            payload.active = !payload.active;
            state.service.saveCampaignGift(payload)
        },
        GET_CAMPAIGN_APPRECIATION_LIST: (state) => {
            return state.campaignService.getCampaignAppreciationList(state.landingPageConfig.campaign.id).then((res) => {
                return state.campaignAppreciationList = res;
            })
        },
        SET_APPRECIATION_LIST: (state, payload) => {
            state.appreciationList = payload
        },
        GET_DEFAULT_CONSENT_TERMS: (state) => {
            state.service.ListAllDefaultConsentTerms().then((response) => {
                state.defaultConsentTermsText = response
            })
        },
        GET_CAMPAIGN_CONSENT_TERMS: (state) => {
            const campaignId = state.landingPageConfig?.campaign?.id;
            state.service.ListAllPaymentMethodConsentTermsByCampaignId(campaignId).then((response) => {
                state.consentTermsText = response
            })
        },
    },
    getters: {
        campaignLandingPageConfig: state => {
            return state.landingPageConfig;
        },
        campaignAppreciationList: state => {
            return state.campaignAppreciationList;
        },
        paymentMethodProviders: state => {
            return {
                pix: state.landingPageConfig.campaign.campaignPaymentMethodProvider.pixPaymentMethodProviderId,
                ticket: state.landingPageConfig.campaign.campaignPaymentMethodProvider.ticketPaymentMethodProviderId,
                creditCard: state.landingPageConfig.campaign.campaignPaymentMethodProvider.creditCardPaymentMethodProviderId
            }
        },
        paymentTaxes: state => {
            let taxes = null

            if (state.landingPageConfig && state.landingPageConfig.campaign) {
                taxes = {
                    Pix: {
                        taxOwner: state.landingPageConfig.campaign.taxOwnerPix,
                        taxType: "percentage"
                    },
                    Ticket: {
                        taxOwner: state.landingPageConfig.campaign.taxOwnerTicket,
                        taxType: "fixed"
                    },
                    CreditCard: {
                        taxOwner: state.landingPageConfig.campaign.taxOwnerCreditCard,
                        taxType: "percentage"
                    }
                }

                state.paymentTaxes = {...taxes};
            }
                
            return taxes;
        },
        campaignId: state => {
            return state.landingPageConfig.id
        },
        isIncetived: state => {
            return state.isIncetived;
        },
        incomeTax: state => {
            return state.incomeTax;
        },
        recurrencePeriodList: state => {
            return state.recurrencePeriodList;
        },
        entityName: state => {
            if (state.landingPageConfig &&
                state.landingPageConfig.campaign &&
                state.landingPageConfig.campaign.company &&
                state.landingPageConfig.campaign.company.entity)
                return state.landingPageConfig.campaign.company.entity.name
            else return ""
        },
        entityCNPJ: state => {
            if (state.landingPageConfig &&
                state.landingPageConfig.campaign &&
                state.landingPageConfig.campaign.company &&
                state.landingPageConfig.campaign.company.entity)
                return state.landingPageConfig.campaign.company.entity.cnpj
            else return ""
        },
        profile: state => {
            return state.profile;
        },
        campaignProductCall: state => {
            return state.campaignProductCall;
        },
        projectButtons: state => {
            return state.projectButtons;
        },
        campaignProducts: state => {
            return state.campaignProducts;
        },
        campaignGifts: state => {
            return state.campaignGifts;
        },
        campaignGiftsConfig: state => {
            return state.campaignGiftsConfig;
        },
        appreciationList: state => {
            return state.appreciationList;
        },
        defaultConsentTermsText: state => {
            return state.defaultConsentTermsText;
        },
        consentTermsText: state => {
            return state.consentTermsText;
        },
    },
    actions: {
        setLandingPageConfig: async (state) => {
            try {
                if(state.state.landingPageConfig == null){
                    const payload = await state.state.service.getCampaignLandingPageConfigBySubdomain(false)
                    state.commit("INIT_CAMPAIGN_LANDINGPAGE_CONFIG", payload);
                    state.commit("SET_RECURRENCE_PERIOD_LIST");
                    if (state.getters.logged) state.commit("GET_PROFILE");
                }
            } catch (error) {
                throw new Error(`Erro ao carregar dados da campaign landing page ${error}`);
            }
        },
        getProfile: async (state) => {
            state.commit("GET_PROFILE");
        },
        getCampaignProductCall: async state => {
            try {
                const campaign = await state.state.service.findBySubdomain()
                state.commit("SET_PRODUCT_CALL", campaign.productCall)
            } catch (error) {
                throw new Error(`Erro ao carregar dados do carrinho ${error}`);
            }
        },
        checkCampaignType: async state => {
            try {
                const campaign = await state.state.service.findBySubdomain()
                const isIncetived = campaign.campaginTypeId === state.state.campaignTypes.Incentivada   
                state.commit('SET_PROJECT_INCETIVED', isIncetived)
            } catch (error) {
                throw new Error(`Erro ao carregar dados do carrinho ${error}`);
            }
        },
        setIncomeTax: async state => {            
            if (state.LandingPageConfig)
                state.commit('SET_INCOME_TAX')
            else {
                await state.dispatch('setLandingPageConfig')
                state.commit('SET_INCOME_TAX')
            }
        },
        setProjectButton: (state, payload) => {
            state.commit('SET_PROJECT_BUTTON', payload)
        },
        refreshCampaignProducts: (state, payload = null) => {
            state.commit('REFRESH_CAMPAIGN_PRODUCTS', payload)
        },
        refreshCampaignGifts: (state, payload = null) => {
            state.commit('REFRESH_CAMPAIGN_GIFTS', payload)
        },
        refreshGiftsConfig: (state, payload = null) => {
            state.commit('REFRESH_GIFTS_CONFIG', payload);
        },
        changeProductStatus: (state, payload) => {
            state.commit('CHANGE_PRODUCT_STATUS', payload);
        },
        deleteProduct: (state, payload) => {
            state.commit('DELETE_PRODUCT', payload);
            state.commit('REFRESH_CAMPAIGN_PRODUCTS');
        },
        changeGiftStatus: (state, payload) => {
            state.commit('CHANGE_GIFT_STATUS', payload);
        },
        getAppreciationList: (state) => {
            state.commit('GET_APPRECIATION_LIST');
        },
        setAppreciationList: (state, payload) => {
            state.commit('SET_APPRECIATION_LIST', payload)
        },
        getDefaultConsentTerms: (state) => {
            state.commit('GET_DEFAULT_CONSENT_TERMS')
        },
        getCampaignConsentTerms: (state) => {
            state.commit('GET_CAMPAIGN_CONSENT_TERMS')
        },
    }
}

export default CampaignModule