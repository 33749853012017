export default class CartItem {

    constructor(project = null, donationProduct = null) {
        this.id = 0;
        this.project = project;
        this.donationProduct = donationProduct;
        this.itemValue = 0;
        this.campaignProduct = null,
        this.campaignProductId = null
        
        this.institutionName =  project && project.institution && project.institution.entity ? project.institution.entity.name : '';
        this.projectId = project ? project.id : 0;
        this.donationProductId = donationProduct ? donationProduct.id : null;
        this.file = donationProduct ? donationProduct.file : project && project.projectLandingPageConfig ? project.projectLandingPageConfig.heroFile : null;
        this.name = donationProduct ? donationProduct.name : project ? project.shortName : '';
    }
}