import Address from "@/scripts/models/address.model";

export default class Entity {

    constructor() {

        this.id = 0;
        this.address = new Address();
        this.name = "";
        this.socialReason = "";
        this.cnpj = "";
		this.type = 0;
		this.creationDate = null;

    }

}