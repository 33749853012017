<template>
  <v-content>
    <v-container>
      <v-layout>
        <div style="max-width: 500px;margin:0 auto">
          <v-layout row wrap>
            <v-flex pa-4>
              <div class="login d-flex align-center justify-center flex-column">
                  <h2>Bem-vindo %João da Silva%</h2>
                  <p>Informe abaixo o número do seu celular</p>
              </div>
              <v-flex class="lg12 mt-5">
                <div class="input-comp">
                  <label class="d-flex">Numero do celular</label>
                </div>
              </v-flex>
              <div class="d-flex">
                <v-flex class="item md3 sm4">
                  <input-text
                    v-model="username"
                    type="text"
                    place-holder="DDD"
                    tabindex="1"
                    autocomplete="on"
                  />
                </v-flex>
                <v-flex class="item md9 sm8 pl-2">
                  <input-text
                    v-model="username"
                    type="text"
                    place-holder="Numero do celular"
                    textLabel
                    tabindex="2"
                    autocomplete="on"
                  />
                </v-flex>
              </div>
              <div class="text-xs-center">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 mt-4"
                  large
                  @click="recoverPassword"
                  :disabled="disableButtonRegister"
                >CADASTRAR</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputPassword from "@/components/input/InputPassword.vue";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";

export default {
  props: ["person", "landingPageConfig"],
  components: {
    Container,
    InputPassword,
    FileImage
  },
  data() {
    return {
      password: "",
      passwordConfirm: "",
      agreedWithTerms: false,
      showPolicyTerms: false,
      showServiceTerms: false,
      levelPassword: 0,
      userService: new UserService(),
      personService: new PersonService(),
      campaignService: new CampaignService(),
      disableButtonRegister: true,
    };
  },
  created() {
    
  },
  methods: {
    changeLevelPassword(levelPassword) {
      this.levelPassword = levelPassword;
    },
    disabledButton() {
      if (
        this.password.length >= 8 &&
        this.levelPassword >= 2 &&
        this.agreedWithTerms
      )
        if (this.password === this.passwordConfirm)
          this.disableButtonRegister = false;
        else this.disableButtonRegister = true;
      else this.disableButtonRegister = true;
    },
    callbackRegister(person) {
      this.$store.dispatch("setRegisterUser", person);
      this.$emit("register");
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    }
  }
};
</script>