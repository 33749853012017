import BaseService from '@/scripts/services/base.service';
import CommonHelper from '../helpers/common.helper';
import axios from 'axios';
// import CheckoutRequest from '@/scripts/models/checkoutRequest.model';

export default class VindiService extends BaseService {

  constructor() {
    super('Vindi');
    this.privateKey = "WlL_WCS3x-N-AzW3zBePQJjvDxS3pmOiVHIvd2hRxPI"; //sandbox
    this.publicKey = "Basic cHVEeF9iOHVjenVsQWdJNTdBVU9sRWd0OWY0X1o3SHpfelZ0QTI2c091bzo=" //sandbox
    this.vindiUrlPaymentProfile = "https://app.vindi.com.br/api/v1/public/payment_profiles";
    this.basicAuth = "Basic " + btoa("puDx_b8uczulAgI57AUOlEgt9f4_Z7Hz_zVtA26sOuoE" + ":");
    axios.baseURL = 'https://sandbox-app.vindi.com.br/api/v1';
  }

  createPaymentProfile(checkoutRequest){
    debugger
    var paymentProfile = this.mountPaymentProfile(checkoutRequest);
    return axios.post('https://sandbox-app.vindi.com.br/api/v1/public/payment_profiles', paymentProfile, {
      headers: { 'Authorization': this.publicKey }
    })
  }

  mountPaymentProfile(checkoutRequest){
    return {      
      holder_name: checkoutRequest.debitAccountInfo.holder.name,
      registry_code: checkoutRequest.debitAccountInfo.holder.CPF,
      bank_branch: checkoutRequest.debitAccountInfo.bankBranch,
      bank_account: checkoutRequest.debitAccountInfo.bankAccount + "-" + checkoutRequest.debitAccountInfo.bankAccountDigit,
      payment_method_code: "bank_debit",
      payment_company_code: checkoutRequest.debitAccountInfo.paymentCompanyCode
    }
  }
}