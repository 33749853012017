import File from "@/scripts/models/file.model";
import CampaignLandingPageBlockIdentifier from "@/scripts/models/enums/campaignLandingPageBlockIdentifier.enum";
import Campaign from "@/scripts/models/campaign.model";
export default class CampaignLandingPageConfig {
	constructor() {
		this.id = 0;
		this.heroTitle = "Escreva aqui a chamada principal do projeto";
		this.heroParagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind of shining texts";
		this.heroSubparagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.";
		this.numberParagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.";
		this.numberSubtitle = "A campanha em números";
		this.simulationTitle = "Faça uma simulação";
		this.memberGetMemberTitle = "Indique";
		this.simulationParagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind of shining texts";
		this.memberGetMemberParagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind of shining texts";
		this.donationStepsTitle = "Passo-a-passo para se tornar um doador";
		this.projectListTitle = "Conheça os projetos da campanha";
		this.casesTitle = "Conheça nossas histórias";
		this.newsListTitle = "Notícias";
		this.videoTitle = "Título do vídeo";
		this.videoParagraph = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind of shining texts";
		this.photoGalleryTitle = "Galeria de Fotos";
		this.appreciationTitle = "Agradecimentos";
		this.testimonyTitle = "Depoimentos";
		this.partnerTitle = "Parceiros";
		this.footnotes = "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.";
		this.copyrightText = " © 2019 - Todos os direitos reservados";
		this.donationVoucherTitle = "Sua empresa pode fazer uma doação diretamente para a campanha.";
        this.donationVoucherSubTitle = "Sua empresa fez uma doação?";
        this.donationVoucherParagraph = "Qualem igitur hominem natura inchoavit? De ingenio eius <br /> in his disputationibus, non de moribus quaeritur.";
        this.donationVoucherBank = "Banco";
        this.donationVoucherAgency = "000-1";
        this.donationVoucherAccount = "000000-1";
        this.donationVoucherCNPJ = "123421543/0001-23";
        this.donationVoucherSocialReason = "Lorem ipsum dolor sit amet";
		this.showNavigationBlock = true;
		this.showDonationBarBlock = true;
		this.showNumberBlock = true;
		this.showProjectsBlock = true;
		this.showSimulationBlock = true;
		this.showMemberGetMemberBlock = true;
		this.showCasesBlock = true;
		this.showDonationStepsBlock = true;
		this.showNewsBlock = true;
		this.showVideoBlock = true;
		this.showPhotoGalleryBlock = true;
		this.showAppreciationBlock = true;
		this.showTestimonyBlock = true;
		this.showPartnerBlock = true;
		this.showDonationVoucherBlock = true;
		this.showMenuBlock = true;
		this.showDonationBarBlock = true;
		this.showProjectsBlock = true;
		this.showDonationProductsBlock = true;
		this.showDirectBeneficiariesNumber = true;
		this.showIndirectBeneficiariesNumber = true;
		this.directBeneficiariesValue = null;
		this.indirectBeneficiariesValue = null;
		this.heroAlignment = 1;
		this.heroFile = new File();
		this.simulationFile = new File();
		this.memberGetMemberFile = new File();
		this.videoFile = new File();
		this.testimonyFile = new File();
		this.themePrimaria = '[63, 162, 247, 1]';
		this.themeSecundaria = '[41, 127, 202, 1]';
		this.themeDestaque = '[250, 110, 110, 1]';
		this.themeFundo = '[228, 241, 253, 1]';
		this.themePadrao = '[195, 198, 213, 1]';
		this.themeGradienteStart = '[51, 119, 180, 1]';
		this.themeGradienteEnd = '[228, 241, 253, 1]';
		this.themeTexto = '[41, 127, 202, 1]';
		this.fontPrincipal = 'Nunito';
		this.fontDestaques = 'IBM Plex Serif';
		this.heroImageOpacity = 0.7;
		this.heroOverlayOpacity = 0.3;
		this.simulationImageOpacity = 0.7;
		this.memberGetMemberImageOpacity = 0.7;
		this.simulationOverlayOpacity = 0.3;
		this.memberGetMemberOverlayOpacity = 0.3;
		this.videoImageOpacity = 0.7;
		this.videoOverlayOpacity = 0.3;
		this.testimonyImageOpacity = 0.7;
		this.testimonyOverlayOpacity = 0.3;
		this.videoLink = "";
		this.navigationSectionName = "Barra de navegação";
		this.numberSectionName = "Números";
		this.simulationSectionName = "Simulador";
		this.memberGetMemberSectionName = "Indicação";
		this.casesSectionName = "Casos";
		this.donationStepsSectionName = "Passos";
		this.newsSectionName = "Notícias";
		this.videoSectionName = "Vídeo";
		this.photoGallerySectionName = "Galeria";
		this.appreciationSectionName = "Agradecimentos";
		this.testimonySectionName = "Testemunhas";
		this.partnerSectionName = "Parceiros";
		this.donationVoucherSectionName = "Comprovante";
		this.menuSectionName = "Menu";
		this.donationBarSectionName = "Barra de arrecadação";
		this.projectsSectionName = "Projetos";
		this.donationButtonText = "Quero doar";
		this.simplifiedDonationSectionName = "Checkout Simplificado",
		this.donationProductsSectionName = "Veja como você pode ajudar";
		this.showSimplifiedDonationBlock = false;
		this.simplifiedDonationTitle = "";
		this.donationProductsTitle = "";
		this.campaignCaseList = [];
		this.campaignDonationStepList = [];
		this.projectList = [];
		this.campaignNewsList = [];
		this.campaignImageList = [];
		this.campaignAppreciationList = [];
		this.campaignTestimonyList = [];
		this.campaignPartnerList = [];
		this.campaignDonationVoucherList = [];
		this.campaign = null; //new Campaign();
		this.showCallToActioOnNav = false;
		this.titleCallToActioOnNav = "";
		this.showPhotoCardsBlock = false;
        this.photoCardsSectionName = "";
        this.photoCardsTitle = "";
		this.editing = false;
		this.blockOrder = [
			CampaignLandingPageBlockIdentifier.NumberBlock,
			CampaignLandingPageBlockIdentifier.DonationProductsBlock,
			CampaignLandingPageBlockIdentifier.ProjectBlock,
			CampaignLandingPageBlockIdentifier.CaseBlock,
			CampaignLandingPageBlockIdentifier.StepBlock,
			CampaignLandingPageBlockIdentifier.SimulationBlock,
			CampaignLandingPageBlockIdentifier.MemberGetMemberBlock,
			CampaignLandingPageBlockIdentifier.NewsBlock,
			CampaignLandingPageBlockIdentifier.AppreciationBlock,
			CampaignLandingPageBlockIdentifier.TestimonyBlock,
			CampaignLandingPageBlockIdentifier.VideoBlock,
			CampaignLandingPageBlockIdentifier.PhotoGalleryBlock,
			CampaignLandingPageBlockIdentifier.PartnerBlock,
			CampaignLandingPageBlockIdentifier.DonationVoucherBlock,
			CampaignLandingPageBlockIdentifier.SimplifiedDonationBlock,
			CampaignLandingPageBlockIdentifier.VolunteeringBlock
		];	
	}
}