
export default class Phone {

    constructor() {
        
        /**
         * DDD do comprador. Especifica o código de área (DDD) do comprador que está realizando o pagamento. Este campo é opcional e você pode enviá-lo caso já tenha capturado os dados do comprador em seu sistema e queira evitar que ele preencha esses dados novamente no PagSeguro.
         * Formato: Um número de 2 dígitos correspondente a um DDD válido.
         */
        this.areaCode = 0;

        /**
         * Número do telefone do comprador. Especifica o número do telefone do comprador que está realizando o pagamento. Este campo é opcional e você pode enviá-lo caso já tenha capturado os dados do comprador em seu sistema e queira evitar que ele preencha esses dados novamente no PagSeguro.
         * Formato: Um número de 7 a 9 dígitos.
         */
        this.number = 0;
    }
}