import BaseCrudService from "./baseCrud.service";

export default class ProjectService extends BaseCrudService {

	constructor() {
		super('Project');
	}

	findById(projectId) {
		return this.getRequest('FindByProjectId', { id: projectId });
	}

	findByIdWithGoal(projectId) {
		return this.getRequest('FindByIdWithGoal', { id: projectId });
	}

	findProjectToAddToCart(projectId) {
		return this.getRequest('FindProjectToAddToCart', { id: projectId });
	}

	deleteProject(projectId) {
		return this.deleteRequest('Delete', { id: projectId });
	}

	listAll(entityId, institutionId) {
		if (institutionId) {
			return this.getRequest('ListAllProjects', { entityId: entityId });
		}
		else {
			return this.getRequest('ListAllProjects', { entityId: null });
		}
	}

	listByEntity(entityId) {
		return this.getRequest('ListByEntityId', { entityId });
	}

	listAllWithGoals() {
		return this.getRequest('ListAllWithGoals');
	}

	sendEmailGoalLate(projectId) {
		return this.getRequest('SendEmailGoalLate', { projectId: projectId });
	}

	sendEmailNotificationToDonatorByGoal(goalValueId) {
		return this.getRequest('SendEmailNotificationToDonatorByGoal', { goalValueId });
	}

	listAllProjectsWithCaptureStatusOn(type) {
		return this.getRequest('ListAllProjectsWithCaptureStatusOn', { type: type }, true, false);
	}

	save(project) {
		return this.postRequest('Save', project);
	}

	saveLandingPageConfig(projectLandingPageConfig) {
		return this.postRequest("SaveLandingPageConfig", projectLandingPageConfig, true, false);
	}

	getLandingPageConfigByProjectId(projectId) {
		return this.getRequest("GetLandingPageConfigByProjectId", { projectId: projectId });
	}

	buildProjectLandingPage(projectId) {
		return this.getRequest("BuildProjectLandingPage", { projectId: projectId });
	}

	saveAccountabilityHero(projectAccountabilityHeroConfig) {
		return this.postRequest("SaveProjectAccountabilityHero", projectAccountabilityHeroConfig);
	}

	getProjectAccountabilityLandingPage(projectId) {
		return this.getRequest("GetProjectAccountabilityLandingPage", { projectId: projectId });
	}

	follow(projectId) {
		return this.postRequest('Follow', { projectId: projectId }, false, false);
	}

	unfollow(projectId) {
		return this.postRequest('Unfollow', { projectId: projectId }, false, false);
	}

	saveProjectEvaluation(projectEvaluation) {
		return this.postRequest('EvaluateProject', projectEvaluation, false, false);
	}

	findProjectEvaluationByUserAndProject(projectId) {
		return this.getRequest('FindProjectEvaluationByUserAndProject', { projectId: projectId }, false, true);
	}

	findProjectsUserFollow() {
		return this.getRequest('FindProjectsUserFollow');
	}

	listAllODS(callback) {
		this.getRequest('ListAllODS').then(callback);
	}

	listAllIncentiveLaw(callback) {
		this.getRequest('ListAllIncentiveLaw').then(callback);
	}

	listAllCause(callback) {
		this.getRequest('ListAllCause').then(callback);
	}

	listAllTargetAudience(callback) {
		this.getRequest('ListAllTargetAudience').then(callback);
	}

	listAllChildrenFund(callback) {
		this.getRequest('ListAllChildrenFund').then(callback);
	}

	listAllElderlyFund(callback) {
		this.getRequest('ListAllElderlyFund').then(callback);
	}

	listAllSportingManifestation(callback) {
		this.getRequest('ListAllSportingManifestation').then(callback);
	}

	ListAllAgeRangeGroup(callback) {
		this.getRequest('ListAllAgeRangeGroup').then(callback);
	}

	listAllCoverage(callback, ProjectId) {
		this.postRequest('ListAllCoverage', { Id: ProjectId }).then(callback);
	}

	listAllGoalVerification(callback) {
		this.getRequest('ListAllGoalVerification').then(callback);
	}

	listAllGoalType(callback) {
		this.getRequest('ListAllGoalType').then(callback);
	}

	listAllBank(callback) {
		this.getRequest('ListAllBank').then(callback);
	}

	listByInstitutionAndName(callback, institutionId, name) {
		this.getRequest("ListByInstitutionAndName", { institutionId, name }).then(callback);
	}

	findByIds(callback, ids) {
		this.postRequest("FindByIds", ids).then(callback);
	}

	sendContact(callback, contact) {
		this.postRequest('SendContact', contact).then(callback);
	}

	publish(projectId) {
		return this.postRequest('Publish', { projectId: projectId }, false);
	}

	suspend(projectId) {
		return this.postRequest('Suspend', { projectId: projectId }, false);
	}

	changeCaptureStatus(projectId, currentStatus) {
		if (currentStatus) {
			return this.postRequest('TurnOnCaptureStatus', { projectId: projectId }, false);
		}
		else {
			return this.postRequest('TurnOffCaptureStatus', { projectId: projectId }, false);
		}
	}

	changeProjectDate(projectId, initialDate, finalDate) {
		return this.putRequest('ChangeProjectDate', { projectId, initialDate, finalDate });
	}

	changeSameBeneficiaries(projectId, sameBeneficiaries, currentStepStatus) {
		return this.postRequest('ChangeSameBeneficiaries', { projectId: projectId, sameBeneficiaries: sameBeneficiaries, currentStepStatus: currentStepStatus }, true, false);
	}

	clone(projectClone) {
		return this.postRequest('Clone', projectClone);
	}

	// Coverage
	listCoverageByProjectId(projectId) {
		return this.getRequest('listCoverageByProjectId', { id: projectId });
	}

	saveCoverage(callback, coverage) {
		this.postRequest('SaveCoverage', coverage).then(callback);
	}

	saveCoverageVolunteering(callback, coverage) {
		this.postRequest('SaveCoverageVolunteering', coverage).then(callback);
	}

	deleteCoverage(callback, coverageId) {
		this.deleteRequest('DeleteCoverage', { id: coverageId }).then(callback);
	}

	// Donation Product
	listDonationProductByProjectId(projectId) {
		return this.getRequest('listDonationProductByProjectId', { id: projectId });
	}

	findDonationProduct(donationProductId) {
		return this.getRequest('FindDonationProduct', { donationProductId: donationProductId });
	}

	findDonationProductByCustomUrl(donationProductCustomUrl, showMessage = true, showLoader = true) {
		return this.getRequest('findDonationProductByCustomUrl', { donationProductCustomUrl: donationProductCustomUrl }, showMessage, showLoader);
	}

	saveDonationProduct(callback, donationProduct) {
		this.postRequest('SaveDonationProduct', donationProduct).then(callback);
	}

	deleteDonationProduct(callback, donationProductId) {
		this.deleteRequest('DeleteDonationProduct', { id: donationProductId }).then(callback);
	}

	// Schedule
	listScheduleByProjectId(projectId) {
		return this.getRequest('listScheduleByProjectId', { id: projectId });
	}

	saveSchedule(callback, schedule) {
		this.postRequest('SaveSchedule', schedule).then(callback);
	}

	deleteSchedule(callback, scheduleId) {
		this.deleteRequest('DeleteSchedule', { id: scheduleId }).then(callback);
	}

	findSchedulePeriodById(callback, id) {
		return this.getRequest('FindSchedulePeriodById', { id: id }).then(callback);
	}

	saveSchedulePeriod(callback, schedulePeriod) {
		this.postRequest('SaveSchedulePeriod', schedulePeriod).then(callback);
	}

	changeSchedulePeriodStatus(schedulePeriodId, status) {
		return this.putRequest('ChangeSchedulePeriodStatus', { schedulePeriodId, status });
	}

	// Goal
	listGoalByProjectId(projectId) {
		return this.getRequest('listGoalByProjectId', { id: projectId });
	}

	saveGoal(callback, goal) {
		this.postRequest('SaveGoal', goal).then(callback);
	}

	saveGoalValue(callback, goalValue) {
		this.postRequest('SaveGoalValue', goalValue).then(callback);
	}

	findGoalValueById(callback, id) {
		return this.getRequest('FindGoalValueById', { id: id }).then(callback);
	}

	deleteGoalsByProject(callback, projectId) {
		this.deleteRequest('DeleteGoalsByProject', { id: projectId }).then(callback);
	}

	deleteGoal(callback, goalId) {
		this.deleteRequest('DeleteGoal', { id: goalId }).then(callback);
	}

	// Supporter
	saveSupporterList(supporterList, projectId) {
		return this.postRequest('SaveSupporterList', supporterList, true, false);
	}

	deleteSupporter(callback, supporterId) {
		this.deleteRequest('DeleteSupporter', { id: supporterId }).then(callback);
	}

	// News
	listNewsByProjectId(callback, projectId) {
		this.getRequest('ListNewsByProjectId', { id: projectId }).then(callback);
	}

	getNewsById(callback, newsId) {
		this.getRequest('GetNewsById', { id: newsId }).then(callback);
	}

	saveNews(callback, news) {
		this.postRequest('SaveNews', news).then(callback);
	}

	deleteNews(callback, newsId) {
		this.deleteRequest('DeleteNews', { id: newsId }).then(callback);
	}

	listProjectAreas(callback) {
		this.getRequest('ListProjectArea').then(callback);
	}

	async listAllDonationProductsBySubdomain() {
		return await this.getRequest('ListAllDonationProductsBySubdomain');
	}

	listAllDonationProductsByCampaignId(callback, campaignId) {
		this.getRequest('ListAllDonationProductsByCampaignId', { id: campaignId }).then(callback);
	}

	// Volunteering
	findProjectByVolunteering(volunteeringId) {
		return this.getRequest('FindProjectByVolunteering', { volunteeringId: volunteeringId });
	}

	createProjectFromVolunteering(volunteeringId) {
		return this.postRequest(`CreateProjectFromVolunteering/${volunteeringId}`);
	}
}