const DonationRecurrencePeriod = {
    /**
     * Nenhum
     */
    None: 0,

    /**
     * Única
     */
    Single: 1,

    /**
     * Mensal
     */
    EveryOneMonth: 2,

    /**
     * Bimestral
     */
    EveryTwoMonths: 3,

    /**
     * Trimestral
     */
    EveryThreeMonths: 4,

    /**
     * Quadrimestral
     */
    EveryFourMonths: 5,

    /**
     * Semestral
     */
    EverySixMonths: 6,

    /**
     * Anual
     */
    EveryTwelveMonths: 7,


};

export default DonationRecurrencePeriod;