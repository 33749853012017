import PaymentSenderInfo from "./paymentSenderInfo.model";
import BillingAddress from "./billingAddress.mode";
export default class DebitAccountInfo {
    constructor() { 
        this.holder = new PaymentSenderInfo();
        this.billingAddress = new BillingAddress();
        this.holder_name = "",
        this.registry_code = "",
        this.bankBranch = "",
        this.bankAccount = "",
        this.bankAccountDigit = "",
        this.paymentMethodCode = "",
        this.paymentCompanyCode = "",
        this.customerId = 0,
        this.dayOfMonth = 0
    }

    
}