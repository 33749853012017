import CommonHelper from '@/scripts/helpers/common.helper';

const PERMISSIONS = 'permissions';
const SOCIAL_MEDIA_LOGIN = 'SOCIAL_MEDIA_LOGIN';
const MISSING_INFORMATION = 'MISSING_INFORMATION';
const RELOAD_CART = 'RELOAD_CART';

const PermissionStorage = {
    getRoles() {
        let roles = localStorage.getItem(PERMISSIONS);
        if (!roles)
            return null;
        let rolestransf = JSON.parse(roles);
        let rolesReturn = [];

        rolestransf.forEach(role => {
            let r = { entity: { id: role.i, type: role.t }, permissionList: role.r }
            rolesReturn.push(r);
        });

        return rolesReturn;
    },

    setRoles(roles) {
        let rolestranf = [];
        roles.forEach(role => {
            let r = { i: role.entity.id, t: role.entity.type, r: role.permissionList }
            rolestranf.push(r);
        });
        localStorage.setItem(PERMISSIONS, JSON.stringify(rolestranf));
    },

    removeRoles() {
        localStorage.removeItem(PERMISSIONS);
    },

    getSocialMediaLogin() {
        let data = sessionStorage.getItem(SOCIAL_MEDIA_LOGIN);
        return JSON.parse(data);
    },

    setSocialMediaLogin(payload) {
        sessionStorage.setItem(SOCIAL_MEDIA_LOGIN, JSON.stringify(payload));
    },

    removeSocialMediaLogin() {
        sessionStorage.removeItem(SOCIAL_MEDIA_LOGIN);
    },

    getMissingInformation() {
        let data = sessionStorage.getItem(MISSING_INFORMATION);
        return JSON.parse(data);
    },

    setMissingInformation(payload) {
        sessionStorage.setItem(MISSING_INFORMATION, JSON.stringify(payload));
    },

    removeMissingInformation() {
        sessionStorage.removeItem(MISSING_INFORMATION);
    },

    getReloadCart() {
        let data = JSON.parse(sessionStorage.getItem(RELOAD_CART));
        if (data) return JSON.parse(data);
        return false;
    },

    setReloadCart(payload) {
        if (payload) {
            sessionStorage.setItem(RELOAD_CART, payload);
            return JSON.parse(sessionStorage.getItem(RELOAD_CART));
        } else {
            sessionStorage.removeItem(RELOAD_CART);
            return false;
        }
    },
}

export default PermissionStorage;