<template>
  <Container maxWidth="960" class="c-login p-relative" style="box-shadow: none;">
    <a href="javascript:;" class="btn-close-dialog" 
    @click="closeModal();  
    $gtag('send', 'event', 'Cadastro sem Sucesso', 'Cadastro', 'Cadastro sem Sucesso')"
    v-if="$router.currentRoute.name !== 'checkout-v2' && $router.currentRoute.name !== 'cart-logged'">
      <Icon name="fal fa-times" size="24" colorFont="#C3C6D5" />
    </a>
    <v-layout row wrap box-login v-if="registerStep == 1">
      <!-- tab 1 -->
      <v-flex tab-login :class="{ active: tab == 'login' }">
        <a href="javascript:;" class="tab" @click="changeTab('login')">
          <h2>Já sou cadastrado</h2>
          <p>Faça login</p>
        </a>
        <div class="form-block">
          <input-text
            v-model="username"
            type="text"
            :place-holder="(campaign && campaign.permitCnpjDonation) ? 'Entre com seu email, CPF ou CNPJ' : 'Entre com seu email ou CPF'"
            tabindex="1"
            autocomplete="on"
            ref="usernameInput"
          />
          <InputPassword
            v-model="password"
            type="password"
            place-holder="Sua senha"
            :force="false"
            @keyup.enter.native="login"
            class="mt-4"
          />
          <a
            @click="enterVerificationCode"
            class="forgot-password"
            v-if="registerUser.email"
          >Código de verificação</a>
          <a
            @click="
              goToForgotPassword();
              $gtag('send', 'event', 'Esqueceu senha', 'Existe Cadastro', window.location.href + 'esqueci-senha');"
            class="forgot-password"
            v-else
          >Esqueceu a senha?</a>
          <!-- <v-checkbox v-model="checkbox1" :label="`Permanecer logado`"></v-checkbox> -->
          <Recaptcha
            ref="recaptcha1"
            v-if="campaign != null && campaign.withRecaptcha"
            :key="1"
            @recaptchaSuccess="recaptchaSuccess"
          />
          <div class="text-xs-center">
            <v-btn
              flat
              round
              color="white"
              class="btn-primary px-5 mt-4"
              large
              @click="login(); $gtag('send', 'event', 'Login', 'Login', 'Entrar');"
            >LOGIN</v-btn>
          </div>
          <!-- <template v-if="ambiente = dev">
                  Oi
                  <button class="btn btn-primary ma-1" @click="loginAUC">AUC</button>
                  <button class="btn btn-primary ma-1" @click="loginInst">Instituição</button>
                  <button class="btn btn-primary ma-1" @click="loginEmp">Empresa</button>
          </template>-->
        </div>
      </v-flex>

      <!-- tab 2 -->
      <v-flex tab-login cadastro :class="{ active: tab == 'register' }" :style="getStyle">
        <a href="javascript:;" class="tab" @click="changeTab('register')">
          <h2>Não sou cadastrado</h2>
          <p >Cadastre-se com</p>
        </a>
        <div class="form-block" >
          <InputText
            type="text"
            v-model="person.cpf"
            :place-holder="(campaign && campaign.permitCnpjDonation) ? 'CPF/CNPJ' : 'CPF'"
            :mask="['###.###.###-##', '##.###.###/####-##']"
            @blur="blurCpf()"
            @input="checkByApi(person.cpf)"
          />
          <div v-if="!findPersonInApi && person.alreadyExist != true">
            <InputText
              v-if="showSocialReasonInput"
              type="text"
              :required="true"
              v-model="person.socialReason"
              place-holder="Razão Social"
            />
            <InputText
              v-if="fieldRequired == 'RegisterId'"
              type="text"
              v-model="person.user.usernames[0].value"
              place-holder="Informe número de matrícula"
            />
            <InputText
              type="text"
              v-if="loginPublic || !isCorporativeEmail"
              v-model="person.name"
              :place-holder="showSocialReasonInput ? 'Nome do responsável' : 'Seu nome completo'"
            />
            <InputText
              type="email"
              v-model="person.email"
              place-holder="Informe seu e-mail"
            />
          </div>
          <div class="box-find-person">
            <InputText
              type="text"
              v-if="campaign.memberGetMemberActive"
              :required="false"
              v-model="person.registrationWithMemberCode"
              place-holder="Código de Indicação (Opcional)"
            />
            <button
              v-if="campaign.memberGetMemberActive"
              @click="findPersonByCodeIndication()"
              class="btn-find-person"
            >
              <i class="fal fa-search"></i>
            </button>
          </div>
          <p v-if="campaign.memberGetMemberActive">{{ personIndicationName }}</p>
          <!-- <v-checkbox v-model="agreedWithTerms" :label="`Eu aceito os termos de uso.`"></v-checkbox> -->
          <!-- <a href="">Leia os termos de uso</a> -->
          <Recaptcha
            ref="recaptcha2"
            :key="2"
            v-if="campaign != null && campaign.withRecaptcha"
            @recaptchaSuccess="recaptchaSuccessCreateAcc"
          />
          <v-layout justify-center mt-4>
            <v-flex shrink v-if="!findPersonInApi && person.alreadyExist != true">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 mx-2"
                @click="save(); $gtag('send', 'event', 'Cadastro com sucesso', 'Cadastro', 'Cadastro com sucesso');"
                large
              >CRIAR CONTA</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap box-login v-if="registerStep == 2">
      <PasswordRegistration
        @register="register"
        @registerAndLogin="onLogin"
        :landingPageConfig="landingPageOptions"
        :person="person"
      />
    </v-layout>

    <!-- <v-layout row wrap box-login v-if="registerStep == 3">
      <CelPhoneRegistration @register="register" :landingPageConfig="landingPageOptions" :person="person"/>
    </v-layout>-->

    <v-layout
      row
      wrap
      box-login
      justify-content-center
      v-if="registerStep == 3"
      @click.once="gaLightBox()"
    >
      <!-- tab 1 -->
      <v-flex tab-login :class="{ active: tab == 'login' }">
        <a href="javascript:;" class="tab">
          <h2>Verificação de e-mail</h2>
          <p>Cópie o código enviado em seu e-mail e cole no campo abaixo</p>
        </a>
        <div class="form-block">
          <input-text
            v-model="verificationCode"
            type="text"
            place-holder="Digite o código de verificação"
            tabindex="1"
            autocomplete="on"
            name="verificationCode"
          />
          <a
            @click="
  resendVerificationCode();
$gtag(
  'send',
  'event',
  'Reenvia Código Email',
  'Novo Cadastro',
  'Reenviar Código'
);
            "
            class="forgot-password"
          >Reenviar código</a>

          <v-layout justify-center mt-4>
            <v-flex shrink>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 mx-2"
                @click="
                  checkVerificationCode();
                  $gtag('send', 'event', 'Verifica Código Email', 'Novo Cadastro', 'VERIFICAR');"
                large
              >VERIFICAR</v-btn>
            </v-flex>
            <v-flex shrink>
              <v-btn
                flat
                round
                color="white"
                class="btn-ghost-default px-5 mx-2"
                @click="
                  setStep(1); 
                  $gtag('send', 'event', 'Voltar Código Email', 'Novo Cadastro', 'Voltar');"
                large
                onclick
              >VOLTAR</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap box-login v-if="registerStep == 4">
      <CPFRegistration :landingPageConfig="landingPageOptions" :person="person" />
    </v-layout>

    <v-layout row wrap box-login justify-content-center v-if="registerStep == 5">
      <UpdatePersonInfo :landingPageConfig="landingPageOptions" :missingInfo="missingPersonInfo" />
    </v-layout>

    <div class="sociais-login">
      <template v-if="showSocialMedia">
        <p class="text-gray">
          <span>Ou conecte-se com</span>
        </p>
        <div>
          <v-btn
            flat
            round
            color="white"
            @click="facebookLogin"
            class="btn-facebook text-none"
            large
          >
            <i class="fab fa-facebook-f"></i>
            <span>Facebook</span>
          </v-btn>
          <v-btn @click="googleLogin" flat round class="btn-google text-none" large>
            <i class="fab fa-google icon-google"></i>
            <span>Google</span>
          </v-btn>
        </div>
      </template>
    </div>
    <!--div class="footer-login">
            Caso não consiga acessar a conta
            <a @click="goToForgotPassword">clique aqui.</a>
    </div-->
  </Container>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputText from "@/components/input/InputText.vue";
import InputPassword from "@/components/input/InputPassword.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import UsernameType from "@/scripts/models/enums/usernameType.enum";
import LoginResultType from "@/scripts/models/enums/loginResultType.enum";
import LoginService from "@/scripts/services/login.service";
import CampaignService from "@/scripts/services/campaign.service";
import PersonService from "@/scripts/services/person.service";
import EmployeeService from "@/scripts/services/employee.service";
import UserService from "@/scripts/services/user.service";
import FacebookService from "@/scripts/services/facebook.service";
import GoogleService from "@/scripts/services/google.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import TypeCampaign from "@/scripts/models/enums/typeCampaign.enum";
import { LoginSocialMedia } from "@/scripts/models/enums/socialMedias.enum.js";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import Person from "@/scripts/models/person.model";
import Username from "@/scripts/models/username.model";
import store from "@/store";
import { mapGetters, mapState, mapActions } from "vuex";
import PasswordRegistration from "@/components/login/PasswordRegistration.vue";
import CPFRegistration from "@/components/login/CPFRegistration.vue";
import CelPhoneRegistration from "@/components/login/CelPhoneRegistration.vue";
import UpdatePersonInfo from "@/components/login/UpdatePersonInfo.vue";
import Recaptcha from "@/components/login/Recaptcha.vue";
import Campaign from "../../scripts/models/campaign.model";
import PaymentMethod from "../../scripts/models/enums/paymentMethod.enum";

export default {
  props: [
    "action",
    "email",
    "facebookId",
    "googleId",
    "name",
    "campaign",
    "inline",
  ],
  components: {
    Container,
    InputText,
    InputPassword,
    PasswordRegistration,
    CelPhoneRegistration,
    CPFRegistration,
    UpdatePersonInfo,
    Recaptcha,
  },
  data() {
    return {
      tab: "login",
      username: null,
      password: null,
      returnRoute: null,
      fieldRequired: null,
      returnQuery: null,
      showOptionLogin: null, //TODO verificar opcao de login
      loginPublic: null,
      haveBtnBack: false,
      person: new Person(),
      campaignService: new CampaignService(),
      loginService: new LoginService(),
      userService: new UserService(),
      personService: new PersonService(),
      employeeService: new EmployeeService(),
      isCorporativeEmail: true,
      landingPageOptions: new CampaignLandingPageOptions(),
      verificationCode: "",
      openedCPFRegistrationModal: false,
      openedUpdatePersonInfoModal: false,
      missingPersonInfo: {},
      personIndicationName: "",
      recaptchaToken: null,
      recaptchaTokenCreateAcc: null,
      showSocialReasonInput: false,
      findPersonInApi: true,
    };
  },
  computed: {
    ...mapGetters([
      "registerUser",
      "registerStep",
      "socialMediaLogin",
      "missingInformation",
      "reloadCart",
      "cartItems",
    ]),
    showSocialMedia() {
      let typeCampaign = this.typeCampaign();
      return (
        (this.showOptionLogin ||
          (this.isCorporativeEmail && this.loginPublic)) &&
        typeCampaign != TypeCampaign.OnlyEmployee &&
        this.registerStep == 1
      );
    },
    getStyle() {
      if (this.campaign && this.campaign.withRecaptcha)
        return "overflow: auto"
    },
  },
  created() {
    this.person.user.usernames = [
      new Username("", UsernameType.RegistrationId),
    ];
    this.person.name = this.name;
    this.person.email = this.email;
    this.person.user.facebookId = this.facebookId;
    this.person.user.googleId = this.googleId;
    if (this.$route.query.returnRoute != null) {
      this.returnRoute = this.$route.query.returnRoute;
    }
    if (this.$route.query.returnQuery != null) {
      this.returnQuery = this.$route.query.returnQuery;
    }

    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function (data) {
        this.landingPageOptions = data;
        this.setCampaignApiList(data.campaign.id);
      }.bind(this)
    );
    //this.tab = this.action || "login";
    this.fieldRequired =
      this.campaign == null ? null : this.campaign.requiredField;

    this.changeTypeCampaign();

    var step = localStorage.getItem("registerStep");
    if (step != null) this.setRegisterStep(step);
  },
  watch: {
    campaign() {
      this.changeTypeCampaign();
      if (!this.openedUpdatePersonInfoModal) {
        this.openUpdatePersonInfoModal();
      }
      if (!this.openedCPFRegistrationModal) {
        this.openCPFRegistrationModal();
      }
    },
  },
  methods: {
    ...mapActions(["setLogged", "setRegisterStep", "setRegisterUser"]),
    checkByApi(cpf){
      if (cpf && cpf.length == 11) {

        this.person.name = null;
        this.person.email = null;

        this.personService.getByApiList(this.person).then(data => {
          this.findPersonInApi = false;
          this.person.alreadyExist = false;

          if (data) {
            if (data.alreadyExist && !this.person.user.username && !this.person.user.password) {
              this.findPersonInApi = true;
              this.person.alreadyExist = data.alreadyExist;
              CommonHelper.swal("Usuário já cadastro. Por favor, faça o Login.")
              this.person.cpf = null;
              this.$refs.usernameInput.$el.children[0].children[0].focus();
            } 
            else {
              this.person.name = data.name;
              this.person.email = data.email;
            }
          }
        });  
      }
    },
    saveCartitems() {
      if (this.reloadCart) this.setReloadCart(false);
      let items = this.cartItems.map((item) => {
        if (item.donationProduct) {
          return {
            projectId: item.project.id,
            donationProductId: item.donationProduct.id,
          };
        } else {
          return {
            projectId: item.project.id,
          };
        }
      });

      this.cartService.mergeCartItems(items).then(
        function (data) {

          // Add campaign product if exist
          data.campaignProduct = this.cartLocal.campaignProduct
          data.campaignProductId = this.cartLocal.campaignProductId

          if (data && data.cartItems) {
            this.updateCart(data);
          }
        }.bind(this)
      );
    },
    setCampaignApiList(campaignId){
      this.campaignService.GetCampaignApiList(campaignId).then(data => {
        if (data) {
          this.campaign.campaignApiList = data;
        }
      });
    },
    recaptchaSuccessCreateAcc(token) {
      this.recaptchaTokenCreateAcc = token;
    },
    recaptchaSuccess(token) {
      this.recaptchaToken = token;
    },
    findPersonByCodeIndication() {
      this.personIndicationName = "";
      this.personService
        .findPersonByCodeIndication(this.person.registrationWithMemberCode)
        .then((data) => {
          this.personIndicationName = "Doador: " + data;
        });
    },
    closeModal() {
      this.$emit("close", true);
      this.$store.commit("REMOVE_MISSING_INFORMATION");
      this.$store.commit("REMOVE_SOCIAL_MEDIA_LOGIN");
    },
    changeTypeCampaign() {
      if (!this.campaign) {
        this.showOptionLogin = true;
        return;
      }

      let typeCampaign = this.typeCampaign();
      if (typeCampaign == TypeCampaign.Mix) {
        this.showOptionLogin = true;
        this.haveBtnBack = true;
      } else if (typeCampaign == TypeCampaign.OnlyPublic) {
        this.showOptionLogin = false;
        this.loginPublic = true;
      } else if (typeCampaign == TypeCampaign.OnlyEmployee) {
        this.showOptionLogin = false;
        this.loginPublic = false;
      } else if (typeCampaign == TypeCampaign.ExternalPayRoll) {
        this.showOptionLogin = false;
        this.loginPublic = true;
      }
    },
    typeCampaign() {

      if (!this.campaign || !this.campaign.id) return TypeCampaign.OnlyPublic;

      let hasPayRollForPublic =
        this.campaign.campaignPaymentMethodList.length > 0 &&
        this.campaign.campaignPaymentMethodList.find(
          (x) => x.paymentMethodId == PaymentMethod.PayrollDeduction
        ) != null;

      if (hasPayRollForPublic) return TypeCampaign.ExternalPayRoll;

      if (this.campaign.isPublic && !this.campaign.hasEmployees)
        return TypeCampaign.OnlyPublic;

      if (this.campaign != null && this.campaign.isPublic && this.campaign.hasEmployees)
        return TypeCampaign.Mix;

      if (this.campaign != null && !this.campaign.isPublic && this.campaign.hasEmployees)
        return TypeCampaign.OnlyEmployee;

      return 0;
    },
    changeTab(tab) {
      this.tab = tab;
    },
    login() {
      if (this.campaign != null && this.campaign.withRecaptcha && this.recaptchaToken == null) {
        CommonHelper.swal("Recaptcha inválido");
        return;
      }
      if (this.username != "" && this.password != "") {
        let possibleCpfValue = this.username
          .replace(/\./g, "")
          .replace(/\-/g, "")
          .replace(" ", "");
        let currentUsername = this.username;
        if (CommonHelper.validateCPF(possibleCpfValue))
          currentUsername = possibleCpfValue;
        this.loginService
          .login(currentUsername, this.password, false, this.recaptchaToken)
          .then(this.onLogin.bind(this))
          .catch(this.onLoginFailed.bind(this));
      } else CommonHelper.swal("Você deve preencher seu usuário e senha.");
    },
    onLogin(loginResult) {
      if (loginResult.success == LoginResultType.Success) {        
        this.$store.commit("REMOVE_ROLES");
        this.person = new Person();
        this.userService.listRolesOfLoggedUser().then(
          function (data) {
            this.$store.commit("SET_ROLES", data);
            this.setLoggedTrue();
            this.$emit("loginSuccess");

            if (this.$router.currentRoute.name == Routes.web.Login) {
              this.$router.push({ name: Routes.app.DashboardPF });
            } else if (
              ![
                Routes.web.CartLogged,
                Routes.app.CampaignLandingPage,
                Routes.web.ProjectLP,
                Routes.app.VolunteeringLP
              ].includes(this.$router.currentRoute.name)
            ) {
              this.$router.go();
            }
          }.bind(this)
        );
      } else if (loginResult.success == LoginResultType.MissingInfo) {
        if (loginResult.loginMessageWarning) {
          CommonHelper.swal(loginResult.loginMessageWarning, "", "warning");
        }
        this.missingPersonInfo = loginResult.missingPersonInfo;
        this.setStep(5);
      } else {
        this.setLogged(false);
        if (loginResult.loginMessageWarning) {
          CommonHelper.swal(loginResult.loginMessageWarning, "", "warning");
        }

        if (loginResult.tempHash) {
          this.$router.push({
            name: Routes.web.LoginPasswordRegistration,
            params: { tempHash: tempHash },
          });
        }
      }
    },
    setLoggedTrue() {
      this.setLogged(true);
      if (this.campaign.modalConfirmationOnDonationActive) {
        this.$nextTick(() => {
          this.$store.commit("SHOW_PERSON_INFO");
        });
      }
    },
    openUpdatePersonInfoModal() {
      this.openedUpdatePersonInfoModal = true;
      if (this.missingInformation && this.missingInformation.registerStep) {
        this.missingPersonInfo = this.missingInformation.missingPersonInfo;
        this.setStep(this.missingInformation.registerStep);
        this.$store.commit("SHOW_LOGIN");
      }
    },
    openCPFRegistrationModal() {
      this.openedCPFRegistrationModal = true;
      if (this.socialMediaLogin && this.socialMediaLogin.registerStep) {
        if (this.campaign.cpfIsRequired) {
          this.setStep(this.socialMediaLogin.registerStep);
          this.$store.commit("SHOW_LOGIN");
        } else {
          this.createFromSocialMedia();
        }
      }
    },
    createFromSocialMedia() {
      var { socialMediaUser, socialMedia } = this.socialMediaLogin;
      if (this.person) {
        this.person.user.optin = true;
        if (socialMedia == LoginSocialMedia.Google) {
          this.person.user.googleId = socialMediaUser.id;
        } else if (socialMedia == LoginSocialMedia.Facebook) {
          this.person.user.facebookId = socialMediaUser.id;
        }
        this.person.email = socialMediaUser.email;
        this.person.name = socialMediaUser.name;
        this.person.user.username = socialMediaUser.email;
        this.person.user.usernames = [];
        this.personService
          .create(this.person)
          .then(this.onCreateFromSocialMedia);
      } else {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Usuário não encontrado.");
      }
    },
    onCreateFromSocialMedia(loginResult) {
      this.$store.commit("REMOVE_SOCIAL_MEDIA_LOGIN");

      if (loginResult.success == LoginResultType.Success) {
        this.$store.commit("REMOVE_ROLES");
        this.userService.listRolesOfLoggedUser().then(
          function (data) {
            this.$store.commit("SET_ROLES", data);
            this.setLoggedTrue();

            if (this.$router.currentRoute.name == Routes.web.Login) {
              this.$router.push({ name: Routes.app.DashboardPF });
            } else if (
              ![
                Routes.web.CartLogged,
                Routes.app.CampaignLandingPage,
                Routes.web.ProjectLP,
              ].includes(this.$router.currentRoute.name)
            ) {
              this.$router.go();
            }
          }.bind(this)
        );
      } else {
        this.setLogged(false);
        if (loginResult.loginMessageWarning) {
          CommonHelper.swal("", loginResult.loginMessageWarning, "warning");
        }
      }
    },
    onLoginFailed: function (baseResponse) {
      this.setLogged(false);
      if (this.campaign.withRecaptcha && window.grecaptcha) window.grecaptcha.reset();
      CommonHelper.swal(baseResponse.errorMessage);
    },
    goToForgotPassword() {
      this.$router.push({ name: Routes.web.LoginForgotPassword });
    },
    changeLoginPublic(isPublic, isCorporativeEmail) {
      this.isCorporativeEmail = isCorporativeEmail;
      this.loginPublic = isPublic;
      this.showOptionLogin = false;
    },
    blurCpf() {
      if (this.person && this.person.cpf) {
        if(CommonHelper.validateCNPJ(this.person.cpf))
          this.showSocialReasonInput = true;
        if (this.isCorporativeEmail) return;
        if (CommonHelper.validateCPF(this.person.cpf))
          this.employeeService.findByCpfAndCampaign(this.person.cpf).then(
            function (employee) {
              if (employee == null) {
                CommonHelper.swal("Funcionário não encontrado");
                return;
              }
              this.person.email = "";
              this.person.name = employee.name;
              this.person.cpf = employee.cpf;
            }.bind(this)
          );
      }
    },
    save() {
      //this.$store.commit("HIDE_LOGIN");
      if (this.campaign != null && this.campaign.withRecaptcha && this.recaptchaTokenCreateAcc == null) {
        CommonHelper.swal("Recaptcha inválido");
        return;
      } else {
        this.person.tokenRecaptcha = this.recaptchaTokenCreateAcc;
      }
      if (this.loginPublic && ((!this.findPersonInApi || !this.campaign.campaignApiList.length > 0) && !this.person.cnpj)) this.savePublic();
      else this.saveEmployee();
    },
    saveEmployee() {
      if (!CommonHelper.validateEmail(this.person.email))
        CommonHelper.swal("Você deve colocar um email válido.");
      else if (CommonHelper.validateCPF(this.person.cpf)) {
        this.person.isEmployee = true;
        this.person.hasCorporativeEmail = this.isCorporativeEmail;
        this.personService
          .validatePossibilityToAddPerson(this.person)
          .then(this.createAccountCallback);
      } else {
        CommonHelper.swal("Informe um CPF válido.");
      }
    },
    savePublic() {
      if (this.person.name.trim().split(" ").length < 2) {
        CommonHelper.swal("Você deve preencher seu nome completo.");
        return;
      } else if (!CommonHelper.validateEmail(this.person.email)) {
        CommonHelper.swal("Você deve colocar um email válido.");
        return;
      } else if (this.campaign.cpfIsRequired) {
        if(this.campaign && this.campaign.permitCnpjDonation  && CommonHelper.validateCNPJ(this.person.cpf)){
          this.person.cnpj = this.person.cpf
          if(!this.person.socialReason){
            CommonHelper.swal("Você deve preencher a Razão Social.");
            return
          }
        }else if (!CommonHelper.validateCPF(this.person.cpf)) {
          CommonHelper.swal(`Informe um ${this.campaign.permitCnpjDonation ? 'CPF/CNPJ' : 'CPF'} válido.`);
          return;
        }
      }
      this.person.isEmployee = false;
      this.person.isEmployeeWithCorporativeEmail = this.isCorporativeEmail;
      this.personService
        .validatePossibilityToAddPerson(this.person)
        .then(this.createAccountCallback);
    },
    createAccountCallback: function (data) {
      if (data) {
        this.person.user.username = this.person.email;
        if (!data.hash) {
          this.setStep(2);
        } else {
          this.personService
            .updateEmailByTempHash(data.hash, this.person)
            .then(() => {
              this.$store.commit("HIDE_LOGIN");
              this.$router.push({
                name: Routes.web.LoginPasswordRegistration,
                query: { tempHash: data.hash },
              });
            });
        }
      }
    },
    facebookLogin() {
      FacebookService.login();
    },
    googleLogin() {
      GoogleService.login();
    },
    setStep(step) {
      //if(this.inline){
      this.setRegisterStep(step);
      //}
    },
    register() {
      this.setStep(3);
    },
    checkVerificationCode() {
      if (this.verificationCode) {
        this.loginService
          .loginByVerificationCode(this.verificationCode)
          .then(this.onLogin.bind(this))
          .catch(this.onLoginFailed.bind(this));
      } else {
        CommonHelper.swal(
          "Por favor, digite o código de verificação enviado em seu e-mail"
        );
      }
    },
    enterVerificationCode() {
      this.setStep(3);
    },
    callbackCheck(check) {
      if (check) {
        this.setRegisterStep(1);
        this.setRegisterUser(new Person());
        this.person = new Person();
        CommonHelper.swal(
          "Usuário criado com sucesso, faça o login para continuar!",
          null,
          "success"
        );
      } else {
        CommonHelper.swal(
          "Código inválido, verifique se digitou corretamente ou clique em reenviar código."
        );
      }
    },
    resendVerificationCode() {
      this.personService.resendVerificationCode(
        this.callbackResend,
        this.registerUser
      );
    },
    callbackResend(data) {
      CommonHelper.swal(
        "Código de verificação de conta enviado, verique sua caixa de e-mail",
        null,
        "success"
      );
    },
    gaLightBox() {
      return ga("send", "pageview", "home/lightbox/acesso-conta/valida-email");
    },
  },
};
</script>
