import CommonHelper from "@/scripts/helpers/common.helper.js";

const defaultScope = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
];

const GoogleService = {

    init(clientId, clientSecret, resourceToRedirect) {
        let protocol = window.location.protocol;
        let host = window.location.host;
        let subdomain = CommonHelper.getSubdomain() || '';

        if(subdomain){
            host = host.replace(subdomain + '.', '');
        }

        let redirectUri = protocol + '//' + host + '/' + resourceToRedirect;

        this.googleConfig = {
            clientId: clientId,
            clientSecret: clientSecret,
            redirectUri: redirectUri,
            state: subdomain
        };
    },

    urlGoogle() {
        return `https://accounts.google.com/o/oauth2/v2/auth?` +
            `scope=${defaultScope[0]} ${defaultScope[1]}&` +
            `include_granted_scopes=true&` +
            `state=${this.googleConfig.state}&` +
            `redirect_uri=${this.googleConfig.redirectUri}&` +
            `response_type=token&` +
            `client_id=${this.googleConfig.clientId}`;
    },

    login() {
        window.location.href = this.urlGoogle();
    }

}

export default GoogleService;