<template>
  <div class="text-xs-left input-comp">
    <label v-if="textLabel" class="d-flex">{{textLabel}}</label>
    <div class="inside-input um">
      <button @click="toggleInput">
        <Icon :name="changeEye" size="18px" />
      </button>
      <input
        :type="inputType"
        :placeholder="placeHolder"
        :value="value"
        @keyup="$emit('input', $event.target.value);"
      />
      <span
        :class="{ valid_password_length: valid_password_length , show_password_length: typed}"
        class="password_length"
      >{{password_length}}</span>
      <!-- {{valid_password_length}} -->
      <span :class="level"></span>
    </div>
    <label v-if="helpLabel" class="d-flex justify-end label-help">{{helpLabel}}</label>
    <div class="lnu_container" v-if="force">
      <p :class="{ lovercase_valid: contains_lowercase }">&nbsp;</p>
      <p :class="{ number_valid: contains_number }">&nbsp;</p>
      <p :class="{ uppercase_valid: contains_uppercase }">&nbsp;</p>
    </div>
  </div>
</template>

<script type="plain/text">
export default {
  data() {
    return {
      inputType: this.type,
      password: null,
      password_length: 0,
      typed: false,
      contains_lowercase: false,
      contains_number: false,
      contains_uppercase: false,
      valid_password_length: false,
      valid_password: false,
      focusOnCreate: false,
      level: "jhb",
      changeEye: "fal fa-eye"
    };
  },
  props: {
    type: "",
    helpLabel: "",
    textLabel: "",
    placeHolder: "",
    insideInput: "",
    value: "",
    force: ""
  },
  watch: {
    value() {
      this.p_len();
    }
  },
  methods: {
    toggleInput() {
      return this.inputType == "password"
        ? ((this.inputType = "text"), (this.changeEye = "fal fa-eye-slash"))
        : ((this.inputType = "password"), (this.changeEye = "fal fa-eye"));
    },

    p_len() {
      this.valid_password_length = 0;
      this.password = this.value;
      this.password_length = this.password.length;

      if (this.password_length >= 0 && this.password.length <= 7)
        this.valid_password_length += 10;
      else this.valid_password_length += 25;

      if (this.password.match(/[a-z]+/)) {
        this.valid_password_length += 10;
        this.level = "1.Fraco";
      } else {
        this.valid_password_length -= 10;
        this.level = "1.Fraco";
      }

      if (this.password.match(/[A-Z]+/)) this.valid_password_length += 20;
      else this.valid_password_length -= 10;
      if (this.password.match(/\d+/)) this.valid_password_length += 15;
      else this.valid_password_length -= 10;
      if (this.password.match(/\W/)) this.valid_password_length += 25;

      this.contains_lowercase = /[a-z]/.test(this.password);
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      if (this.valid_password_length < 30) {
        this.level = 1;
      } else if (
        this.valid_password_length >= 30 &&
        this.valid_password_length < 60
      )
        this.level = 2;
      else if (
        this.valid_password_length >= 60 &&
        this.valid_password_length < 85
      ) {
        this.level = 3;
      } else {
        this.level = 4;
      }

      if (
        !(
          this.contains_number &&
          (this.contains_lowercase || this.contains_uppercase)
        )
      ) {
        this.level = 1;
	  }
	  
      this.$emit("levelPassword", this.level);
      //console.log(this.level + " " + this.valid_password_length);
    }
  }
};
</script>
<style>
input[type="password"]::-webkit-input-placeholder {
  color: rgba(71, 87, 98, 0.8);
}

input[type="password"]::input-placeholder {
  color: rgba(71, 87, 98, 0.8);
}
.label-help {
  color: rgba(76, 77, 79, 0.6);
  font-style: italic;
  font-size: 13px;
  margin-top: 3px;
}
.input_container {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
}

.password_length {
  padding: 2px 10px;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fbd490;
  color: rgba(71, 87, 98, 0.8);
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.valid_password_length {
  background: #00ad7c;
  color: rgba(255, 255, 255, 0.9);
}

.show_password_length {
  display: block;
}

.lnu_container {
  display: flex;
  width: 100%;
  margin: 10px auto 30px;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.lnu_container p {
  flex: 1;
  height: 7px;
  border-radius: 0;
  margin-right: 1px;
  margin-top: 10px;
  width: 100px;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: rgba(71, 87, 98, 0.8);
  background: linear-gradient(to right, #00ad7c 50%, #eee 50%);
  background-size: 200% 100%;
  background-position: right;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.lnu_container p:first-child {
  background: linear-gradient(to right, red 50%, #eee 50%);
}
.lovercase_valid,
.number_valid,
.uppercase_valid {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.valid_password_container {
  display: block;
  margin: 10px auto;
  border-radius: 4px;
  position: relative;
  background: #00ad7c;
  width: 20px;
  height: 20px;
  visibility: hidden;
  opacity: 0;
}

.show_valid_password_container {
  visibility: visible;
  opacity: 1;
}

.tick {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 25;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  -webkit-animation: draw 0.5s ease forwards;
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>