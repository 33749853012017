export default class BillingAddress {
    constructor() {
        this.street = '';
        this.number = '';
        this.complement = '';
        this.postalCode = '';
        this.city = '';
        this.state = '';
        this.country = '';
        this.district = '';
    }
}