const GetCurrentOrder = function (currentList) {
	if (currentList.length != 0) {
		var item = currentList.reduce(function (prev, current) {
			return (prev.Order > current.Order) ? prev : current
		})
		if (isNaN(item.Order)) {
			return currentList.length + 1;
		}
		return item.Order + 1;
	}

	return 0;
}

const flat = function (input, depth = 1, stack = []) {
	for (let item of input) {
		if (item instanceof Array && depth > 0) {
			flat(item, depth - 1, stack);
		}
		else {
			stack.push(item);
		}
	}
	return stack;
}

export { GetCurrentOrder, flat };