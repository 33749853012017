<template>
  <div>
    <div class="anime-proj" id="animation-proj"></div>
    <MyProfile v-model="profileDrawer"></MyProfile>
    <nav class="nav-bar">
      <router-link
        :to="{ name: routes.app.CampaignLandingPage }"
        @click="
          $gtag(
            'send',
            'event',
            'header',
            `logo_${window.location.href.split('.', 1)[0].slice(8)}`,
            `${window.location.href}`
          )
        "
      >
        <FileImage
          :file="getLogo()"
          :useBg="true"
          class="logo"
          v-if="getLogo() != null"
        />

        <div class="logo" v-else></div>
      </router-link>

      <ul
        class="nav-main"
        v-show="landingPageOptions == null || landingPageOptions.showMenu"
      >

        <v-btn id="btn-cta-nav" v-if="landingPageOptions != null && landingPageOptions.showCallToActioOnNav" class="btn-campaign btn-cta" @click="scrollMeTo()">
          {{landingPageOptions.titleCallToActioOnNav}}
        </v-btn>

        <li title="Página Inicial">
          <router-link
            :to="{ name: routeHome }"
            @click="$gtag('send', 'event', 'home', 'home', 'home')"
          >
            <Icon :name="'uil uil-home-alt'" :size="24" />
          </router-link>
        </li>
        <li title="Idioma" style="cursor: pointer">
          <div id="google_translate_element"></div>
        </li>
        <li title="Dúvidas Frequentes" @click="$gtag('send', 'event', 'FAQ', 'FAQ', 'FAQ Header')">
          <Faq></Faq>
        </li>
        <li title="Fale Conosco" @click="$gtag('send', 'event', 'Contato', 'Contato', 'Botão de contato Header')">
          <Contact></Contact>
        </li>
        <li
          v-if="showCart && !isSimplifiedCheckout"
          style="position: relative"
          class="notranslate"
        >
          <MyCart @verifyCampaign="checkCampaignStatus" :campaign="campaign"></MyCart>
        </li>
        <!-- <li v-if="showCart && logged">
          <router-link :to="{name: routes.web.CartLogged }">
            <Icon :name="'uil uil-shopping-trolley'" :size="24" />
          </router-link>
                </li>-->
        <li title="Perfil">
          <a
            href
            @click.prevent="
              profileDrawer = !profileDrawer;
              $gtag('send', 'event', 'header', 'home', 'Perfil');
            "
            v-if="logged"
          >
            <div class="avatar-profile sm">
              <img :src="getUrl()" alt />
            </div>
            <!-- <Icon :name="'uil uil-user-square'" :size="24" /> -->
          </a>
        </li>
        <li class="pd-2" title="Entrar">
          <!-- <router-link :to="{ name: routes.web.Login }" v-if="!logged">Entrar</router-link> -->
          <a
            v-if="!logged"
            @click="
              showLogin();
              $gtag('send', 'event', 'header', 'home', 'Entrar');
            "
            >Entrar</a
          >
        </li>
      </ul>
    </nav>
    <!-- MENU ######################### -->
    <!-- <section class="bloco nav bg-theme-white" data-title="Barra de Navegação" :class="{hidden: hiddenNav}">
			<div class="edit">
				<a href="javascript:;" class="fal fa-eye-slash" @click="hiddenNav = !hiddenNav"></a>
			</div>
			<div class="bloco-content">
				<div class="menu-lp-campaign d-none">
					<input type="checkbox" id="menu-campaign" />
					<label for="menu-campaign" class="open-menu-btn"></label> 
					<nav class="nav">
						<ul v-if="landingPageOptions!=null && landingPageOptions.showAnchors">
							<li v-for="anchor in landingPageOptions.anchors" :key="anchor.name" v-show="anchor.show">
								<a @click="anchor.click(anchor.name)">{{anchor.text}}</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
        </section>-->
  </div>
</template>

<script type="plain/text">
import MyProfile from "@/components/MyProfile.vue";
import MyCart from "@/components/MyCart.vue";
import Faq from "@/components/Faq.vue";
import Contact from "@/components/Contact.vue";
import CampaignService from "@/scripts/services/campaign.service";
import LoginService from "@/scripts/services/login.service";
import CartService from "@/scripts/services/cart.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import CommonHelper from "@/scripts/helpers/common.helper";
import FileImage from "@/components/FileImage.vue";
import { mapActions, mapGetters } from "vuex";
import md5 from "crypto-js/md5"

import PersonService from "@/scripts/services/person.service.js";
import Person from "@/scripts/models/person.model.js";

export default {
  components: {
    MyProfile,
    MyCart,
    Faq,
    Contact,
    FileImage,
  },
  props: {
    landingPageOptions: Object,
    campaign: Object
  },
  data() {
    return {
      profileDrawer: false,
      primary: "rgb(22, 174, 181) ",
      secondary: "rgb(210, 231, 147) ",
      campaignService: new CampaignService(),
      allowToShowBasedCampaign: true,
      //logged: true,
      routes: Routes,
      loginService: new LoginService(),
      cartService: new CartService(),
      routeHome: null,
      // hiddenNav: false,
      notificationDrawer: true,
      personService: new PersonService(),
      person: new Person(),
    };
  },
  created() {
    this.loginService.verifyToken(this.verifyTokenCallBack);
  },
  mounted() {
    try {
      new window.google.translate.TranslateElement(
        { pageLanguage: "pt" },
        "google_translate_element"
      );
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapGetters(["logged", "isSimplifiedCheckout"]),
    showCart() {
      return (
        (this.$router.currentRoute.name == Routes.app.CampaignLandingPage ||
          this.$router.currentRoute.name == Routes.web.CartLogged ||
          this.$router.currentRoute.name == Routes.web.ProjectLP ||
          this.$router.currentRoute.name == Routes.web.ProjectAll) &&
        this.allowToShowBasedCampaign
      );
    },
  },
  watch: {
    logged(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.verifyTokenCallBack(true);
      }
    },
  },
  methods: {
    ...mapActions(["setLogged"]),
    showLogin() {
      this.$store.commit("SHOW_LOGIN");
    },
    toggleProfileDrawer: function () {
      this.profileDrawer = !this.profileDrawer;
    },
    verifyTokenCallBack: function (data) {
      this.setLogged(data);
      if (this.logged) {
        if (CommonHelper.getSubdomain()) {
          // this.cartService.doesCartHaveItems().then(
          //   function(hasItems) {
          //     this.showCart = hasItems;
          //   }.bind(this)
          // );
          this.personService.getProfile(this.getProfileCallback);
          this.routeHome = Routes.app.CampaignLandingPage;
        } else {
          this.routeHome = Routes.app.DashboardPF;
        }
      } else {
        this.routeHome = Routes.app.CampaignLandingPage;
      }
    },
    getLogo() {
      if (
        this.landingPageOptions != null &&
        this.landingPageOptions.logo != null
      ) {
        return this.landingPageOptions.logo;
      }
      return null;
    },
    checkCampaignStatus(campaign) {
      if (campaign) {
        this.allowToShowBasedCampaign =
          campaign.campaignPhaseId == CampaignPhases.EmAndamento &&
          campaign.published;
      }
    },
    getUrl() {
        let hex = "3377b4";
        let rgba = getComputedStyle(document.documentElement).getPropertyValue("--theme-primaria").split(",");
        
        if (rgba != '') {
        hex = `${(
            (1 << 24) +
            (parseInt(rgba[0]) << 16) +
            (parseInt(rgba[1]) << 8) +
            parseInt(rgba[2])
        )
            .toString(16)
            .slice(1)}`;
        }

        let email = md5(this.person.email);
        let name;
        
        if(this.person != null && this.person.cnpj){
        name = this.person.socialReason;
        }else{
        name = this.person.name;
        }

        let url = `https://www.gravatar.com/avatar/${email}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F${name}/128/${hex}/ffffff`;
        return url;
    },
    getProfileCallback(data) {
      this.person = data;
    },
    scrollMeTo() {
      var element = document.querySelector('.bloco-checkout-simplificado');
      if(!element)
        element = document.querySelector(`[data-title="Agradecimentos"]`)
      if (element) {
        var topPosition = element.offsetTop;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
        return true;
      }
      return false;
    },
  },
};
</script>
