<template>
  <v-container fluid fill-height>
    <div style="max-width: 500px;margin:0 auto">
      <v-layout row wrap>
        <v-flex pa-4>
          <!-- v-if="missingInfo.field == PersonFileds.CPF" -->
          <template>
            <div class="head-login mb-4">
              <p>Por favor entre com seu CPF</p>
            </div>
            <div class="box-xs-form">
              <InputText type="text" v-model="fieldValue" place-holder="CPF" mask="###.###.###-##" />
            </div>
          </template>

          <div class="text-xs-center">
            <v-btn
              flat
              round
              color="white"
              class="btn-primary px-5 mt-4"
              large
              @click="updateInfo"
              :disabled="fieldValue.length < 11"
            >ATUALIZAR</v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import LoginResultType from "@/scripts/models/enums/loginResultType.enum";
import CommonHelper from "@/scripts/helpers/common.helper";
import { mapGetters, mapState, mapActions } from "vuex";
import PersonFileds from "@/scripts/models/enums/personFileds.enum.js";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";

export default {
  props: ["landingPageConfig", "missingInfo"],
  components: {
    Container,
    FileImage
  },
  data() {
    return {
      fieldValue: "",
      userService: new UserService(),
      personService: new PersonService(),
      PersonFileds: PersonFileds
    };
  },
  computed: {
    ...mapGetters(["socialMediaLogin"])
  },
  methods: {
    ...mapActions(["setLogged"]),
    updateInfo() {
      if (this.validateInfo()) {
        this.missingInfo.value = this.fieldValue;
        this.personService
          .updatePersonInfo(this.missingInfo)
          .then(this.onLogin);
      }
    },
    validateInfo() {
      if (this.missingInfo.field == PersonFileds.CPF) {
        if (CommonHelper.validateCPF(this.fieldValue)) return true;
        CommonHelper.swal("Por favor entre com um CPF válido");
      }
      return false;
    },
    onLogin(loginResult) {
      this.$store.commit("REMOVE_MISSING_INFORMATION");
      this.$store.commit("HIDE_LOGIN");

      if (loginResult.success == LoginResultType.Success) {
        this.$store.commit("REMOVE_ROLES");
        this.userService.listRolesOfLoggedUser().then(
          function(data) {
            this.$store.commit("SET_ROLES", data);
            this.setLogged(true);

            if (this.$router.currentRoute.name == Routes.web.Login) {
              this.$router.push({ name: Routes.app.DashboardPF });
            } else if (
              ![
                Routes.web.CartLogged,
                Routes.app.CampaignLandingPage,
                Routes.web.ProjectLP
              ].includes(this.$router.currentRoute.name)
            ) {
              this.$router.go();
            }
          }.bind(this)
        );
      } else {
        this.setLogged(false);
        if (loginResult.loginMessageWarning) {
          CommonHelper.swal("", loginResult.loginMessageWarning, "warning");
        }
      }
    }
  }
};
</script>