<template>
  <v-layout row wrap w-100>
    <v-flex v-for="columnIndex in columns" :key="columnIndex">
      <v-checkbox
        v-for="item in getElements(columnIndex)"
        color="#02ACB4"
        class="ma-0"
        ref="input"
        v-model="selectedList"
        :label="item[labelAttribute]"
        :key="item[valueAttribute]"
        :value="item[valueAttribute]"
        @change="$emit('input', selectedList), $emit('focus', helpBoxText)"
        :disabled="listSizeLimit && selectedList.length == listSizeLimit && !selectedList.includes(item[valueAttribute])"
      ></v-checkbox>
    </v-flex>
  </v-layout>
</template>

<script>
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  props: {
    list: "",
    labelAttribute: "",
    valueAttribute: "",
    value: "",
    helpBoxText: "",
    columns: Number,
    numberOfElementsPerColumn: Array,
    listSizeLimit: Number
  },
  data() {
    return {
      selectedList: this.value,
      qtyPerColumn: this.numberOfElementsPerColumn
    };
  },
  created() {
    //this.calculateQtyOfElementsPerColumn();
    this.$on("focus", this.focus);
    if (!this.validationMethod) this.validationMethod = this.validate;
    if (!this.onValidationSuccessMethod)
      this.onValidationSuccessMethod = this.validationSuccess;
    if (!this.onValidationFailMethod)
      this.onValidationFailMethod = this.validationFail;
  },
  methods: {
    calculateQtyOfElementsPerColumn() {
      if (
        this.list != null &&
        (this.qtyPerColumn == null || this.qtyPerColumn.length == 0)
      ) {
        this.qtyPerColumn = [];
        let total = 0;
        let qty = Math.ceil(this.list.length / this.columns);
        while (total + qty <= this.list.length) {
          this.qtyPerColumn.push(qty);
          total += qty;
        }
        if (total <= this.list.length) {
          this.qtyPerColumn.push(total - this.list.length);
        }
      }
    },
    getElements(columnIndex) {
      if (!this.list) return [];
      /*if(|| !this.qtyPerColumn) return [];
      let firstElementIndexOfCurrentColumn = 0;
      let lastElementIndexOfCurrentColumn = 0;
      if (this.qtyPerColumn.length >= columnIndex) {
        for (let i = 0; i < columnIndex; i++) {
          firstElementIndexOfCurrentColumn = lastElementIndexOfCurrentColumn;
          lastElementIndexOfCurrentColumn += this.qtyPerColumn[i];
        }
      }*/
      let numberOfElementsPerColumn = Math.ceil(
        this.list.length / this.columns
      );
      let firstElementIndexOfCurrentColumn =
        (columnIndex - 1) * numberOfElementsPerColumn;
      let lastElementIndexOfCurrentColumn = Math.min(
        columnIndex * numberOfElementsPerColumn,
        this.list.length
      );
      let elements = this.list.slice(
        firstElementIndexOfCurrentColumn,
        lastElementIndexOfCurrentColumn
      );
      return elements;
    },
    focus() {
      if (this.$refs && this.$refs.input && this.$refs.input.length > 0) {
        this.$refs.input[0].$el.focus();
      }
    },
    validate() {
      if (!this.selectedList || !this.selectedList.length) {
        return false;
      }
      return true;
    },
    validationSuccess() {
      this.$emit("validationStatusChanged", false);
    },
    validationFail() {
      this.$emit("validationStatusChanged", true);
      this.focus();
    }
  },
  watch: {
    value(val) {
      this.isValid();
      this.selectedList = val;
    }
  }
};
</script>
