<template>
  <div>
    <div :id="`g-recaptcha${$vnode.key}`" class="g-recaptcha" :data-sitekey="sitekey"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sitekey: "",
      widget: null,
    };
  },
  created() {
    this.sitekey = process.env.VUE_APP_GOOGLE_KEY_RECAPTCHA;
  },
  mounted() {
    window[`ReCaptchaLoaded${this.$vnode.key}`] = () => {
      if (window.grecaptcha) {
        window.grecaptcha.render(`g-recaptcha${this.$vnode.key}`, {
          sitekey: this.sitekey,
          callback: (token) => {
            this.$emit("recaptchaSuccess", token);
          },
        });
      }
    };
    var script = document.createElement("script");
    script.src =
      `https://www.google.com/recaptcha/api.js?onload=ReCaptchaLoaded${this.$vnode.key}&render=explicit`;
    document.head.appendChild(script);
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widget);
    },
    reset() {
      window.grecaptcha.reset(this.widget);
    },
    render() {
      if (window.grecaptcha) {
        this.widget = window.grecaptcha.render("g-recaptcha", {
          sitekey: this.sitekey,
          callback: (token) => {
            this.$emit("recaptchaSuccess", token);
          },
        });
      }
    },
  },
};
</script>