export default class CampaignLandingPageOptions {
  constructor() {
    this.simulationDrawer = false;
    this.memberGetMemberDrawer = false;
    this.showMenu = true;
    this.anchors = [];
    this.showAnchors = false;
    this.logo = null;
    this.showCallToActioOnNav = false;
    this.titleCallToActioOnNav = "";
  }
}
