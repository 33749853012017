import BaseCrudService from "./baseCrud.service";

export default class EmployeeService extends BaseCrudService {

	constructor() {
		super('Employee');
	}

	findByCpfAndCampaign(cpf){
		return this.getRequest('FindByCpfAndCampaign', {cpf: cpf});
	}

	uploadBaseEmployees(file) {
		return this.postFileRequest('UploadBaseEmployees', file);
	}

	uploadBaseToConciliate(file) {
		return this.postFileRequest('UploadPayrollDonationBaseToConciliateByCampaign', file);
	}

	listImportEmployeeByCampaignId(campaignId, loading) {
		return this.getRequest('ListImportEmployeeByCampaignId', { campaignId: campaignId }, true, loading);
	}

	listImportEmployeeIntermediateByImportStatus(importEmployeeId, listStatus) {
		return this.postRequest('ListImportEmployeeIntermediateByImportStatus', { registerId: importEmployeeId, listStatus: listStatus });
	}

	generateExcel(importEmployeeId, listStatus) {
		return this.postRequestBlob('DownloadExcelEmployee', { registerId: importEmployeeId, listStatus: listStatus });
	}

	generateExcelUploadConciliate(payRollConciliationActionId, listStatus){
		return this.postRequestBlob('DownloadExcelUploadConciliation', { registerId: payRollConciliationActionId, listStatus: listStatus });
	}

	generateExcelBaseEmployee(campaignId) {
		return this.getRequestBlob('DownloadExcelBaseEmployee', { campaignId: campaignId });
	}

	downloadBaseToConciliate(campaignId) {
		return this.getRequestBlob('DownloadBaseToConciliate', { campaignId: campaignId });
	}

	downloadBaseToConciliateByPayRollConciliationActionId(payRollConciliationActionId) {
		return this.getRequestBlob('DownloadBaseToConciliateByPayRollConciliationActionId', { payRollConciliationActionId: payRollConciliationActionId });
	}

	listPayRollConciliationAction(campaignId, loading){
		return this.getRequest('ListPayRollConciliationAction', { campaignId: campaignId },true, loading);
	}

	listPayRollConciliationActionDonationByStatus(payRollConciliationActionId, listStatus){
		return this.postRequest('ListPayRollConciliationActionDonationByStatus', { registerId: payRollConciliationActionId, listStatus: listStatus });
	}

}