<style scoped>
  pre {
     overflow-x: auto;
     white-space: pre-wrap;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word;
  }
</style>
<template>
  <Modal
    modalTitle="Política de Privacidade"
    v-model="dialog"
    :cardTitle="true"
    :withClose="true"
    myMaxWidth="640px"
    @input="$emit('close')"
  >
    <v-container fluid pa-0>
      <v-layout pa-0>
        <v-flex list-scroll sm12 style="height:85vh">
          <div class="content-text-block overflow font-sys">
              <pre>
                <p><strong><span data-contrast="none">POL&Iacute;TICA DE PRIVACIDADE DA ABRACE UMA CAUSA</span></strong></p>
<p><span data-contrast="none">A sua privacidade &eacute; importante para n&oacute;s. &Eacute; pol&iacute;tica do Abrace uma Causa respeitar a sua privacidade em rela&ccedil;&atilde;o a qualquer informa&ccedil;&atilde;o sua que possamos coletar no site </span><a href="https://abraceumacausa.com.br/"><span data-contrast="none">Abrace uma Causa</span></a><span data-contrast="none">, e outros sites que possu&iacute;mos e operamos.</span></p>
<p><span data-contrast="none">Solicitamos informa&ccedil;&otilde;es pessoais apenas quando realmente precisamos delas para lhe fornecer um servi&ccedil;o. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Tamb&eacute;m informamos por que estamos coletando e como ser&aacute; usado.</span></p>
<p><span data-contrast="none">Apenas retemos as informa&ccedil;&otilde;es coletadas pelo tempo necess&aacute;rio para fornecer o servi&ccedil;o solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceit&aacute;veis para evitar perdas e roubos, bem como acesso, divulga&ccedil;&atilde;o, c&oacute;pia, uso ou modifica&ccedil;&atilde;o n&atilde;o autorizados.</span></p>
<p><span data-contrast="none">As informa&ccedil;&otilde;es dos usu&aacute;rios s&atilde;o armazenadas pela Abrace uma Causa, operadora dos dados, seguindo todos os protocolos de criptografia em tr&acirc;nsito e em banco de dados, e s&atilde;o controlados pela gestora da campanha. Que poder&aacute; enviar informa&ccedil;&otilde;es referentes a campanhas, pesquisa de satisfa&ccedil;&atilde;o e divulgar novas inciativas correlacionadas com o tema da mesma.</span></p>
<p><span data-contrast="none">A Abrace uma Causa, a gestora da campanha e/ou a institui&ccedil;&atilde;o promotora da a&ccedil;&atilde;o volunt&aacute;ria n&atilde;o compartilham informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
<p><span data-contrast="none">O nosso site pode ter links para sites externos que n&atilde;o s&atilde;o operados por n&oacute;s. Esteja ciente de que n&atilde;o temos controle sobre o conte&uacute;do e pr&aacute;ticas desses sites e n&atilde;o podemos aceitar responsabilidade por suas respectivas pol&iacute;ticas de privacidade.</span></p>
<p><span data-contrast="none">Voc&ecirc; &eacute; livre para recusar a nossa solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais, entendendo que talvez n&atilde;o possamos fornecer alguns dos servi&ccedil;os desejados.</span></p>
<p><span data-contrast="none">O uso continuado de nosso site ser&aacute; considerado como aceita&ccedil;&atilde;o de nossas pr&aacute;ticas em torno de privacidade e informa&ccedil;&otilde;es pessoais. Se voc&ecirc; tiver alguma d&uacute;vida sobre como lidamos com dados do usu&aacute;rio e informa&ccedil;&otilde;es pessoais, entre em contato conosco.</span></p>
<p><span data-contrast="none">Esta Pol&iacute;tica de Privacidade foi elaborada para atendimento dos dispositivos previstos na Lei Federal n&ordm; 13.709/2018 - Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais (&ldquo;LGPD&rdquo;) o Marco Civil da Internet (Lei 12.965 /14) e o Regulamento da UE n. 2016/6790, sem preju&iacute;zo de observ&acirc;ncia das demais legisla&ccedil;&otilde;es aplic&aacute;veis &agrave; privacidade e prote&ccedil;&atilde;o de dados pessoais tratados durante sua experi&ecirc;ncia conosco.</span></p>
<ol>
<li><strong><span data-contrast="none">Objetivo.</span></strong><span data-contrast="none">A Pol&iacute;tica de Privacidade da Abrace uma Causa (&ldquo;Pol&iacute;tica&rdquo;) foi criada para demonstrar o nosso compromisso com a privacidade e a seguran&ccedil;a de suas informa&ccedil;&otilde;es que podemos vir a coletar em nosso site Abrace uma Causa, e outros sites que possu&iacute;mos e operamos.</span></li>
</ol>
<ol start="26">
<li><strong><span data-contrast="none">Quem Somos.</span></strong><span data-contrast="none"> ABRACE UMA CAUSA GEST&Atilde;O E SERVI&Ccedil;OS LTDA., sociedade empres&aacute;ria limitada, inscrita no CNPJ sob o n&ordm; 26.246.070/0001-18, com sede na Cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, na Av. Brigadeiro Faria Lima, 1755, Box 46, Jardim Paulistano, CEP 01452-001 (&ldquo;AUC&rdquo;; &ldquo;N&oacute;s&ldquo;). De acordo com a LGPD, a partir do momento que voc&ecirc; nos fornece alguns de seus dados pessoais, nos tornamos respons&aacute;veis pela utiliza&ccedil;&atilde;o, armazenamento e prote&ccedil;&atilde;o dos mesmos, na qualidade de controlador de suas informa&ccedil;&otilde;es conforme a defini&ccedil;&atilde;o legal.&nbsp;</span></li>
</ol>
<p><strong><span data-contrast="none">III.</span></strong><strong><span data-contrast="none">Dados pessoais coletados.</span></strong><span data-contrast="none"> Solicitamos informa&ccedil;&otilde;es pessoais apenas quando realmente precisamos delas para lhe fornecer um servi&ccedil;o. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Tamb&eacute;m informamos por que estamos coletando e como ser&aacute; usado. Durante seu relacionamento conosco, podemos coletar tipos diferentes de dados pessoais de forma autom&aacute;tica, para fins de confer&ecirc;ncia, monitoramento e controle ou ent&atilde;o fornecidas diretamente por voc&ecirc;, tais como no momento de cria&ccedil;&atilde;o de conta na plataforma. Nesse sentido, listamos abaixo os dados pessoais que podemos coletar de acordo com cada situa&ccedil;&atilde;o:</span></p>
<p><span data-contrast="none">- Informa&ccedil;&otilde;es que voc&ecirc; digita em nossos formul&aacute;rios, tais como nome completo, endere&ccedil;o de e-mail, CPF, endere&ccedil;o e telefone.</span></p>
<p><span data-contrast="none">- Informa&ccedil;&otilde;es acerca de suas atividades na plataforma, tais como recibo, informe, consulta &agrave;s doa&ccedil;&otilde;es feitas, emiss&atilde;o de segunda via de boleto.</span></p>
<p><span data-contrast="none">- Informa&ccedil;&otilde;es obtidas automaticamente atrav&eacute;s de sua navega&ccedil;&atilde;o pelo site, tais como seu endere&ccedil;o de IP, eventuais endere&ccedil;os de sites visitados e os caminhos percorridos atrav&eacute;s de nossa p&aacute;gina na web.&nbsp;</span></p>
<p><span data-contrast="none">A AUC n&atilde;o armazena dados de cart&atilde;o de cr&eacute;dito/d&eacute;bito, os quais s&atilde;o processados via gateway diretamente, que possuem certifica&ccedil;&atilde;o PCI-DSS (Payment Card Industry &ndash; Data Security Standard).</span></p>
<ol>
<li><strong><span data-contrast="none">Finalidades do tratamento de dados pessoais.</span></strong><span data-contrast="none"> Visando a prote&ccedil;&atilde;o de nossa empresa e dos nossos clientes, a AUC n&atilde;o vende, aluga ou empresta os dados pessoais constantes de nosso Banco de Dados para terceiros.&nbsp;</span></li>
</ol>
<p><span data-contrast="none">Nos comprometemos em utilizar seus dados pessoais exclusivamente para as seguintes finalidades:</span></p>
<p><span data-contrast="none">- Criar de conta em Nossa plataforma;</span></p>
<p><span data-contrast="none">- Identificar o doador;</span></p>
<p><span data-contrast="none">- Permitir a realiza&ccedil;&atilde;o de doa&ccedil;&otilde;es atrav&eacute;s da plataforma;</span></p>
<p><span data-contrast="none">- Prestar de contas acerca das doa&ccedil;&otilde;es;</span></p>
<p><span data-contrast="none">- Garantir envio correto de informa&ccedil;&otilde;es aos &oacute;rg&atilde;os competentes no caso de doa&ccedil;&otilde;es via incentivos fiscais;</span></p>
<p><span data-contrast="none">- Emitir recibos de doa&ccedil;&otilde;es.</span></p>
<p><span data-contrast="none">- Facilitar o envio de agradecimentos, contrapartidas e documentos quando pertinente.</span></p>
<ol>
<li><strong><span data-contrast="none">Compartilhamento de dados pessoais.</span></strong><span data-contrast="none"> A AUC utiliza-se, para a operacionaliza&ccedil;&atilde;o de seus servi&ccedil;os, da parceria com diversas empresas localizadas Brasil e no exterior. Deste modo, a AUC poder&aacute; compartilhar as suas informa&ccedil;&otilde;es pessoais, nas hip&oacute;teses abaixo expostas:</span></li>
</ol>
<p><span data-contrast="none">- Com as empresas gestoras das campanhas de doa&ccedil;&atilde;o com a finalidade de garantir a concretiza&ccedil;&atilde;o das doa&ccedil;&otilde;es;</span></p>
<p><span data-contrast="none">- Com reparti&ccedil;&otilde;es p&uacute;blicas e/ou Fundo e/ou Entidade Apoiada com a finalidade de t&atilde;o somente informar a titularidade da doa&ccedil;&atilde;o realizada;</span></p>
<p><span data-contrast="none">- Com autoridades, entidades governamentais ou outros terceiros institucionais, para a prote&ccedil;&atilde;o dos interesses da AUC em qualquer tipo de conflito, incluindo a&ccedil;&otilde;es judiciais e processos administrativos;</span></p>
<p><span data-contrast="none">- No caso de transa&ccedil;&otilde;es e altera&ccedil;&otilde;es societ&aacute;rias envolvendo a AUC, em que a transfer&ecirc;ncia das informa&ccedil;&otilde;es ser&aacute; necess&aacute;ria para a continuidade dos servi&ccedil;os;</span></p>
<p><span data-contrast="none">- Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham compet&ecirc;ncia legal para a sua requisi&ccedil;&atilde;o.</span></p>
<p><span data-contrast="none">- Adicionalmente, &eacute; poss&iacute;vel que algumas das transfer&ecirc;ncias acima ocorram fora do territ&oacute;rio brasileiro. Ocasi&atilde;o em que a AUC se compromete a faz&ecirc;-lo somente para pa&iacute;ses que proporcionem um grau de prote&ccedil;&atilde;o aos seus dados pessoais ao previsto na legisla&ccedil;&atilde;o aplic&aacute;vel, ou mediante a ado&ccedil;&atilde;o de garantias e salvaguardas, tais como cl&aacute;usulas contratuais espec&iacute;ficas, bem como mediante a pr&eacute;via coleta do seu consentimento espec&iacute;fico quando aplic&aacute;vel.</span></p>
<p><span data-contrast="none">Fora as hip&oacute;teses acima previstas, a AUC e a gestora da campanha n&atilde;o compartilham informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal publicamente ou com terceiros, exceto quando exigido por lei.</span></p>
<ol>
<li><strong><span data-contrast="none">Dados pessoais seguros.</span></strong><span data-contrast="none"> O armazenamento dos seus dados pessoais &eacute; feito na nuvem com utiliza&ccedil;&atilde;o de criptografia desde a transmiss&atilde;o dos dados do aparelho eletr&ocirc;nico do usu&aacute;rio at&eacute; a grava&ccedil;&atilde;o e manuten&ccedil;&atilde;o no servidor. Qualquer informa&ccedil;&atilde;o fornecida pelos usu&aacute;rios ser&aacute; coletada e guardada de acordo com os mais r&iacute;gidos padr&otilde;es de seguran&ccedil;a. Para tanto, a AUC adota diversas precau&ccedil;&otilde;es, em observ&acirc;ncia &agrave;s diretrizes sobre padr&otilde;es de seguran&ccedil;a estabelecidas nas legisla&ccedil;&otilde;es aplic&aacute;veis, em especial a LGPD. O acesso &agrave;s informa&ccedil;&otilde;es coletadas &eacute; restrito aos colaboradores e &agrave;s pessoas autorizadas. Aqueles que se utilizarem indevidamente dessas informa&ccedil;&otilde;es, em viola&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade, estar&atilde;o sujeitos a san&ccedil;&otilde;es disciplinares e legais cab&iacute;veis. De qualquer forma, na remota hip&oacute;tese de incid&ecirc;ncia de epis&oacute;dios desta natureza, a AUC garante o pleno esfor&ccedil;o para remediar as consequ&ecirc;ncias do evento.</span></li>
</ol>
<p><strong><span data-contrast="none">VII.</span></strong><strong><span data-contrast="none">Reten&ccedil;&atilde;o e exclus&atilde;o dos dados pessoais.</span></strong><span data-contrast="none"> As informa&ccedil;&otilde;es dos usu&aacute;rios s&atilde;o armazenadas pela AUC, operadora dos dados, seguindo todos os protocolos de criptografia em tr&acirc;nsito e em banco de dados, e s&atilde;o controlados pela empresa gestora da campanha, a qual poder&aacute; enviar informa&ccedil;&otilde;es referentes a campanhas, pesquisa de satisfa&ccedil;&atilde;o e divulgar novas inciativas correlacionadas com o tema da mesma.</span></p>
<p><span data-contrast="none">Enquanto seus dados pessoais estiverem sob Nossa gest&atilde;o, garantimos sua prote&ccedil;&atilde;o a partir do momento que voc&ecirc; os fornece para n&oacute;s, seja para viabilizar sua opera&ccedil;&atilde;o em nossa comunidade, ou at&eacute; para receber comunicados, convites, informativos, bem como para melhorar sua experi&ecirc;ncia de navega&ccedil;&atilde;o em nosso site. Apenas retemos as informa&ccedil;&otilde;es coletadas pelo tempo necess&aacute;rio para fornecer o servi&ccedil;o solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceit&aacute;veis para evitar perdas e roubos, bem como acesso, divulga&ccedil;&atilde;o, c&oacute;pia, uso ou modifica&ccedil;&atilde;o n&atilde;o autorizados. Caso opte em algum momento pela exclus&atilde;o de seus dados pessoais do nosso banco de armazenamento, poderemos reter algumas informa&ccedil;&otilde;es pessoais por um per&iacute;odo adicional para fins de cumprimento de obriga&ccedil;&otilde;es legais ou regulat&oacute;rias, para exerc&iacute;cio regular de direito da AUC, bem como auditorias. A reten&ccedil;&atilde;o de seus dados pessoais ser&aacute; feita durante o prazo necess&aacute;rio, sempre respeitando os prazos estabelecidos na legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p>
<p><strong><span data-contrast="none">VIII.</span></strong><strong><span data-contrast="none">Direitos como titular de dados pessoais.</span></strong><span data-contrast="none"> A AUC respeita e garante aos usu&aacute;rios, a possibilidade de exercerem seus direitos previstos na LGPD, podendo apresentar solicita&ccedil;&otilde;es relacionados aos seus dados pessoais, tais como:</span></p>
<p><span data-contrast="none">- Confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento;</span></p>
<p><span data-contrast="none">- Acesso aos dados pessoais;</span></p>
<p><span data-contrast="none">- Corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados;</span></p>
<p><span data-contrast="none">- Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos ou tratados em desconformidade com a LGPD;</span></p>
<p><span data-contrast="none">- Portabilidade dos dados a outro fornecedor de servi&ccedil;o ou produto, mediante requisi&ccedil;&atilde;o expressa pelo usu&aacute;rio;</span></p>
<p><span data-contrast="none">- Elimina&ccedil;&atilde;o dos dados tratados com consentimento do usu&aacute;rio;</span></p>
<p><span data-contrast="none">- Obten&ccedil;&atilde;o de informa&ccedil;&otilde;es sobre as entidades com as quais compartilhou os seus dados;</span></p>
<p><span data-contrast="none">- Informa&ccedil;&atilde;o sobre a possibilidade de o usu&aacute;rio n&atilde;o fornecer o consentimento, bem como de ser informado sobre as consequ&ecirc;ncias em caso de negativa;</span></p>
<p><span data-contrast="none">- Revoga&ccedil;&atilde;o do consentimento.</span></p>
<ol>
<li><strong><span data-contrast="none">Exercendo os seus direitos de titular dos dados.</span></strong><span data-contrast="none"> Os seus direitos como titular de dados pessoais poder&atilde;o ser exercidos diretamente por voc&ecirc;, a partir da gest&atilde;o de informa&ccedil;&otilde;es de cadastro, enquanto outra parte depender&aacute; do envio de requisi&ccedil;&atilde;o por meio de solicita&ccedil;&atilde;o para o e- mail: dpo@abraceumacausa.com.br, para posterior avalia&ccedil;&atilde;o e ado&ccedil;&atilde;o de das provid&ecirc;ncias necess&aacute;rias. A AUC empreender&aacute; todos os esfor&ccedil;os razo&aacute;veis para atender as requisi&ccedil;&otilde;es feitas por usu&aacute;rios no menor espa&ccedil;o de tempo poss&iacute;vel.&nbsp;</span></li>
</ol>
<ol>
<li><strong><span data-contrast="none">Altera&ccedil;&otilde;es da Pol&iacute;tica de Privacidade.</span></strong><span data-contrast="none"> A AUC est&aacute; sempre trabalhando para aprimorar a experi&ecirc;ncia e seguran&ccedil;a de nossos usu&aacute;rios. Por isso, nossas pr&aacute;ticas de tratamento de dados pessoais poder&atilde;o vir a sofrer altera&ccedil;&otilde;es futuras de modo a refletir tais aprimoramentos. Sempre que alguma condi&ccedil;&atilde;o relevante desta Pol&iacute;tica de Privacidade for alterada, tais altera&ccedil;&otilde;es ser&atilde;o v&aacute;lidas, eficazes e vinculantes ap&oacute;s a nova vers&atilde;o ser publicada em nosso site. Quando realizarmos altera&ccedil;&otilde;es relevantes na presente Pol&iacute;tica, voc&ecirc; ser&aacute; devidamente notificado, conforme as circunst&acirc;ncias, por exemplo, exibindo um aviso na p&aacute;gina principal de https://abraceumacausa.com.br ou enviando-lhe um e-mail para contatos cadastrados em nossos servi&ccedil;os.</span></li>
</ol>
<ol>
<li><strong><span data-contrast="none">Contato.</span></strong><span data-contrast="none"> Se voc&ecirc; tiver d&uacute;vidas ou preocupa&ccedil;&otilde;es em rela&ccedil;&atilde;o &agrave; sua privacidade ou ao tratamento de seus dados pessoais pela EI, entre em contato conosco atrav&eacute;s do e-mail dpo@abraceumacausa.com.br</span></li>
</ol>
<p><strong><span data-contrast="none">XII.</span></strong><strong><span data-contrast="none">Disposi&ccedil;&otilde;es Finais.</span></strong></p>
<p><span data-contrast="none">A Abrace uma Causa, a seu exclusivo crit&eacute;rio, poder&aacute; modificar os termos e condi&ccedil;&otilde;es constantes no presente Aviso, incluindo, mas n&atilde;o se limitando, &agrave;s hip&oacute;teses decorrentes do cumprimento de legisla&ccedil;&atilde;o, &agrave;s altera&ccedil;&otilde;es nos produtos e servi&ccedil;os, ou pela implanta&ccedil;&atilde;o de novas ferramentas tecnol&oacute;gicas. Para maior seguran&ccedil;a e dentro do princ&iacute;pio da transpar&ecirc;ncia, eventuais atualiza&ccedil;&otilde;es deste Aviso de Privacidade ser&atilde;o disponibilizadas para conhecimento dos titulares no site da Abrace uma Causa (</span><a href="https://abraceumacausa.com.br/"><span data-contrast="none">https://abraceumacausa</span></a><span data-contrast="none">).&nbsp;</span></p>
<p><span data-contrast="none">A Abrace uma Causa n&atilde;o se responsabiliza por problemas decorrentes de demora, interrup&ccedil;&atilde;o ou bloqueio nas transmiss&otilde;es dos dados enviados no seu endere&ccedil;o eletr&ocirc;nico.</span></p>
<p><span data-contrast="none">POL&Iacute;TICA DE COOKIES ABRACE UMA CAUSA</span></p>
<p><strong><span data-contrast="none">O QUE S&Atilde;O COOKIES?</span></strong></p>
<p><span data-contrast="none">Cookies s&atilde;o arquivos de texto baixados em seu dispositivo quando voc&ecirc; visita um site. S&atilde;o &uacute;teis por permitirem que um site reconhe&ccedil;a o dispositivo do usu&aacute;rio. Voc&ecirc; encontra mais informa&ccedil;&otilde;es sobre cookies em: </span><a href="https://www.allaboutcookies.org/"><span data-contrast="none">www.allaboutcookies.org</span></a><span data-contrast="none"> ou </span><a href="https://www.youronlinechoices.eu/"><span data-contrast="none">www.youronlinechoices.eu</span></a></p>
<p><span data-contrast="none">O termo cookie na verdade descreve uma s&eacute;rie de tecnologias, incluindo:</span></p>
<ul>
<li><span data-contrast="none"> pixel tags (imagens gr&aacute;ficas transparentes colocadas em uma p&aacute;gina da Web ou em um e-mail para indicar que foram visualizados),</span></li>
<li><span data-contrast="none"> identificadores de dispositivo m&oacute;vel,&nbsp;</span></li>
<li><span data-contrast="none"> armazenamento na Web usado em softwares do computador ou</span></li>
<li><span data-contrast="none"> em dispositivos m&oacute;veis.</span></li>
</ul>
<p><span data-contrast="none">Vamos usar o termo cookie ao longo desta pol&iacute;tica para abranger todas essas tecnologias, mas passaremos a voc&ecirc; todos os detalhes sobre elas, assim voc&ecirc; faz escolhas conscientes sobre as configura&ccedil;&otilde;es de cookies.</span></p>
<p><span data-contrast="none">Os cookies t&ecirc;m v&aacute;rias finalidades, como permitir a navega&ccedil;&atilde;o eficiente entre p&aacute;ginas, lembrar prefer&ecirc;ncias e, em geral, melhorar a experi&ecirc;ncia do usu&aacute;rio. Eles tamb&eacute;m podem ajudar a garantir que os an&uacute;ncios que voc&ecirc; v&ecirc; online sejam mais relevantes para voc&ecirc; e seus interesses.</span></p>
<p><span data-contrast="none">Como &eacute; pr&aacute;tica comum em quase todos os sites profissionais, este site usa cookies, que s&atilde;o pequenos arquivos baixados no seu computador, para melhorar sua experi&ecirc;ncia. Esta p&aacute;gina descreve quais informa&ccedil;&otilde;es eles coletam, como as usamos e por que &agrave;s vezes precisamos armazenar esses cookies. Tamb&eacute;m compartilharemos como voc&ecirc; pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.</span></p>
<p><span data-contrast="none">H&aacute; algumas categorias diferentes de cookies, incluindo:</span></p>
<p><strong><span data-contrast="none">Cookies de sess&atilde;o e persistentes</span></strong></p>
<ul>
<li><span data-contrast="none"> Cookies de sess&atilde;o - s&atilde;o cookies que expiram depois que voc&ecirc; fecha seu navegador da Web; e</span></li>
<li><span data-contrast="none"> Cookies persistentes - s&atilde;o cookies que devem permanecer no seu dispositivo por um per&iacute;odo definido ou at&eacute; voc&ecirc; exclu&iacute;-los.</span></li>
</ul>
<p><strong><span data-contrast="none">Cookies proprietários e de terceiros</span></strong></p>
<ul>
<li><span data-contrast="none"> Cookies proprietários - são cookies definidos pelo site que voc&ecirc; est&aacute; acessando no momento, seja por n&oacute;s, ou por um terceiro em nosso nome;</span></li>
<li><span data-contrast="none"> Cookies de terceiros - são cookies definidos por uma parte diferente da do site que voc&ecirc; est&aacute; acessando. Se voc&ecirc; visitar o site da Abrace uma Causa e outra parte definir um cookie por meio desse site, ser&aacute; cookie de terceiros.</span></li>
</ul>
<p><strong><span data-contrast="none">COMO USAMOS OS COOKIES?</span></strong></p>
<p><span data-contrast="none">Utilizamos cookies por v&aacute;rios motivos, detalhados abaixo. Infelizmente, na maioria dos casos, n&atilde;o existem op&ccedil;&otilde;es padr&atilde;o do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. &Eacute; recomend&aacute;vel que voc&ecirc; deixe todos os cookies se n&atilde;o tiver certeza se precisa ou n&atilde;o deles, caso sejam usados para fornecer um servi&ccedil;o que voc&ecirc; usa.</span></p>
<p><strong><span data-contrast="none">COOKIES DO NAVEGADOR</span></strong></p>
<p><span data-contrast="none">Voc&ecirc; pode retirar ou alterar o seu consentimento sobre o uso de cookies a qualquer momento. Se voc&ecirc; n&atilde;o quiser mais receber cookies, use as configura&ccedil;&otilde;es do navegador da Web para aceitar, recusar e excluir cookies. Para fazer isso, siga as instru&ccedil;&otilde;es de seu navegador (geralmente dentro das configura&ccedil;&otilde;es de "Ajuda", "Ferramentas" ou "Editar").</span></p>
<p><span data-contrast="none">Se voc&ecirc; configurar o navegador para recusar cookies, correr&aacute; o risco de n&atilde;o conseguir usar todos os recursos do site da Abrace uma Causa. Para obter mais informa&ccedil;&otilde;es, acesse </span><a href="https://www.allaboutcookies.org/"><span data-contrast="none">www.allaboutcookies.org</span></a><span data-contrast="none">.</span></p>
<p><strong><span data-contrast="none">IDENTIFICADORES DE DISPOSITIVOS M&Oacute;VEIS</span></strong></p>
<p><span data-contrast="none">Em seu dispositivo m&oacute;vel, o sistema operacional pode disponibilizar mais op&ccedil;&otilde;es para cancelar o interesse com base em an&uacute;ncios ou, ent&atilde;o, redefinir os identificadores de seu celular. Por exemplo, voc&ecirc; pode usar a op&ccedil;&atilde;o "Limitar rastreamento de an&uacute;ncios" (somente em dispositivos iOS) ou a configura&ccedil;&atilde;o "Cancelar an&uacute;ncios com base em interesses" (no Android) que permite limitar o uso de informa&ccedil;&otilde;es sobre o uso de aplicativos para fins de fornecimento de an&uacute;ncios direcionados a seus interesses.</span></p>
<p><strong><span data-contrast="none">COOKIES QUE DEFINIMOS</span></strong></p>
<ul>
<li><strong><span data-contrast="none">Cookies relacionados &agrave; conta.</span></strong><span data-contrast="none"> Se voc&ecirc; criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscri&ccedil;&atilde;o e administra&ccedil;&atilde;o geral. Esses cookies geralmente ser&atilde;o exclu&iacute;dos quando voc&ecirc; sair do sistema, por&eacute;m, em alguns casos, eles poder&atilde;o permanecer posteriormente para lembrar as prefer&ecirc;ncias do seu site ao sair.</span></li>
</ul>
<ul>
<li><strong><span data-contrast="none">Cookies relacionados ao login.</span></strong><span data-contrast="none"> Ap&oacute;s efetuado o login, guarda-se as informa&ccedil;&otilde;es na m&aacute;quina do usu&aacute;rio para que as mesmas sejam enviadas a cada nova requisi&ccedil;&atilde;o da p&aacute;gina. Assim que o usu&aacute;rio fechar a p&aacute;gina os cookies s&atilde;o apagados automaticamente.</span></li>
<li><strong><span data-contrast="none">Cookies relacionados a boletins por e-mail.</span></strong><span data-contrast="none"> Este site oferece servi&ccedil;os de assinatura de boletim informativo ou e-mail e os cookies podem ser usados para lembrar se voc&ecirc; j&aacute; est&aacute; registrado e se deve mostrar determinadas notifica&ccedil;&otilde;es v&aacute;lidas apenas para usu&aacute;rios inscritos / n&atilde;o inscritos.</span></li>
</ul>
<ul>
<li><strong><span data-contrast="none">Pedidos processando cookies relacionados.</span></strong><span data-contrast="none"> Este site oferece facilidades de com&eacute;rcio eletr&ocirc;nico ou pagamento e alguns cookies s&atilde;o essenciais para garantir que seu pedido seja lembrado entre as p&aacute;ginas, para que possamos process&aacute;-lo adequadamente.</span></li>
</ul>
<ul>
<li><strong><span data-contrast="none">Cookies relacionados a pesquisas.</span></strong><span data-contrast="none"> Periodicamente, oferecemos pesquisas e question&aacute;rios para fornecer informa&ccedil;&otilde;es interessantes, ferramentas &uacute;teis ou para entender nossa base de usu&aacute;rios com mais precis&atilde;o. Essas pesquisas podem usar cookies para lembrar quem j&aacute; participou numa pesquisa ou para fornecer resultados precisos ap&oacute;s a altera&ccedil;&atilde;o das p&aacute;ginas.</span></li>
</ul>
<ul>
<li><strong><span data-contrast="none">Cookies relacionados a formul&aacute;rios.</span></strong><span data-contrast="none"> Quando voc&ecirc; envia dados por meio de um formul&aacute;rio como os encontrados nas p&aacute;ginas de contato ou nos formul&aacute;rios de coment&aacute;rios, os cookies podem ser configurados para lembrar os detalhes do usu&aacute;rio para correspond&ecirc;ncia futura.</span></li>
</ul>
<ul>
<li><strong><span data-contrast="none">Cookies de prefer&ecirc;ncias do site.</span></strong><span data-contrast="none"> Para proporcionar uma &oacute;tima experi&ecirc;ncia neste site, fornecemos a funcionalidade para definir suas prefer&ecirc;ncias de como esse site &eacute; executado quando voc&ecirc; o usa. Para lembrar suas prefer&ecirc;ncias, precisamos definir cookies para que essas informa&ccedil;&otilde;es possam ser chamadas sempre que voc&ecirc; interagir com uma p&aacute;gina for afetada por suas prefer&ecirc;ncias.</span></li>
</ul>
<p><strong><span data-contrast="none">COOKIES DE TERCEIROS</span></strong></p>
<p><span data-contrast="none">Em alguns casos especiais, tamb&eacute;m usamos cookies fornecidos por terceiros confi&aacute;veis. A se&ccedil;&atilde;o a seguir detalha quais cookies de terceiros voc&ecirc; pode encontrar atrav&eacute;s deste site.</span></p>
<ul>
<li><span data-contrast="none">Este site usa o Google Analytics, que &eacute; uma das solu&ccedil;&otilde;es de an&aacute;lise mais difundidas e confi&aacute;veis da Web, para nos ajudar a entender como voc&ecirc; usa o site e como podemos melhorar sua experi&ecirc;ncia. Esses cookies podem rastrear itens como quanto tempo voc&ecirc; gasta no site e as p&aacute;ginas visitadas, para que possamos continuar produzindo conte&uacute;do atraente.</span></li>
</ul>
<p><span data-contrast="none">Para mais informa&ccedil;&otilde;es sobre cookies do Google Analytics, consulte a p&aacute;gina oficial do Google Analytics.</span></p>
<ul>
<li><span data-contrast="none">As an&aacute;lises de terceiros s&atilde;o usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conte&uacute;do atrativo. Esses cookies podem rastrear itens como o tempo que voc&ecirc; passa no site ou as p&aacute;ginas visitadas, o que nos ajuda a entender como podemos melhorar o site para voc&ecirc;.</span></li>
<li><span data-contrast="none">Periodicamente, testamos novos recursos e fazemos altera&ccedil;&otilde;es subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que voc&ecirc; receba uma experi&ecirc;ncia consistente enquanto estiver no site, enquanto entendemos quais otimiza&ccedil;&otilde;es os nossos usu&aacute;rios mais apreciam.</span></li>
<li><span data-contrast="none">&Agrave; medida que vendemos produtos, &eacute; importante entendermos as estat&iacute;sticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse &eacute; o tipo de dados que esses cookies rastrear&atilde;o. Isso &eacute; importante para voc&ecirc;, pois significa que podemos fazer previs&otilde;es de neg&oacute;cios com precis&atilde;o que nos permitem analisar nossos custos de publicidade e produtos para garantir o melhor pre&ccedil;o poss&iacute;vel.</span></li>
</ul>
<p><strong><span data-contrast="none">Mais informa&ccedil;&otilde;es</span></strong><span data-contrast="none"> - Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que voc&ecirc; n&atilde;o tem certeza se precisa ou n&atilde;o, geralmente &eacute; mais seguro deixar os cookies ativados, caso interaja com um dos recursos que voc&ecirc; usa em nosso site.</span></p>
<p><strong><span data-contrast="none">COOKIE DOUBLECLICK DART</span></strong></p>
<p><span data-contrast="none">O Google faz uso de cookies DART em nosso site, a utiliza&ccedil;&atilde;o deles &eacute; importante para que a empresa possa exibir an&uacute;ncios para o usu&aacute;rio levando em conta outros sites que a pessoa navegou anteriormente na internet, vale destacar que os usu&aacute;rios podem realizar a desativa&ccedil;&atilde;o de tais cookies acessando diretamente a pol&iacute;tica de privacidade do Google.</span></p>
<p><strong><span data-contrast="none">AN&Aacute;LISE DE DADOS</span></strong></p>
<p><span data-contrast="none">A nossa empresa utiliza o servi&ccedil;o do Google Analytics para mensurar, calcular e analisar todo o tr&aacute;fego que recebe no site www.abraceumacausa.com.br esta ferramenta coleta e processa os dados de acordo com a sua pr&oacute;pria pol&iacute;tica, partindo do pressuposto da aceita&ccedil;&atilde;o do usu&aacute;rio final, a pol&iacute;tica est&aacute; dispon&iacute;vel nos links abaixo:</span></p>
<ul>
<li><a href="https://www.google.com/intl/pt-BR/policies/privacy/partners/"><span data-contrast="none">https://www.google.com/intl/pt-BR/policies/privacy/partners/</span></a></li>
<li><a href="https://policies.google.com/privacy"><span data-contrast="none">https://policies.google.com/privacy</span></a></li>
<li><a href="https://policies.google.com/terms"><span data-contrast="none">https://policies.google.com/terms</span></a></li>
</ul>
<p><span data-contrast="none">COMPROMISSO DO USU&Aacute;RIO</span></p>
<p><span data-contrast="none">O usu&aacute;rio se compromete a fazer uso adequado dos conte&uacute;dos e da informa&ccedil;&atilde;o que o Abrace uma Causa oferece no site e com car&aacute;ter enunciativo, mas n&atilde;o limitativo:</span></p>
<ul>
<li><span data-contrast="none"> A) N&atilde;o se envolver em atividades que sejam ilegais ou contr&aacute;rias &agrave; boa f&eacute; a &agrave; ordem p&uacute;blica;</span></li>
<li><span data-contrast="none"> B) N&atilde;o divulgar conte&uacute;do ou propaganda de natureza racista, xenof&oacute;bica, apostas online, pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</span></li>
<li><span data-contrast="none"> C) N&atilde;o causar danos aos sistemas f&iacute;sicos (hardwares) e l&oacute;gicos (softwares) do Abrace uma Causa, de seus fornecedores ou terceiros, para introduzir ou disseminar v&iacute;rus inform&aacute;ticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</span></li>
</ul>
<p><span data-contrast="none">RECEBIMENTO DE E-MAILS:</span></p>
<p><span data-contrast="none">O envio de e-mail de divulga&ccedil;&otilde;es e newsletters ser&atilde;o realizados aos usu&aacute;rios que podem a qualquer momento voluntariamente desativar estes e-mails, exceto aqueles que s&atilde;o imprescind&iacute;veis para garantir a seguran&ccedil;a e a transpar&ecirc;ncia da plataforma. (Exemplo: e-mail de confirma&ccedil;&atilde;o de conta, extratos, etc.)</span></p>
<p><span data-contrast="none">DIVULGA&Ccedil;&Atilde;O DE E-MAILS PARA TERCEIROS:</span></p>
<p><span data-contrast="none">A Abrace Uma Causa compromete-se a n&atilde;o compartilhar seu e-mail com terceiros, exceto diante de express&atilde;o de vontade do usu&aacute;rio em atualizar-se diante de campanhas de projetos que incentiva e/ou de a&ccedil;&otilde;es de voluntariado em que participa.</span></p>
<p><span data-contrast="none">SEGURAN&Ccedil;A CIBERN&Eacute;TICA:</span></p>
<p><span data-contrast="none">A plataforma da Abrace Uma Causa pode reter o endere&ccedil;o de IP do usu&aacute;rio com o objetivo de identific&aacute;-lo em caso de tentativa de fraude.</span></p>
<p><span data-contrast="none">Al&eacute;m disso, o site possui monitoramento 24 (vinte e quatro) horas, bem como Backup incremental di&aacute;rio, redund&acirc;ncia de armazenamento de informa&ccedil;&otilde;es, servidor protegido por firewall, utiliza&ccedil;&atilde;o de criptografia para armazenamento de senhas e utiliza&ccedil;&atilde;o de protocolo SSL para comunica&ccedil;&atilde;o segura entre servidor e usu&aacute;rio.</span></p>
<p><span data-contrast="none">DADOS PESSOAIS</span></p>
<p><strong><span data-contrast="none">Plataforma de Doa&ccedil;&otilde;es</span></strong></p>
<p><span data-contrast="none">Neste ato Voc&ecirc; autoriza o tratamento de seus respectivos dados pessoais para cria&ccedil;&atilde;o de conta na plataforma para identifica&ccedil;&atilde;o de quem &eacute; o doador e para presta&ccedil;&atilde;o de contas (recibo, informe, consulta &agrave;s doa&ccedil;&otilde;es feitas, emiss&atilde;o de segunda via de boleto, etc), sendo certo que tais dados pessoais ser&atilde;o compartilhados com reparti&ccedil;&otilde;es p&uacute;blicas e/ou Fundo e/ou Entidade Apoiada com a finalidade de t&atilde;o somente informar a titularidade da doa&ccedil;&atilde;o realizada.&nbsp;</span></p>
<p><strong><span data-contrast="none">Plataforma de Voluntariado</span></strong></p>
<p><span data-contrast="none">Neste ato Voc&ecirc; autoriza o tratamento de seus respectivos dados pessoais para cria&ccedil;&atilde;o de conta na plataforma para identifica&ccedil;&atilde;o de quem &eacute; o volunt&aacute;rio e para registro de participa&ccedil;&atilde;o (lista de presen&ccedil;a no relato da a&ccedil;&atilde;o informado por seu l&iacute;der; informa&ccedil;&otilde;es de dispositivos e outros relatos informados pelo usu&aacute;rio), sendo certo que tais dados pessoais ser&atilde;o compartilhados com reparti&ccedil;&otilde;es p&uacute;blicas e/ou Entidade Idealizadora da A&ccedil;&atilde;o de Voluntariado e/ou Entidade Gestora da A&ccedil;&atilde;o de Voluntariado com a finalidade de t&atilde;o somente cadastrar, gerenciar e permitir a sua participa&ccedil;&atilde;o na a&ccedil;&atilde;o de voluntariado escolhida.&nbsp;</span></p>
<p><span data-contrast="none">O consentimento poder&aacute; ser revogado a qualquer momento mediante manifesta&ccedil;&atilde;o expressa do titular a Abrace Uma Causa, sendo certo que, nesta hip&oacute;tese, voc&ecirc; n&atilde;o poder&aacute; mais participar como doador das campanhas e/ou como volunt&aacute;rio das a&ccedil;&otilde;es de voluntariado dispon&iacute;veis em nossa plataforma. O armazenamento dos seus dados pessoais &eacute; feito na nuvem com utiliza&ccedil;&atilde;o de criptografia desde a transmiss&atilde;o dos dados do aparelho eletr&ocirc;nico do usu&aacute;rio at&eacute; a grava&ccedil;&atilde;o e manuten&ccedil;&atilde;o no servidor.</span></p>
<p><span data-contrast="none">A Abrace Uma Causa n&atilde;o armazena dados de cart&atilde;o, os quais s&atilde;o processados via gateway diretamente.</span></p>
<p><span data-contrast="none">DIREITOS DO TITULAR</span></p>
<p><span data-contrast="none">O titular do Dado Pessoal possui os seguintes direitos em rela&ccedil;&atilde;o aos seus dados, na medida em que tais direitos sejam reconhecidos pelas leis aplic&aacute;veis:</span></p>
<p><span data-contrast="none">Confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento: confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento dos Dados mantidos pela Abrace uma Causa;</span></p>
<p><span data-contrast="none">Acesso aos dados: acesso aos Dados coletados pela Abrace uma Causa , com exce&ccedil;&atilde;o dos casos de prote&ccedil;&atilde;o de segredo comercial e ind&uacute;stria;</span></p>
<p><span data-contrast="none">Corre&ccedil;&atilde;o de dados: solicita&ccedil;&atilde;o da corre&ccedil;&atilde;o dos Dados que contenham informa&ccedil;&otilde;es incompletas, desatualizadas ou err&ocirc;neas;</span></p>
<p><span data-contrast="none">Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o de dados: anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o dos Dados desnecess&aacute;rios, excessivos ou tratados em desconformidade com o disposto na legisla&ccedil;&atilde;o aplic&aacute;vel. A anonimiza&ccedil;&atilde;o se dar&aacute; considerando a utiliza&ccedil;&atilde;o de meios t&eacute;cnicos razo&aacute;veis e dispon&iacute;veis na ocasi&atilde;o do tratamento dos Dados;</span></p>
<p><span data-contrast="none">Portabilidade: portabilidade dos Dados Pessoais mediante requisi&ccedil;&atilde;o expressa, de acordo com a regulamenta&ccedil;&atilde;o da autoridade nacional, observados os segredos comercial e industrial.</span></p>
<p><span data-contrast="none">Elimina&ccedil;&atilde;o dos dados: elimina&ccedil;&atilde;o dos Dados Pessoais tratados com o consentimento do titular, exceto nas hip&oacute;teses previstas na legisla&ccedil;&atilde;o vigente, nesse sentido, sujeito aos requerimentos legais locais, a Abrace uma Causa pode manter o Dado Pessoal caso: (i) seja legalmente obrigada a mant&ecirc;-los, (ii) para o cumprimento de leis e/ou regulamentos que assim determinem; (iii) necessite dos Dados para estabelecer, exercer ou defender reivindica&ccedil;&otilde;es legais; e (iii) necessite manter o controle dos Dados por raz&otilde;es de sa&uacute;de p&uacute;blica.</span></p>
<p><span data-contrast="none">Informa&ccedil;&atilde;o sobre o compartilhamento de Dados: recebimento de informa&ccedil;&atilde;o sobre os seus Dados Pessoais que s&atilde;o compartilhados com entidades p&uacute;blicas e privadas.</span></p>
<p><span data-contrast="none">Revoga&ccedil;&atilde;o de consentimento: revoga&ccedil;&atilde;o do consentimento dado, a qualquer momento, mediante requerimento expresso do titular. O procedimento de revoga&ccedil;&atilde;o ser&aacute; sempre gratuito e facilitado; e</span></p>
<p><strong><span data-contrast="none">DADOS DE CONTATO</span></strong></p>
<p><span data-contrast="none">Para esclarecer alguma d&uacute;vida adicional, favor encaminhar uma mensagem para o e- mail: dpo@abraceumacausa.com.br</span></p>
<p><strong><span data-contrast="none">ALTERA&Ccedil;&Otilde;ES NA PRESENTE POL&Iacute;TICA</span></strong></p>
<p><span data-contrast="none">Poderemos ocasionalmente efetuar altera&ccedil;&otilde;es na presente Pol&iacute;tica.</span></p>
<p><span data-contrast="none">Quando realizarmos altera&ccedil;&otilde;es relevantes na presente Pol&iacute;tica, voc&ecirc; ser&aacute; devidamente notificado, conforme as circunst&acirc;ncias, por exemplo, exibindo um aviso na p&aacute;gina principal de https://abraceumacausa.com.br ou enviando-lhe um e-mail para contatos cadastrados em nossos servi&ccedil;os.</span></p>
<p><strong><span data-contrast="none">DISPOSI&Ccedil;&Otilde;ES FINAIS</span></strong></p>
<p><span data-contrast="none">A Abrace uma Causa, a seu exclusivo crit&eacute;rio, poder&aacute; modificar os termos e condi&ccedil;&otilde;es constantes no presente Aviso, incluindo, mas n&atilde;o se limitando, &agrave;s hip&oacute;teses decorrentes do cumprimento de legisla&ccedil;&atilde;o, &agrave;s altera&ccedil;&otilde;es nos produtos e servi&ccedil;os, ou pela implanta&ccedil;&atilde;o de novas ferramentas tecnol&oacute;gicas. Para maior seguran&ccedil;a e dentro do princ&iacute;pio da transpar&ecirc;ncia, eventuais atualiza&ccedil;&otilde;es deste Aviso de Privacidade ser&atilde;o disponibilizadas para conhecimento dos titulares no site da Abrace uma Causa (https://abraceumacausa.com.br). A Abrace uma Causa n&atilde;o se responsabiliza por problemas decorrentes de demora, interrup&ccedil;&atilde;o ou bloqueio nas transmiss&otilde;es dos dados enviados no seu endere&ccedil;o eletr&ocirc;nico.</span></p>
<p><span data-contrast="none">Essa Pol&iacute;tica foi atualizada pela &uacute;ltima vez em 10 de maio de 2021.</span></p>
            </pre>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";

export default {
  props: ["value"],
  components: {
    Modal
  },
  data() {
    return {
      dialog: false
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
      this.$emit("input", this.dialog);
    }
  }
};
</script>
