import PaymentSenderInfo from "./paymentSenderInfo.model";
import BillingAddress from "./billingAddress.mode";
import CreditCard from "./creditCard.model";

export default class CreditCardInfo {
    constructor() {
        this.creditCard = new CreditCard();
        this.holder = new PaymentSenderInfo();
        this.token = '';
        this.billingAddress = new BillingAddress();
        this.cardNumber = '';
        this.brand = null;
        this.cvv = '';
        this.expirationMonth = '';
        this.expirationYear = '';
        this.creditCardId = "";
    }

    get cardBin() {
        return this.cardNumber.slice(0, 6);
    }
}