<template>
    <div class="block-container" :style="{maxWidth: maxWidth + 'px'}">
    <slot/>
  </div>
</template>

<script type="plain/text">
export default {
    props: {
        maxWidth: null
    }
};
</script>