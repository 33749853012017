const PROJECT = 'new-project'
const CartStorage = {
    getProject() {
        let project = localStorage.getItem(PROJECT);
        return JSON.parse(project);
    },

    setProject(value) {
        localStorage.setItem(PROJECT, JSON.stringify(value));
    },

    removeProject() {
        localStorage.removeItem(PROJECT);
    }
}

export default CartStorage;