<template>
  <div class="display-flex justify-content-center">
    <div v-if="!rounded" :class="defaultClass" id="preview" ref="preview" :style="{width , backgroundImage: 'url(' + getImage() + ')'}">
      <label for="upload-input">
        <i class="fas fa-camera-alt"></i>
      </label>
      <label for="upload-input" class="do-upload" v-show="file.path == ''">
        <i class="fal fa-cloud-upload"></i>
        {{unselectedText}}
      </label>
      <input
        type="file"
        id="upload-input"
        accept="image/*"
        @change="fileChanged($event)"
        ref="upload_input"
      />
    </div>
    <div v-else class="upload-image rounded" id="preview" ref="preview" :style="{backgroundImage: 'url(' + getImage() + ')'}">
      <label for="upload-input" class="d-none">
        <i class="fas fa-camera-alt"></i>
      </label>
      <label for="upload-input" class="do-upload" v-show="file.path == ''">
        <i class="fal fa-cloud-upload"></i>
        {{unselectedText}}
      </label>
      <input
        type="file"
        id="upload-input"
        :accept="accept.length > 0 ? accept.join(',') : 'image/*'"
        @change="fileChanged($event)"
        ref="upload_input"
      />
    </div>
  </div>
</template>

<script type="plain/text">
// Documentação https://github.com/xkeshi/image-compressor
import ImageCompressor from "image-compressor.js";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  props: {
    unselectedText: {
      type: String,
      default: ''
    },
    rounded:Boolean,
    defaultClass: {
      type: String,
      default: 'upload-image bg-contain'
    },
    quickName: String,
    file: Object,
    maxWidth: {
      type: Number,
      default: 1000
    },
    maxHeight: {
      type: Number,
      default: 1000
    },
    minWidth: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    quality: {
      type: Number,
      default: 0.65
    },
    width:{
      type: String,
      default: "300px"
    },
    accept: Array
  },
  data() {
    return {
      loader: false
    };
  },
  methods: {
    swal(title, message, type) {
      CommonHelper.swal(title, message, type);
    },
    getImage(){
        return this.quickName ? 'https://ui-avatars.com/api/?background=F1F2F5&color=80849A&size=256&name=' + this.quickName : this.file.path;
    },
    create_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    },
    fileChanged(event) {
        var selectedFile = event.target.files[0];
        if (!this.accept || this.accept.length === 0) this.accept = 'image/*'
        if (this.accept.indexOf(selectedFile.type) !== -1 || this.accept === 'image/*') {
          const fileExtension = selectedFile.name.substr(selectedFile.name.indexOf(".") + 1)
          this.loader = true;
          new ImageCompressor(selectedFile, {
            quality: this.quality,
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight,
            minWidth: this.minWidth,
            minHeight: this.minHeight,
            success: function(result) {
              this.file.name = this.create_UUID() + '.' + fileExtension;
              let reader = new FileReader();
              reader.onload = function() {
                this.file.path = reader.result;
              }.bind(this);
              reader.readAsDataURL(result);
              this.loader = false;
            }.bind(this),
            error(e) {
              console.log(e.message);
            }
          });
        } else {
          this.swal("Erro", "Tipo de imagem inválida!", "error");
        } 
      }    
    }
};
</script>