<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-text>
			<v-layout>
				<v-flex confirmation-alert>
					<v-flex pa-5 p-relative>
            <i v-if="icon" :class="icon" class="icon-alert"></i>
						<h2 v-if="title">{{title}}</h2>
						<p v-if="message">{{message}}</p>
					</v-flex>
					<v-flex pb-4 px-5 ml-auto>
						<button class="mr-5 fw-700" @click="sendAndSave">{{cancelButtonText}}</button>
						<button class="fw-700" @click="dialog = false; $emit('confirm')">{{confirmButtonText}}</button>
					</v-flex>
				</v-flex>
			</v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="plain/text">
export default {
  props:{
    title: '',
    message: '',
    confirmButtonText: '',
    cancelButtonText: '',
    icon:'',
    startOpen: false,
    withStorage:false
  },
  created(){
    if(this.withStorage == true && this.startOpen){
      if(localStorage.getItem('maybeValueSimulation')!=null){
        if(localStorage.getItem('maybeValueSimulation') == 'false')
          this.dialog = true;
        else
          this.dialog = false;
        }
      else {
        localStorage.setItem('maybeValueSimulation', false);
        this.dialog = true
      }
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    sendAndSave(){
      this.dialog = false;
      if(this.withStorage) {
        localStorage.setItem('maybeValueSimulation', true);
        this.dialog = false;
      }
      this.$emit('cancel');
    }
  },
  data() {
    return {
      dialog: this.startOpen,
    };
  }
};
</script>