<template>
  <div class="autocomplete">
    <label v-if="textLabel" class="d-flex">{{ textLabel }}{{ required ? ' *' : '' }}</label>
    <input 
      ref="input"
      @input="onInput" 
      @keydown.down.prevent="onArrowDown" 
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="onEnter" 
      @blur="onBlur"
      v-model="query" 
      type="text" 
      :placeholder="placeHolder" 
      autocomplete="off"
    />
    <span v-if="query" class="clear-icon" @click="clearSelection" :style="{ top: textLabel ? '65%' : '50%' }">
      <i class="fas fa-times-circle"></i>
    </span>
    <div v-if="filteredItems.length" class="autocomplete-items">
      <div v-for="(item, index) in filteredItems" :key="item" :class="{ 'autocomplete-active': index === currentFocus }"
        @click="setActive(item)" @mouseover="currentFocus = index">
        <strong>{{ item.substr(0, query.length) }}</strong>{{ item.substr(query.length) }}
        <input type="hidden" :value="item" />
      </div>
    </div>
    <v-flex
      justify-space-between
      d-flex
      v-if="helpLabel || invalidInputMessage"
    >
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
        >{{ invalidInputMessage }}</label
      >
    </v-flex>
  </div>
</template>
<script>
import Fuse from 'fuse.js';
import ValidatableInput from "@/components/base/ValidatableInput.vue";
import { isValid } from 'date-fns';

export default {
  extends: ValidatableInput,
  props: {
    items: {
      type: Array,
      required: true
    },
    placeHolder: {
      type: String,
      default: 'Digite aqui'
    },
    textLabel: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      query: '',
      filteredItems: [],
      currentFocus: -1,
      fuse: null
    };
  },
  mounted() {
    this.fuse = new Fuse(this.items.map(item => ({ name: item })), {
      includeScore: true,
      threshold: 0.3,
      keys: ['name']
    });
  },
  methods: {
    onInput() {
      const result = this.fuse.search(this.query);
      this.filteredItems = result.slice(0, 4).map(({ item }) => item.name);
      this.currentFocus = -1;
    },
    onArrowDown() {
      if (this.currentFocus < this.filteredItems.length - 1) {
        this.currentFocus++;
      }
    },
    onArrowUp() {
      if (this.currentFocus > 0) {
        this.currentFocus--;
      }
    },
    onEnter() {
      if (this.currentFocus > -1) {
        this.query = this.filteredItems[this.currentFocus];
        this.$emit('selection-changed', this.query);
        this.filteredItems = [];
      }
    },
    onBlur() {
      setTimeout(() => {
        if (!this.items.includes(this.query)) {
          this.query = '';
          this.$emit('selection-changed', this.query);
        }
      }, 200);
    },
    setActive(item) {
      this.query = item;
      this.$emit('selection-changed', this.query);
      this.filteredItems = [];
    },
    clearSelection() {
      this.query = '';
      this.filteredItems = [];
      this.$emit('selection-changed', this.query);
    },
  },
  watch: {
    query(val) {
      if (!val) {
        this.filteredItems = [];
      }
      this.$emit('input', val)
    }
  }
};
</script>
<style scoped>
.autocomplete {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
  overflow: visible;
}

.autocomplete-items div {
  padding: 4px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
  background-color: rgba(var(--theme-primaria));
}

.autocomplete-active {
  background-color: rgba(var(--theme-primaria)) !important;
  color: #fff
}

.clear-icon {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.clear-icon:hover {
  color: #555;
}
</style>
