import CreditCardCartDetailsStatus from '@/scripts/models/enums/creditCardCartDetailsStatus.enum';
import DonationRecurrencePeriod from '@/scripts/models/enums/donationRecurrencePeriod.enum';

export default class CreditCardCartDetails {

    constructor() {
        this.id = 0;
        this.cartId = 0;
        this.pagSeguroPreApprovalCode = '';
        this.pagSeguroTransactionCode = '';
        this.statusId = CreditCardCartDetailsStatus.Created;
        this.creationDate = new Date();
        this.lastUpdateDate = new Date();
        this.cart = null;
        this.donationRecurrencePeriod = null;
    }

}