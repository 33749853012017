import BaseService from '@/scripts/services/base.service';
import CommonHelper from '../helpers/common.helper';
// import CheckoutRequest from '@/scripts/models/checkoutRequest.model';

export default class PagSeguroService extends BaseService {

  constructor(pagSeguroDirectPayment) {
    super('PagSeguro');
    this.pagSeguroDirectPayment = pagSeguroDirectPayment;
  }

  createCheckout(checkout) {
    return this.postRequest('createCheckout');
  }

  createSession(checkoutRequest) {
    return this.postRequest('createSession', null, true, false)
      .then(function (session) {
        return this.onSessionCreated(session, checkoutRequest);
      }.bind(this))
  }

  onSessionCreated(session, checkoutRequest) {
    console.log(session);
    this.pagSeguroDirectPayment.setSessionId(session.id);
    return checkoutRequest;
  }

  prepareSenderHash(checkoutRequest) {
    return new Promise(function (resolve, reject) {
      this.pagSeguroDirectPayment.onSenderHashReady(function (response) {

        if (response.status == "error") {
          reject(response.message);
        }

        checkoutRequest.paymentSenderInfo.hash = response.senderHash;
        resolve(checkoutRequest);

      }.bind(this))
    }.bind(this));
  }

  getCardBrand(checkoutRequest) {
    console.log(checkoutRequest);
    return new Promise(function (resolve, reject) {
      this.pagSeguroDirectPayment.getBrand({
        cardBin: checkoutRequest.creditCardInfo.cardNumber.slice(0, 6),
        success: function (response) {
          checkoutRequest.creditCardInfo.brand = response.brand.name;
          resolve(checkoutRequest);
        }.bind(this),
        error: function (response) {
          reject(response);
        }.bind(this)
      });
    }.bind(this));
  }

  createCardToken(checkoutRequest) {
    return new Promise(function (resolve, reject) {
      this.pagSeguroDirectPayment.createCardToken(
        Object.assign(checkoutRequest.creditCardInfo, {
          success: function (response) {
            checkoutRequest.creditCardInfo.token = response.card.token;
            resolve(checkoutRequest);
          }.bind(this),
          error: function (response) {
            reject(response);
          }.bind(this)
        })
      );
    }.bind(this));
  }


  // createTransaction(card) {
  //   return this.postRequest('createTransaction', card);
  // }

  createPreApproval(card) {
    return this.postRequest('createPreApproval', card);
  }

  createTransaction(hash, token) {
    return this.postRequest('CreateTransaction', {
      hash: hash,
      creditCardToken: token
    });
  }

  prepareCreditCardCheckout(checkoutRequest) {
    console.log(checkoutRequest)
    return this.createSession(checkoutRequest)
      .then(this.prepareSenderHash.bind(this))
      .then(this.getCardBrand.bind(this))
      .then(this.createCardToken.bind(this))
      .catch(function(response){
        console.log(response);

        CommonHelper.swal('','Ops, por favor, certifique-se de que as informações do seu cartão crédito estão corretas.','error');

        throw response;
      });
    // return this.postRequest('CreateSubscription', {
    //   hash: hash,
    //   creditCardToken: token
    // });
  }
}