export default class User {

    constructor() {
        this.username = "";
        this.password = "";
        this.token = "";
        this.tokenRefresh = "";
        this.expirationDate = "";
        this.active = false;
        this.tempHash = "";
        this.googleId = "";
        this.facebookId = "";
        this.lastLogin = "";
        this.id = 0;
    }

}