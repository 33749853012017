import axios from "axios";

export const logLevels = {
    Trace: 0,
    Debug: 1,
    Information: 2,
    Warning: 3,
    Error: 4,
    Critical: 5,
};

class LoggerHelper {
    constructor({ sessionId, url, minLevel = logLevels.Information }) {
        this.minLevel = minLevel;
        this.sessionId = sessionId;
        this.http = axios.create({
            baseURL: url,
        });

        this.http.interceptors.response.use(
            (response) => response,
            (error) =>
                window.console.error(
                    "Failed to reach the logging server",
                    error
                )
        );
    }

    payload(message, logLevel = logLevels.Information) {
        return {
            sessionId: this.sessionId,
            logLevel,
            logMessage: message,
            timeStamp: new Date(),
        };
    }

    trace(message) {
        if (this.minLevel > logLevels.Trace) return;
        const payload = this.payload(message, logLevels.Trace);
        return this.http.post("/", payload);
    }

    debug(message) {
        if (this.minLevel > logLevels.Debug) return;
        const payload = this.payload(message, logLevels.Debug);
        return this.http.post("/", payload);
    }

    info(message) {
        if (this.minLevel > logLevels.Information) return;
        const payload = this.payload(message, logLevels.Information);
        return this.http.post("/", payload);
    }

    warn(message) {
        if (this.minLevel > logLevels.Warning) return;
        const payload = this.payload(message, logLevels.Warning);
        return this.http.post("/", payload);
    }

    error(message) {
        if (this.minLevel > logLevels.Error) return;
        const payload = this.payload(message, logLevels.Error);
        return this.http.post("/", payload);
    }

    critical(message) {
        const payload = this.payload(message, logLevels.Critical);
        return this.http.post("/", payload);
    }

    log(message, logLevel = logLevels.Information) {
        const payload = this.payload(message, logLevel);
        return this.http.post("/", payload);
    }
}

export default LoggerHelper;
