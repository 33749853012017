<template></template>
<script type="plain/text">
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  props: {
    helpLabel: "",
    textLabel: "",
    required: {
      type: Boolean,
      default: true
    },
    disabled: false,
    invalidInputMessage: "",
    autoFocusOnValidationFail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { valid: true };
  },
  computed: {
    showValidationMessage: {
      get() {
        return !this.valid;
      },
      set(val) {
        this.valid = !val;
      }
    }
  },
  created() {
    this.$on("focus", this.focus);
    this.$on("input", this.isValid);
    if (!this.onValidationSuccessMethod)
      this.onValidationSuccessMethod = this.validationSuccess;
    if (!this.onValidationFailMethod)
      this.onValidationFailMethod = this.validationFail;
    if (!this.validationMethod) this.validationMethod = this.validate;
  },
  methods: {
    focus() {
      if (this.$refs.input && typeof this.$refs.input.focus == "function")
        this.$refs.input.focus();
    },
    validate() {
      if (this.required && (this.value == null || this.value == "")) {
        return false;
      }
      return true;
    },
    validationSuccess() {
      if (!this.valid) this.$emit("validationStatusChanged", true);
      this.valid = true;
    },
    validationFail() {
      if (this.valid) this.$emit("validationStatusChanged", false);
      this.valid = false;
      if (this.autoFocusOnValidationFail) {
        this.focus();
        if (this.money != null) this.$el.scrollIntoView();
      }
    }
  },
  watch: {
    value() {
      this.isValid();
    }
  }
};
</script>
