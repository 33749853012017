var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-navigation-drawer',{attrs:{"value":_vm.value,"fixed":"","temporary":"","width":"400","right":""},on:{"input":_vm.changeValue}},[_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.logged),expression:"logged"}],attrs:{"header":""}},[_c('v-layout',{attrs:{"avatar":"","p-relative":""}},[_c('div',{staticClass:"avatar-profile mr-3"},[_c('img',{attrs:{"src":_vm.getUrl(),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"name-profile"},[(_vm.person.socialReason)?_c('h2',[_vm._v(_vm._s(_vm.person.socialReason))]):_c('h2',[_vm._v(_vm._s(_vm.person.name))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.person.email))]),_vm._v(" "),(_vm.person.cnpj)?_c('div',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm._f("FormatDocument")(_vm.person.cnpj,'J')))]):_c('div',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm._f("FormatDocument")(_vm.person.cpf)))]),_vm._v(" "),_c('div',{staticClass:"text-gray mt-1"},[_c('a',{on:{"click":function($event){_vm.logout();
                  _vm.$gtag('send', 'event', 'Navbar', 'clicou', 'Sair');}}},[_c('faIcon',{staticClass:"mr-1",attrs:{"type":"fal","name":"sign-out","size":"16px"}}),_vm._v("Sair\n              ")],1)])]),_vm._v(" "),_c('v-btn',{staticClass:"btn-close-drawer-mobile",on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('Icon',{attrs:{"name":'fal fa-times',"size":24,"fontColor":"#c8cbd9"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"scroll"},[_c('v-list',_vm._l((_vm.getItems()),function(line){return _c('v-list-tile',{key:line.title},[_c('router-link',{staticClass:"align-center display-flex",attrs:{"to":{ name: line.link }}},[_c('v-list-tile-action',[_c('faIcon',{attrs:{"type":"fal","name":line.faIcon,"size":"18px"}})],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(line.title))])],1)],1)],1)}),1),_vm._v(" "),(_vm.hasPermissionGestion)?_c('h5',[_vm._v("Gestão AUC")]):_vm._e(),_vm._v(" "),(_vm.hasPermissionGestion)?_c('v-list',_vm._l((_vm.getItemsGestao()),function(line){return _c('v-list-tile',{key:line.title},[(line.global)?_c('router-link',{staticClass:"align-center display-flex",attrs:{"to":{ name: line.link }}},[_c('v-list-tile-action',[_c('faIcon',{attrs:{"type":"fal","name":line.faIcon,"size":"18px"}})],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(line.title))])],1)],1):_c('router-link',{staticClass:"align-center display-flex",attrs:{"to":{
                name: line.link,
                query: { auc: true, entityId: _vm.entityIdAUC },
              }}},[_c('v-list-tile-action',[_c('faIcon',{attrs:{"type":"fal","name":line.faIcon,"size":"18px"}})],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(line.title))])],1)],1)],1)}),1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.logged),expression:"logged"}],staticClass:"grupos"},[(
              _vm.companyList.length > 0 ||
              _vm.institutionList.length > 0 ||
              _vm.aucList.length > 0 ||
              _vm.councilList.length > 0
            )?_c('h5',[_vm._v("\n            Meus Perfis\n          ")]):_vm._e(),_vm._v(" "),(_vm.councilList.length > 0)?_c('h6',[_vm._v("Conselhos")]):_vm._e(),_vm._v(" "),_c('dl',{staticClass:"cons"},_vm._l((_vm.councilList),function(item){return _c('dd',{key:item.id},[_c('router-link',{attrs:{"to":{
                  name: _vm.routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }}},[_vm._v(_vm._s(item.name))])],1)}),0),_vm._v(" "),(_vm.companyList.length > 0)?_c('h6',[_vm._v("Empresas")]):_vm._e(),_vm._v(" "),_c('dl',{staticClass:"emp"},_vm._l((_vm.companyList),function(item){return _c('dd',{key:item.id},[_c('router-link',{attrs:{"to":{
                  name: _vm.routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }}},[_vm._v(_vm._s(item.name))])],1)}),0),_vm._v(" "),(_vm.institutionList.length > 0)?_c('h6',[_vm._v("Instituições")]):_vm._e(),_vm._v(" "),_c('dl',{staticClass:"inst"},_vm._l((_vm.institutionList),function(item){return _c('dd',{key:item.id},[_c('router-link',{attrs:{"to":{
                  name: _vm.routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }}},[_vm._v(_vm._s(item.name))])],1)}),0),_vm._v(" "),(_vm.aucList.length > 0)?_c('h6',[_vm._v("Plataforma")]):_vm._e(),_vm._v(" "),_c('dl',{staticClass:"inst"},_vm._l((_vm.aucList),function(item){return _c('dd',{key:item.id},[_c('router-link',{attrs:{"to":{
                  name: _vm.routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }}},[_vm._v(_vm._s(item.name))])],1)}),0)])],1)],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }