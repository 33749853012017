const CampaignTypes = {
    Incentivada: 1,
    SemIncentivoFiscal: 2,
    Ticket: 3,
    Voluntariado: 4
};

const CampaignTypesList = [
    { id: CampaignTypes.Incentivada, name: "Incentivada" },
    { id: CampaignTypes.SemIncentivoFiscal, name: "Sem Incentivo Fiscal" },
    { id: CampaignTypes.Ticket, name: "Ticket" },
    { id: CampaignTypes.Voluntariado, name: "Voluntariado" }
]

export { CampaignTypes, CampaignTypesList };