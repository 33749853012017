<template>
  <v-layout row wrap w-100>
    <v-flex class="mr-4" v-for="(elements, index) in elementsPerColumn" :key="index">
      <div v-for="(item, index2) in elements" v-bind:key="'div-'+index2">
        <v-checkbox
          color="#02ACB4"
          class="ma-0"
          ref="input"
          v-model="selectedList"
          :label="item[labelAttribute]"
          :key="'cb-' + item[valueAttribute]"
          :value="item[valueAttribute]"
          @change="checkboxListchanged(selectedList), $emit('focus', helpBoxText)"
          :disabled="listSizeLimit && selectedList.length == listSizeLimit && !selectedList.includes(item[valueAttribute])"
        ></v-checkbox>
        <InputMoney
          :key="'im-' + item[valueAttribute]"
          v-show="selectedList.includes(item[valueAttribute])"
          v-model="model[item[valueAttribute]]"
          textLabel="Qual será o valor mínimo da doação?"
          :precision="0"
          @blur="emitInput()"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script type="plain/text">
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
export default {
  extends: ValidatableComponent,
  props: {
    list: "",
    labelAttribute: "",
    valueAttribute: "",
    value: "",
    helpBoxText: "",
    columns: Number,
    numberOfElementsPerColumn: Array,
    listSizeLimit: Number
  },
  data() {
    return {
      model: Object,
      selectedList: [],
      elementsPerColumn: []
    };
  },
  created() {
    this.$on("focus", this.focus);
    if (!this.validationMethod) this.validationMethod = this.validate;
    if (!this.onValidationSuccessMethod)
      this.onValidationSuccessMethod = this.validationSuccess;
    if (!this.onValidationFailMethod)
      this.onValidationFailMethod = this.validationFail;
  },
  mounted() {
    this.elementsPerColumn = [];
    for (let i = 0; i < this.columns; i++) {
      let elements = [];
      for (let j = 0; j < this.numberOfElementsPerColumn[i]; j++) {
        elements.push({});
      }
      this.elementsPerColumn.push(elements);
    }
    this.buildElementsPerColumn();
    this.updateModel(this.value);
  },
  methods: {
    buildElementsPerColumn() {
      for (let i = 1; i <= this.columns; i++) {
        let elements = this.getElements(i);
        for (let j = 0; j < elements.length; j++) {
          this.$set(this.elementsPerColumn[i - 1], j, elements[j]);
        }
      }
    },
    getElements(columnIndex) {
      if (!this.list) return [];

      let firstElementIndexOfCurrentColumn = 0;
      let lastElementIndexOfCurrentColumn = 0;

      if (
        !this.numberOfElementsPerColumn ||
        this.numberOfElementsPerColumn.length != this.columns
      ) {
        let num = Math.ceil(this.list.length / this.columns);
        firstElementIndexOfCurrentColumn = (columnIndex - 1) * num;
        lastElementIndexOfCurrentColumn = Math.min(
          columnIndex * num,
          this.list.length
        );
      } else {
        firstElementIndexOfCurrentColumn = 0;
        lastElementIndexOfCurrentColumn = 0;
        for (let i = 0; i < columnIndex - 1; i++) {
          firstElementIndexOfCurrentColumn = lastElementIndexOfCurrentColumn;
          lastElementIndexOfCurrentColumn += this.numberOfElementsPerColumn[i];
        }
        firstElementIndexOfCurrentColumn = lastElementIndexOfCurrentColumn;
        lastElementIndexOfCurrentColumn += this.numberOfElementsPerColumn[
          columnIndex - 1
        ];
      }
      let elements = this.list.slice(
        firstElementIndexOfCurrentColumn,
        lastElementIndexOfCurrentColumn
      );
      return elements;
    },
    focus() {
      if (this.$refs && this.$refs.input && this.$refs.input.length > 0) {
        this.$refs.input[0].$el.focus();
      }
    },
    validate() {
      if (!this.selectedList) {
        return false;
      }

      if (this.selectedList.length == undefined) {
        Object.keys(this.selectedList).forEach(c => {
          if (this.selectedList[c] <= 0) {
            return false;
          }
        });
      }
      return true;
    },
    validationSuccess() {
      this.$emit("validationStatusChanged", false);
    },
    validationFail() {
      this.$emit("validationStatusChanged", true);
      this.focus();
    },
    checkboxListchanged(list) {
      Object.keys(this.model).forEach(c => {
        if (!list.includes(parseInt(c))) {
          delete this.model[c];
        }
      });
      this.$emit("input", this.model);
    },
    emitInput() {
      this.checkboxListchanged(this.selectedList);
    },
    updateModel(val) {
      this.model = JSON.parse(JSON.stringify(val));
      this.selectedList = Object.keys(val).map(k => parseInt(k));
    }
  },
  watch: {
    value(val) {
      this.updateModel(val);
    },
    list() {
      this.buildElementsPerColumn();
    }
  }
};
</script>
