import BaseCrudService from "./baseCrud.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

export default class DocService extends BaseCrudService {

    constructor() {
        super('Doc');
    }
    showReceiptById(id){
        return this.getRequest('ShowReceiptId', { receiptId: id });
    }

    findAllPendingSignature(entityId, callback) {
        return this.getRequest('PendingSignature', { entityId: entityId}).then(callback);
    }

    findAllPendingSignatureAlt(entityId) {
        return this.getRequest('PendingSignature', { entityId: entityId}); 
    }

    findAllSigned(entityId, year, callback) {
        return this.getRequest('ReceiptSigned', { entityId: entityId, year: year}).then(callback);
    }

    findAllToGenerate(entityId, callback) {
        return this.getRequest('ReceiptToGenerate', { entityId: entityId}).then(callback);
    }

    findReceiptsGenerating(entityId, callback) {
        return this.getRequest('ReceiptsGenerating', { entityId: entityId}).then(callback);
    }

    getSignersByEntityId(entityId){
        return this.getRequest('GetSignersByEntityId', {entityId});
    }

    generateReceipts(args){
        return this.postRequest('GenerateReceipts', {cartItemsIds: args.cartItemsIds, personIds: args.personIds, subscriptionType: args.subscriptionType, entityId: args.entityId})
    }

    saveReceiptSigned(callback, docs) {
        return this.postRequest('saveReceiptSigned', docs).then(callback);
    }

    saveReceiptSignedManual(formData) {
        return this.postFileRequest('saveReceiptSignedManual', formData);
    }

    getReceipt(chave, callback) {
        return this.getRequestBlob('GetReceiptCriptedFilePath', { chave: chave}).then(callback);
    }

    getIncome(chave, callback) {
        return this.getRequestBlob('IncomeReport', { key: chave}).then(callback);
    }

    schedulePDFsGeneration(campaignId) {
        return this.getRequest("GenerateConcentTerm", { campaignId: campaignId });
    }

    getIncomeCodesByCompanyAndUser(callback) {
        return this.getRequest('IncomeCodesByCompanyAndUser').then(callback);
    }

    downloadZipReceipts(entityId, year){
        return this.getRequest("DownloadZipReceipts", {entityId, year});
    }

    downloadNotGeneratedReceipts(entityId, personId = null){
        return this.getRequestBlob("DownloadZipReceiptsNoGenerated", {entityId, personId})
    }

    restoreReceipt(cartItemId){
        return this.getRequest("RestoreReceipt", {cartItemId});
    }

    //Manual Receipts
    saveReceiptManual(receiptManual){
        return this.postRequest("SaveReceiptManual", receiptManual);
    }

    listReceiptManualByInstitutionId(institutionId){
        return this.getRequest("ListReceiptManualByInstitutionId", {institutionId : institutionId});
    }

    generateReceiptManual(receiptManualId){
        return this.getRequest("GenerateReceiptManual", { id : receiptManualId });
    }

    getReceiptByReceiptManualId(receiptManualIds){
        return this.postRequest("GetReceiptChaveCSByReceiptManualId", receiptManualIds)
    }

    generateReceiptByReceiptManual(receiptManualIds){
        return this.postRequest("GenerateReceiptByReceiptManual", receiptManualIds)
    }

    setDonationDateToReceiptManual(receiptManual){
        return this.postRequest("SetDonationDateToReceiptManual", receiptManual)
    }

    cancelReceipt(receiptManual){
        return this.postRequest("CancelReceipt", receiptManual);
    }

    getManualSigners(receiptManualId){
        return this.getRequest("GetManualSigners", {receiptManualId : receiptManualId});
    }

    getSignerByReceiptId(receiptId){
        return this.postRequest("GetSignerByReceiptId", receiptId);
    }

    getReceiptByCartItemId(expectedCartId){
        return this.getRequest("GetReceiptByExpectedCartId", {expectedCartId: expectedCartId});
    }

    getAllReceiptsByCartItemId(expectedCartId){
        return this.getRequest("GetAllReceiptsByExpectedCartId", {expectedCartId: expectedCartId});
    }
    
    listAllReceiptManualPersonByInstitutionId(institutionId, callback) {
        return this.getRequest("ListAllReceiptManualPersonByInstitutionId", {institutionId: institutionId}).then(callback)
    }

    listAllReceiptManualPersonByDocument(institutionId, document, callback) {
        return this.getRequest("ListAllReceiptManualPersonByDocument", {institutionId: institutionId, document: document}).then(callback)
    }

    savePerson(person) {
        return this.postRequest("SavePerson", person)
    }
}