<template>
  <v-dialog v-model="dialog" persistent scrollable :maxWidth="myMaxWidth ? myMaxWidth : '900px'">
    <v-card class="column">
      <v-card-title class="shrink headline" :style="cardTitle ? 'padding: 15px' : 'padding: 0'" v-if="cardTitle">
        <h1>{{modalTitle}}</h1>
        <a href="#"></a>
        <v-btn class="close-modal" v-if="withClose" @click="toggleDialog" style="z-index:1; margin-bottom: 15px;">
          <i class="fal fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider v-if="cardTitle"></v-divider>
      <v-card-text
        class="align-start grow"
        style="overflow-y:auto !important;height: calc(100% - 196px)"
      >
        <!--:style="modalFooter ? 'max-height:calc(100% - (112px + 83px))' : 'max-height:calc(100% - 83px)'"-->
        <v-layout>
          <slot />
        </v-layout>
      </v-card-text>
      <v-card-actions class="shrink" v-if="modalFooter">
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="plain/text">
export default {
  props: {
    myMaxWidth: "",
    value: "",
    modalTitle: "",
    cardTitle: true,
    withClose: true,
    modalFooter: false,
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.dialog = this.value;
      },
      immediate: true,
    },
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
      this.$emit("input", this.dialog);
    },
  },
};
</script>
