const Permissions = {
	CRUDProject: 1,
	CRUDInstitution: 2,
	CRUDCompany: 3,
	CRUDUserAll: 4,
	CRUDUserCompany: 5,
	CRUDUserInstitution: 6,
	CRUDProjectAll: 7,
	CRUDCampaign: 8,
	CRUDCampaignAll: 9,
	CanChangeTicketCartStatus: 10,
	ListAllProjectsWithCaptureStatusOn: 11,
	UpdateInstitution: 12,
	UpdateCompany: 13,
	ConciliationRH: 14,
	CashFlow: 15,
	Dashboard: 16,
	CanSignReceipt: 17,
	CRUDCouncil: 18,
	CRUDUserCouncil: 19,
	UpdateCouncil: 20
};

export default Permissions;