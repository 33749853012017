import Vue from 'vue'
import money from 'v-money'
import axios from 'axios'
import App from './App.vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import mapInit from 'highcharts/modules/map'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import VueScrollactive from 'vue-scrollactive';
import router from './router'
import store from './store'
import Nav from "@/components/Nav.vue"
import Icon from './components/Icon.vue'
import faIcon from './components/faIcon.vue'
import Vuetify from 'vuetify'
import Steps from '@/components/Steps.vue'
import StepsVolunteering from '@/components/StepsVolunteering.vue'
import Loader from '@/components/Loader.vue'
import InputText from '@/components/input/InputText.vue'
import InputSelect from '@/components/input/InputSelect.vue'
import InputTextArea from '@/components/input/InputTextArea.vue'
import InputSearch from '@/components/input/InputSearch.vue'
import InputMultSelect from '@/components/input/InputMultSelect.vue'
import InputCheckBoxList from '@/components/input/InputCheckBoxList.vue'
import InputCheckBoxListWithMoneyInput from '@/components/input/InputCheckBoxListWithMoneyInput.vue'
import InputDate from '@/components/input/InputDate.vue'
import InputDateRange from '@/components/input/InputDateRange.vue'
import InputMoney from '@/components/input/InputMoney.vue'
import InputFile from '@/components/input/InputFile.vue'
import InputAutocomplete from '@/components/input/InputAutocomplete.vue'
import BorderSeparation from '@/components/BorderSeparation.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import ApiService from './scripts/services/api.service';
import FacebookService from './scripts/services/facebook.service';
import GoogleService from './scripts/services/google.service';
import TokenService from './scripts/services/token.service';
import Lightbox from 'vue-easy-lightbox';
import FileImageUpload from '@/components/FileImageUpload.vue';
import BlueLogger from 'blue-logger';
import uuid from 'uuid/v4';
import { codemirror } from "vue-codemirror";

import VueTheMask from "vue-the-mask";
import VueMoment from "vue-moment";

import Datepicker from "vue-airbnb-style-datepicker";
// import ViaCep from 'vue-viacep'
import moment from "moment-timezone";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { Routes } from "@/scripts/models/enums/routes.enum.js";

import "./scripts/filters/formatDocument.filter";
import "./scripts/filters/formatMoney.filter";
import "./scripts/filters/formatNumber.filter";
import "./scripts/filters/formatDate.filter";

import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";
import Slick from "vue-slick";
import "vuetify/dist/vuetify.min.css";
import "./assets/css/styl/main.styl";

import "../node_modules/slick-carousel/slick/slick.css";

// require styles
import "codemirror/lib/codemirror.css";
// language
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
// theme css
import "codemirror/theme/monokai.css";
// require active-line.js
import "codemirror/addon/selection/active-line.js";
import LoggerHelper from "./scripts/helpers/logger.helper";

Vue.config.productionTip = false;
//Initializing session
const sessionId = uuid();
Vue.prototype.sessionId = sessionId;

const configAppInsights = {
    instrumentationKey: process.env.VUE_APP_AZURE_INSTRUMENTATION_KEY,
    disableTelemetry: process.env.VUE_APP_AZURE_TELEMETRY_DISABLED,
};
const appInsights = new ApplicationInsights({ config: configAppInsights });
appInsights.loadAppInsights();
appInsights.trackPageView();

const logMinLevel = process.env.VUE_APP_LOG_LEVEL || 4;
const logUrl = process.env.VUE_APP_LOG_URL;

const logger = new LoggerHelper({
    minLevel: logMinLevel,
    sessionId,
    url: logUrl,
});

Vue.prototype.$logger = Vue.$logger = logger;

//Warning handler
Vue.config.warnHandler = (msg, vm, trace) => {
    console.warn("Warning handler fired!", msg);
    // logger.warn(msg, trace);
};

//Error handler
Vue.config.errorHandler = function (msg, vm, trace) {
    console.error("Exception handler fired!", msg);
    // logger.error(msg, trace);
};

stockInit(Highcharts);
mapInit(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);
Vue.use(HighchartsVue);

Vue.component('Nav', Nav)
Vue.component('Icon', Icon)
Vue.component('faIcon', faIcon)
Vue.component('Steps', Steps)
Vue.component('StepsVolunteering', StepsVolunteering)
Vue.component('InputText', InputText)
Vue.component('InputSelect', InputSelect)
Vue.component('InputTextArea', InputTextArea)
Vue.component('InputCheckBoxList', InputCheckBoxList)
Vue.component('InputCheckBoxListWithMoneyInput', InputCheckBoxListWithMoneyInput)
Vue.component('InputDate', InputDate)
Vue.component('InputDateRange', InputDateRange)
Vue.component('InputMultSelect', InputMultSelect)
Vue.component('InputMoney', InputMoney)
Vue.component('InputFile', InputFile)
Vue.component('InputAutocomplete', InputAutocomplete)
Vue.component('v-loader', Loader)
Vue.component('BorderSeparation', BorderSeparation)
Vue.component('InputSearch', InputSearch)
Vue.component('Slick', Slick)
Vue.component('FileImageUpload', FileImageUpload)
Vue.component('CodeMirror', codemirror)

Vue.use(Lightbox);
Vue.use(VueScrollactive);

Vue.use(money, { precision: 4 });
Vue.use(VueTheMask);
// Vue.use(ViaCep);
moment.locale("pt-BR");
Vue.use(VueMoment, {
    moment,
});

Vue.use(Routes);

Vue.use(Vuetify, {
    iconfont: "fa", // 'mdi' || 'md' || 'mdi' || 'fa' || 'fa4'
    options: {
        themeCache: {
            get: () => "",
        },
    },
});

// let protocol = window.location.protocol;
// let host = window.location.host;
// let url = protocol + '//' + host;
FacebookService.init("v6.0", "770587750378099", "facebook");
GoogleService.init(
    "989165121940-03du05efetpq0qqscdv51vrpndva2rk5.apps.googleusercontent.com",
    "T32JYjRUYTGw8qPDxCSoVyI3",
    "google"
);

// If token exists set header
if (TokenService.getToken()) {
    ApiService.setHeader();
}

let loadEntityId = function (to, from, next) {
    let loadedAnything = false;
    if (from.query.entityId && !to.query.entityId)
        (to.query.entityId = from.query.entityId), (loadedAnything = true);
    if (from.query.institutionId && !to.query.institutionId)
        (to.query.institutionId = from.query.institutionId),
            (loadedAnything = true);
    if (from.query.companyId && !to.query.companyId)
        (to.query.companyId = from.query.companyId), (loadedAnything = true);
    if (loadedAnything)
        router.push({ name: to.name, query: to.query, params: to.params });
    else next();
};

router.beforeEach((to, from, next) => {
    store.commit("SET_INCLUDE_OR_REMOVE_CSS_BASED_ON_ROUTE", to);
    let routesToLoadEntity = [
        Routes.app.ProjectList,
        Routes.app.ProjectStep0,
        Routes.app.ProjectStep1,
        Routes.app.ProjectStep2,
        Routes.app.ProjectStep3,
        Routes.app.ProjectStep4,
        Routes.app.ProjectStep50,
        Routes.app.ProjectStep51,
        Routes.app.ProjectGallery,
        Routes.app.ProjectDocuments,
        Routes.app.DashboardCompany,
        Routes.app.CampaignList,
        Routes.app.CampaignEdit,
        Routes.app.CampaignFAQ,
        Routes.app.CampaignLandingPageEdit,
        Routes.app.InstitutionProfile,
        Routes.app.CompanyProfile,
        Routes.app.HrConciliation,
        Routes.app.ResourceFlowList,
        Routes.app.ConciliationCompany,
        Routes.app.TransferToAUC,
        Routes.app.ConciliedByCompany,
        Routes.app.OnConciliationAUC,
        Routes.app.ConciliedAUC,
        Routes.app.PendentAcceptToInstitution,
        Routes.app.PendentTransferToInstitution,
        Routes.app.OnConciliationToInstitution,
        Routes.app.Concilied,
        Routes.app.Declined,
        Routes.app.StatusZero,
    ];
    if (routesToLoadEntity.includes(to.name)) {
        loadEntityId(to, from, next);
    } else {
        next();
    }
});

new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: "places", // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});

const datepickerOptions = {
    sundayFirst: false,
    dateLabelFormat: "dddd, MMMM D, YYYY",
    days: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo",
    ],
    daysShort: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
    monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
    colors: {
        selected: "#00a699",
        inRange: "#66e2da",
        selectedText: "#fff",
        text: "#565a5c",
        inRangeBorder: "#33dacd",
        disabled: "#fff",
        hoveredInRange: "#67f6ee",
    },
    texts: {
        apply: "Aplicar",
        cancel: "Cancelar",
    },
};

Vue.use(Datepicker, datepickerOptions);
Vue.prototype.$gtag = (
    action,
    event,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue = null
) => {
    //	console.log(eventLabel);
    if (typeof gtag === "function") {
        gtag("event", eventAction, {
            event_category: eventCategory,
            event_label: eventLabel,
            value: eventValue,
        });
    }
};

Vue.config.devtools = true;
