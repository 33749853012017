import BaseCrudService from "./baseCrud.service";

export default class FileService extends BaseCrudService {

	constructor() {
		super('File');
	}

	render(fileId) {
		return this.getRequest('Render', { id: fileId }, true, false);
	}

	getById(fileId) {
		return this.getRequest('GetById', { id: fileId }, true, false);
	}
}