import CheckoutService from "@/scripts/services/checkout.service";
import CartService from "@/scripts/services/cart.service";
import PaymentInstallment from "@/scripts/services/paymentInstallment.service";
import CampaignCheckout from "@/scripts/models/checkout/campaignCheckout.model";
import Checkout from "@/scripts/models/checkout/checkout.model";
import USDDonationCheckout from "@/scripts/models/checkout/usdDonationCheckout.model";

const CheckoutModule = {
    state: {
        service: new CheckoutService(),
        cartService: new CartService(),
        paymentInstallment: new PaymentInstallment(),
        campaign: new CampaignCheckout(),
        usdDonation: new USDDonationCheckout(),
        checkout: new Checkout(),
        itemsToCheckout: [],
        tabs: [],
        blockCartContinue: false,
        checkoutValue: 0,
        paymentType: null,
        checkoutConsentTerm: false,
        consentTermIsChecked: false,
        coins: [
            { id: "USD", name: "USD (Dólar)", prefix: "US$ " },
            { id: "BRL", name: "BRL (Real)", prefix: "R$ " },
        ],
        coinSelected: "BRL",
        recurrency: 2,
        checkMinimumValueOff: false,
        consentTermText: ''
    },
    mutations: {
        CAMPAIGN_CHECKOUT_DATA: (state, payload) => {
            state.campaign = payload
        },
        SET_USD_DONATION: (state, payload) => {
            state.usdDonation = payload
        },
        SET_HAS_PROJECT_INCETIVED: (state, payload) => {
            state.hasProjectIncetived = payload
        },
        SET_TO_CHECKOUT: (state, payload) => {
            let index = null;
            
            let { itemValue, ...data} = payload
            itemValue = (itemValue).replaceAll('.','').replaceAll(',','.')
            data.itemValue = itemValue

            state.itemsToCheckout.forEach((item, i) => {
                const hasOnlyProject = item.id === 0

                if (!hasOnlyProject && item.id === data.id) {
                    index = i
                }

                if (hasOnlyProject && item.projectId === data.projectId) {
                    index = i
                }
            })

            if (index !== null && itemValue !== '0') {
                state.itemsToCheckout[index].itemValue = itemValue;
                return;
            } 
            
            else if (index !== null && itemValue === '0') {
                state.itemsToCheckout.splice(index, 1)
                return
            } 
            
            else if (itemValue !== '0') {
                state.itemsToCheckout.push(data)
            }
        },
        REMOVE_FROM_CHECKOUT: (state, payload) => {
            let index = null

            state.itemsToCheckout.forEach((item, i) => {
                if (item.id === payload.id) {
                    index = i
                }
            })

            if (index !== null) {
                state.itemsToCheckout.splice(index, 1)
            }
        },
        CLEAR_CHECKOUT: (state) => {
            state.itemsToCheckout = [];
        },
        SET_CHECKOUT_VALUE: (state) => {
            state.checkoutValue = 0;
            let value = 0
            
            state.itemsToCheckout.forEach((item) => {
                value += parseFloat((item.itemValue))
            })
            state.checkoutValue = value
        },
        SET_PAYMENT_TYPE: (state, payload) => {
            state.paymentType = payload
        },
        CONSENT_TERM_DIALOG: (state, payload = null) => {
            if (!state.consentTermIsChecked) state.checkoutConsentTerm = true
            if (payload !== null) state.checkoutConsentTerm = payload
        },
        SET_CONSENT_TERM_CHECKED: (state, payload = null) => {    
            if (payload === null) state.consentTermIsChecked = !state.consentTermIsChecked
            else state.consentTermIsChecked = payload
        },
        SET_RECURRENCY: (state, payload) => {
            state.recurrency = payload
        },
        SET_CHECK_MINIMUM_VALUE_OFF: (state, payload) => {
            state.checkMinimumValueOff = payload
        },
        CLEAR_CHECKOUT_VALUES: (state) => {
            state.itemsToCheckout.forEach((item) => {
                item.itemValue = '0,00'
            })
        },
        SET_CONSENT_TERM: (state, payload) => {
            state.consentTermText = payload
        },
        SET_TABS: (state, payload) => {
            state.tabs = payload
        }
    },
    getters: {
        campaignCheckoutData: state => {
            return state.campaign
        },
        usdDonationInfo: state => {
            return state.usdDonation
        },
        hasProjectIncetived: state => {
            return state.hasProjectIncetived
        },
        itemsToCheckout: (state) => {
            return state.itemsToCheckout
        },
        checkoutValue: (state) => {
            state.checkoutValue = 0
            if (state.itemsToCheckout.length > 0) {
                state.itemsToCheckout.forEach((item) => {
                    state.checkoutValue += parseFloat(item.itemValue)
                })
            }

            return state.checkoutValue
        },
        paymentType: state => {
            return state.paymentType;
        },
        coinObject: state => {
            for (const coin of state.coins) {
                if (coin.id === state.coinSelected) return coin
            }
        },
        checkoutConsentTerm: state => {
            return state.checkoutConsentTerm;
        },
        consentTermIsChecked: state => {
            return state.consentTermIsChecked;
        },
        recurrency: state => {
            return state.recurrency;
        },
        checkMinimumValueOff: state => {
            return state.checkMinimumValueOff
        },
        generalConsentTerm: state => {
            return state.consentTermText
        },
        tabs: (state) => {
            return state.tabs;
        }
    },
    actions: {
        loadCampaignCheckoutData: async state => {
            try {
                if (state.state.campaign.id != 0) return;
                var campaignData = await state.state.service.getCampaignDataForCheckout();
                state.commit('CAMPAIGN_CHECKOUT_DATA', campaignData)
            } catch (error) {
                throw new Error("Erro ao carregar dados do checkout");
            }
        },
        toggleUSDDonation: async (state, enabled) => {
            try {
                if (enabled) {
                    var dollar = await state.state.cartService.getDolarPrice();
                    var usDonation = new USDDonationCheckout();
                    usDonation.enabled = enabled;
                    usDonation.dollar = dollar.bid;
                    usDonation.coin = 'USD';
                    usDonation.prefix = 'USD$'
                    state.commit('SET_USD_DONATION', usDonation)
                } else
                    state.commit('SET_USD_DONATION', new USDDonationCheckout())
            } catch (error) {
                throw new Error("Erro ao trocar moeda");
            }
        },
        checkIfHasIncetivedProject: async state => {
            try {
                var cartItems = await state.state.cartService.getCart()
                
                const value = cartItems
                    .map(function (el) {
                        return el.project.incentiveLawId;
                    })
                    .filter(function (r) {
                        return r != null;
                    }).length > 0;

                state.commit('SET_HAS_PROJECT_INCETIVED', value)
            } catch (error) {
                throw new Error("Erro ao carregar dados do carrinho");
            }
        },
        setToCheckout: (state, payload) => {
            state.commit('SET_TO_CHECKOUT', payload)
            state.commit('SET_CHECKOUT_VALUE')
        },
        clearCheckout: (state) => {
            state.commit('CLEAR_CHECKOUT')
        },
        setPaymentType: (state, payload) => {
            state.commit('SET_PAYMENT_TYPE', payload)
            state.commit('SET_CART_PAYMENT_METHOD', payload)
            state.commit('SET_CONSENT_TERM_CHECKED', false)
        },
        setConsentTermIsChecked: (state, payload = null) => {
            state.commit('SET_CONSENT_TERM_CHECKED', payload)
        },
        setConsentTermDialog: (state, payload = null) => {
            state.commit('CONSENT_TERM_DIALOG', payload)
        },
        setRecurrency: (state, payload) => {
            state.commit('SET_RECURRENCY', payload)
        },
        setCheckMinimumValueOff: (state, payload) => {
            state.commit('SET_CHECK_MINIMUM_VALUE_OFF', payload)
        },
        clearCheckoutValues: (state) => {
            state.commit('CLEAR_CHECKOUT_VALUES')
            state.commit('SET_CHECKOUT_VALUE')
        },
        setConsentTerm: (state, payload) => {
            state.commit('SET_CONSENT_TERM', payload)
        },
        setTabs: ({ getters, commit }) => {
            const allTabs = {
                donation: {
                    title: "Valor",
                    icon: "dollar-sign",
                    active: true,
                    show: true
                },
                // bonus: {
                //   title: "Bonus",
                //   icon: "credit-card",
                //   active: true,
                // },
                acknowledgement: {
                    title: "Agradecimento",
                    icon: "hand-holding-heart",
                    active: true,
                    show: false
                },
                resume: {
                    title: "Pagamento",
                    icon: "credit-card",
                    active: true,
                    show: true
                },
            }

            const tabToSend = []

            Object.keys(allTabs).map((slot) => {
                
                if (allTabs[slot].active) {
                    tabToSend.push({
                        slot,
                        ...allTabs[slot],
                    })
                }
                
                // If there is a campaign donation product, show the acknowledgement tab
                if (slot === 'acknowledgement' && (getters.campaignDonationProduct !== null
                    && getters.campaignDonationProduct !== undefined &&
                    getters.campaignDonationProduct.campaignProductQuestionList.length)) {
                    tabToSend[1].show = true
                }
            })

            commit('SET_TABS', tabToSend)
        }
    }
}

export default CheckoutModule;