import BaseCrudService from "./baseCrud.service";
import axios from 'axios';
import Cep from 'cep-promise'

export default class LocationService extends BaseCrudService {
    constructor() {
        super("Location");
    }

    listAllState(callback) {
        this.getRequest("ListAllState").then(callback);
    }

    listAllCities() {
        return this.getRequest("ListAllCities");
    }

    searchCEP(cep){
        return Cep(cep);
    }
}