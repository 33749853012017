<template>
  <nav class="steps" :page-options="pageOptions">
    <template v-for="(stepNumber, index) in steps">
      <router-link
        v-bind:key="index"
        v-if="project.stepsValidationStatus['step'+stepNumber]"
        class="done"
        :to="{ name: pages[stepNumber], query: { projectId: project.id } }"
      >
        <faIcon type="fas" name="check" colorFon="white" />
      </router-link>
      <router-link
        v-bind:key="index"
        v-else-if="(index + 1) == pageOptions.currentPage && !project.stepsValidationStatus['step'+stepNumber]"
        class="active"
        :to="{ name: pages[stepNumber] }"
      >{{ (index + 1) }}</router-link>
      <router-link
        v-bind:key="index"
        v-else
        :to="{ name: pages[stepNumber], query: { projectId: project.id } }"
      >{{ (index + 1) }}</router-link>
    </template>
  </nav>
</template>

<script type="plain/text">
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum.js";
export default {
  props: ["pageOptions", "project"],
  computed: {
    steps() {
      if (this.project.projectTypeId == ProjectTypes.Ticket) return [1, 2];
      return [1, 2, 3, 4, 5];
    }
  },
  data() {
    return {
      pages: [
        Routes.app.ProjectStep0,
        Routes.app.ProjectStep1,
        Routes.app.ProjectStep2,
        Routes.app.ProjectStep3,
        Routes.app.ProjectStep4,
        Routes.app.ProjectStep51
      ]
    };
  }
};
</script>
