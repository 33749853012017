import store from "@/store";
import LoginService from "@/scripts/services/login.service";

const SHOW_PERSON_INFO = 'showPersonInfoUsers'
const loginService = new LoginService();

const PersonInfoStorage = {

    setShowPersonInfo(state){
        let user = loginService.getLoggedUser();
        
        if (user) {
            let showPersonInfo = this.getShowPersonInfo();
            if(showPersonInfo == null){
                showPersonInfo = {};
                showPersonInfo[user] = state;
            }else if(showPersonInfo[user] == null){
                showPersonInfo[user] = state;
            }else if(showPersonInfo[user] == false){
                return;
            }else{
                showPersonInfo[user] = state;
            }
    
            localStorage.setItem(SHOW_PERSON_INFO, JSON.stringify(showPersonInfo));
        }
    },
    setShowPersonInfoNow(state){
        let user = loginService.getLoggedUser();

        if (user) {
            let showPersonInfo = this.getShowPersonInfo();
            if(showPersonInfo == null){
                showPersonInfo = {};
                showPersonInfo[user] = state;
            }else if(showPersonInfo[user] == null){
                showPersonInfo[user] = state;
            }else{
                showPersonInfo[user] = state;
            }
    
            localStorage.setItem(SHOW_PERSON_INFO, JSON.stringify(showPersonInfo));
        }
    },
    getShowPersonInfo(){
        let data = localStorage.getItem(SHOW_PERSON_INFO);
        if(data == "")
         return null;
        return JSON.parse(data);
    },
    verify(){
        let user = loginService.getLoggedUser();
        let showPersonInfo = this.getShowPersonInfo();
        if(showPersonInfo == null || showPersonInfo[user] == null){
            return false;
        }
        if (user) {
            return showPersonInfo[user];
        }

        return false;
    }
}

export default PersonInfoStorage;