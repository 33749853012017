<template>
  <!-- <v-dialog ref="dialog" v-model="modal" full-width ma-auto persistent lazy width="290px">
    <template v-slot:activator="{ on }">
      <div class="p-relative">
        <a href="javascript:;" class="clean-date" @click="clearDate">
          <Icon :name="links.icon" :size="16" colorFont="#80849A" />
        </a>
        <v-text-field
          ref="input"
          v-on="on"
          :value="formattedDate"
          :label="textLabel"
          prepend-icon="event"
          :placeholder="placeHolder"
          readonly
        ></v-text-field>
        <v-flex justify-space-between d-flex>
          <label
            v-if="showValidationMessage"
            class="d-flex justify-end msg-validation"
            :key="showValidationMessage"
          > {{invalidInputMessage}}</label>
          <label v-if="helpLabel && !showValidationMessage" class="d-flex justify-end">{{helpLabel}}</label>
        </v-flex>
      </div>
    </template>
    <v-date-picker v-model="date" scrollable :disabled="disabled" :max="max" :min="min">
      <v-spacer></v-spacer>
      <v-btn flat class="btn btn-default text-white round" @click="cancel">Cancel</v-btn>
      <v-btn flat class="btn btn-primary text-white round" @click="save">OK</v-btn>
    </v-date-picker>
  </v-dialog>-->
  <div>
    <v-menu
      ref="menu1"
      v-model="modal"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
      location="top"
    >
      <template v-slot:activator="{ on }">
        <div class="input-comp">
          <v-flex d-flex justify-space-between w-100 v-if="textLabel">
            <label v-if="textLabel" class="d-flex">{{ textLabel }}{{ required ? ' *' : '' }}</label>
          </v-flex>
          <div class="inside-input">
            <input
              :disabled="disabled"
              :placeholder="placeHolder"
              ref="input"
              type="text"
              v-model="dateDDMMYYYY"
              v-mask="'##/##/####'"
            />
            <i class="fal fa-calendar" v-on="on"></i>
          </div>
        </div>
      </template>
      <v-date-picker
        v-model="dateYYYYMMDD"
        no-title
        locale="pt-br"
        scrollable
        :disabled="disabled"
        :max="max"
        :min="min"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script type="plain/text">
import format from "date-fns/format";
import parse from "date-fns/parse";
import ValidatableInput from "@/components/base/ValidatableInput.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  extends: ValidatableInput,
  props: {
    placeHolder: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: "",
      modal: false,
      links: {
        icon: "fal fa-times",
        text: "CleanDate",
      },
      formatterHelper: new FormatterHelper(),
    };
  },
  watch: {
    value() {
      if (this.dateYYYYMMDD == null && this.dateDDMMYYYY == null)
        this.dateYYYYMMDD = this.dateDDMMYYYY = this.value;
    },
  },
  methods: {
    splitDate(dataString) {
      dataString = dataString.slice(0, 10)
      const formatoDDMMYYYY = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const formatoYYYYMMDD = /^(\d{4})-(\d{2})-(\d{2})$/;

      let dia, mes, ano;

      if (formatoDDMMYYYY.test(dataString)) {
        [, dia, mes, ano] = dataString.match(formatoDDMMYYYY);
      } else if (formatoYYYYMMDD.test(dataString)) {
        [, ano, mes, dia] = dataString.match(formatoYYYYMMDD);
      } else {
        throw new Error(
          "Formato de data inválido. Use DD/MM/YYYY ou YYYY-MM-DD."
        );
      }
      return { dia, mes, ano };
    },
    formatDDMMYYYY(date) {
      if (this.isValidDate(date)){
        const splited = this.splitDate(date);
        return `${splited.dia}/${splited.mes}/${splited.ano}`
      }        
      return null;
    },
    formatYYYYMMDD(date) {
      if (this.isValidDate(date)){
        const splited = this.splitDate(date);
        return `${splited.ano}-${splited.mes}-${splited.dia}`
      }        
      return null;
    },
    cancel() {
      this.modal = false;
    },
    save() {
      this.$emit("input", this.date);
      this.modal = false;
    },
    clearDate() {
      this.date = "";
      this.$emit("input", null);
    },
    isValidDate(date) {
      if (!date || date.length < 10) return false;
      const dateRegex =
        /^(?:(?:(?:0?[1-9]|1\d|2[0-8])\/(?:0?[1-9]|1[0-2])|(?:29|30)\/(?:0?[13-9]|1[0-2])|31\/(?:0?[13578]|1[02]))\/(?:19|[2-9]\d)\d{2}|(?:19|[2-9]\d)\d{2}-(?:(?:0?[1-9]|1[0-2])-(?:0?[1-9]|1\d|2[0-8])|(?:0?[13-9]|1[0-2])-29|(?:(?:0?[1-9]|1[0-2])-(?:29|30))|(?:(?:0?[13578]|1[02])-31)))$/;
      return dateRegex.test(date.substring(0, 10));
    },
  },
  computed: {
    dateDDMMYYYY: {
      get() {
        return this.formatDDMMYYYY(this.date)
      },
      set(value) {
        if(value == ""){
          this.date = value;
          this.save();
        } 
        if (!this.isValidDate(value)) return;
        this.date = this.formatYYYYMMDD(value);
        this.save();
      },
    },
    dateYYYYMMDD: {
      get() {
        return this.formatYYYYMMDD(this.date)
      },
      set(value) {
        if (!this.isValidDate(value)) return;
        this.date = this.formatYYYYMMDD(value);
        this.save();
      },
    },
  },
};
</script>
