// Services
import DocService from "@/scripts/services/doc.service";
import ProjectService from "@/scripts/services/project.service.js";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Router
import router from "../../router";

const ManualReceiptsModule = {
    state: {
      docService: new DocService(),
      projectService: new ProjectService(),
      formatter: new FormatterHelper(),
      isPersonConfirm: Boolean,
      isAnEdit: Boolean,
      listDialog: Boolean,
      personDialog: Boolean,
      personsList: Object,
      personsListBackup: Object,
      personToEdit: Object,
      selectedPerson: Object,
      personAdded: Object,
      receipts: [],
      receiptsBackup: [],
      personsComboboxList: [],
      projects: [],
      isFromReceipt: Boolean,
      projectsList: [],
      newPersonFormKey: 0,
      person: {
        id: 0,
        donator: '',
        document: '',
        institutionId: 0,
        operationType: '',
        campanyType: '',
        businessGroup: null,
        businessGroupName: null,
        manager: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        uf: '',
        complement: '',
        zip: '',
        phone: '',
      },
    },
    mutations: {
        SET_IS_PERSON_CONFIRM: (state, payload) => {
          state.isPersonConfirm = payload;
        },
        SET_IS_EDIT: (state, payload) => {
          state.isAnEdit = payload;
        },
        CLOSE_PERSON_DIALOG: (state) => {
          state.personDialog = false;
        },
        OPEN_PERSON_DIALOG: (state) => {
          state.personDialog = true;
        },
        CLOSE_LIST_DIALOG: (state) => {
          state.listDialog = false;
        },
        OPEN_LIST_DIALOG: (state) => {
          state.listDialog = true;
        },
        GET_PERSONS_LIST: async (state) => {
          state.personsListBackup = await state.docService.listAllReceiptManualPersonByInstitutionId(router.currentRoute.query.institutionId);
          state.personsList = state.personsListBackup.filter((p) => p.active);
        },
        SET_PERSONS_LIST: (state, payload) => {
          state.personsList = payload;
        },
        LOAD_PERSONS_COMBOBOX: (state, payload) => {
          state.personsComboboxList = []
          state.docService.listAllReceiptManualPersonByInstitutionId(router.currentRoute.query.institutionId, (response) => {
            for (let p of response) {
              if (p.active) {
                
                p.text = ((p.cpf ? state.formatter.formatCpf(p.cpf) : state.formatter.formatCnpj(p.cnpj)) + ' - ' + p.donator)
                p.notFormated = p.cpf ?  p.cpf : p.cnpj + ' - ' + p.donator
                state.personsComboboxList.push(p)
                
                if (state.isFromReceipt && payload && (payload.document.replace(/[^\d]+/g, '') == p.cpf || payload.document.replace(/[^\d]+/g, '') == p.cnpj)) {
                  const pCopy = {...p} 
                  state.personAdded = pCopy
                  state.selectedPerson = payload
                  state.selectedPerson.document = (state.selectedPerson.cpf ? state.selectedPerson.cpf : state.selectedPerson.cnpj)
                  //state.dispatch('setSelectedPerson', pCopy)
                  //state.dispatch('updateSelectedPerson')
                }
              }
              
              
            }
          })
        },
        SET_PERSON_TO_EDIT: (state, payload) => {
            payload.cpf ? (payload.document = payload.cpf) : (payload.document = payload.cnpj)
            state.person = payload
        },
        CLEAR_PERSON: (state) => {
          if (!state.isAnEdit) {
            for (let key in state.person) {
              if (key === 'document') return
              key === 'id' ? state.person[key] = 0 : state.person[key] = ''
            }
          }
        },
        CLEAR_ALL_PERSON: (state) => {
          if (!state.isAnEdit) {
            for (let key in state.person) {
              key === 'id' ? state.person[key] = 0 : state.person[key] = ''
            }
          }
        },
        SET_PERSON_DOCUMENT: (state, payload) => {
          state.person.document = payload
        },
        LOAD_MANUAL_RECEIPTS: (state) => {
          state.docService.listReceiptManualByInstitutionId(router.currentRoute.query.institutionId).then((response) => {
            state.projectsList = []
            state.receipts = response
            
            for (const receipt of state.receipts) {
                if (state.projectsList.indexOf(receipt.projectName) === -1) state.projectsList.push(receipt.projectName)
            }

            state.projectsList.push('Todos')
            state.receiptsBackup = state.receipts
          })
        },
        SET_RECEIPTS: (state, payload) => {
          state.receipts = payload;
        },
        SET_SELECTED_PERSON: (state, payload) => {
            state.selectedPerson = payload
            state.selectedPerson.document = (state.selectedPerson.cpf ? state.selectedPerson.cpf : state.selectedPerson.cnpj)
        },
        UPDATE_SELECTED_PERSON: (state) => {
          if (state.selectedPerson.document)
            state.docService.listAllReceiptManualPersonByDocument(router.currentRoute.query.institutionId, state.selectedPerson.document, (response) => {
              state.selectedPerson = response[0]
              state.selectedPerson.document = (state.selectedPerson.cpf ? state.selectedPerson.cpf : state.selectedPerson.cnpj)
              delete state.selectedPerson.id
          })
        },
        LOAD_PROJECTS: (state) => {
          state.projects = []
          state.projectService.listByEntity(router.currentRoute.query.entityId).then((res) => {
            res.forEach(project => {
              if (project.incentiveLawId !== null) {
                state.projects.push(project);
              }
            });
          });
        },
        // Detect if new user came from new receipt or not
        SET_IS_FROM_RECEIPT: (state, payload) => {
          state.isFromReceipt= payload
        },
        SET_PERSON_ADDED: (state, payload) => {
          state.personAdded = payload;
        },
        SET_NEW_PERSON_FORM_KEY: (state) => {
          state.newPersonFormKey += 1;
        }
    },
    getters: {
        isPersonConfirm: state => {
          return state.isPersonConfirm;
        },
        isAnEdit: state => {
          return state.isAnEdit;
        },
        personDialog: state => {
          return state.personDialog;
        },
        listDialog: state => {
          return state.listDialog;
        },
        personsList: state => {
          return state.personsList;
        },
        personsListBackup: state => {
          return state.personsListBackup;
        },
        personToEdit: state => {
          return state.personToEdit;
        },
        person: state => {
          return state.person;
        },
        personDocument: state => {
          return state.person.document
        },
        personsComboboxList: state => {
          return state.personsComboboxList;
        },
        selectedPerson: state => {
          return state.selectedPerson;
        },
        projects: state => {
          return state.projects;
        },
        projectsList: state => {
          return state.projectsList;
        },
        receipts: state => {
          return state.receipts;
        },
        isFromReceipt: state => {
          return state.isFromReceipt;
        },
        personAdded: state => {
          return state.personAdded;
        },
        receiptsBackup: state => {
          return state.receiptsBackup;
        },
        newPersonFormKey: state => {
          return state.newPersonFormKey;
        }
    },
    actions: {
        setIsPersonConfirm(state, payload) {
          state.commit('SET_IS_PERSON_CONFIRM', payload);
        },
        setIsEdit(state, payload) {
          state.commit('SET_IS_EDIT', payload);
        },
        closePersonDialog(state) {
          state.commit('CLOSE_PERSON_DIALOG');
          state.commit('SET_IS_PERSON_CONFIRM', false);
        },
        openPersonDialog(state) {
          state.commit('OPEN_PERSON_DIALOG');
        },
        closeListDialog(state) {
          state.commit('CLOSE_LIST_DIALOG');
        },
        openListDialog(state) {
          state.commit('OPEN_LIST_DIALOG');
        },
        reloadPersonsList(state) {
          state.commit('GET_PERSONS_LIST')
        },
        setPersonsList(state, payload) {
          state.commit('SET_PERSONS_LIST', payload)
        },
        setPersonToEdit(state, payload) {
          state.commit('SET_PERSON_TO_EDIT', payload)
        },
        clearPerson(state) {
          state.commit('CLEAR_PERSON')
        },
        clearAllPerson(state) {
          state.commit('CLEAR_ALL_PERSON')
        },
        setPersonDocument(state, payload) {
          state.commit('SET_PERSON_DOCUMENT', payload)
        },
        loadPersonsCombobox(state, payload) {
          state.commit('LOAD_PERSONS_COMBOBOX', payload)
        },
        loadProjects(state) {
          state.commit('LOAD_PROJECTS')
        },
        loadManualReceipts(state) {
          state.commit('LOAD_MANUAL_RECEIPTS')
        },
        setSelectedPerson(state, payload) {
          state.commit('SET_SELECTED_PERSON', payload)
        },
        updateSelectedPerson(state) {
          state.commit('UPDATE_SELECTED_PERSON')
        },
        setIsFromReceipt(state, payload) {
          state.commit('SET_IS_FROM_RECEIPT', payload)
        },
        setPersonAdded(state, payload = null) {
          state.commit('SET_PERSON_ADDED', payload)
        },
        setReceipts(state, payload) {
          state.commit('SET_RECEIPTS', payload)
        },
        setNewPersonFormKey(state) {
          state.commit('SET_NEW_PERSON_FORM_KEY')
        }
    }
}

export default ManualReceiptsModule;