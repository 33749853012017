<template>
  <v-content>
    <UseTermsModal v-model="showServiceTerms"></UseTermsModal>
    <PrivacyPolicyTermsModal v-model="showPolicyTerms"></PrivacyPolicyTermsModal>
    <v-container>
      <v-layout>
        <div style="max-width: 500px;margin:0 auto" @click.once="gaLightBox()">
          <v-layout row wrap>
            <v-flex pa-4>
              <div class="head-login mb-4">
                <p>Escolha sua senha de acesso</p>
              </div>
              <div class="box-xs-form">
                <InputPassword
                  type="password"
                  v-model="password"
                  insideInput="Moderada"
                  textLabel="Escolha sua senha"
                  helpLabel="Sua senha deve conter ao menos 8 caracteres com letras e números."
                  ref="passwordInput"
                  @levelPassword="changeLevelPassword"
                  @input="disabledButton"
                  :force="true"
                />
              </div>

              <div class="box-xs-form mt-3">
                <InputPassword
                  type="password"
                  v-model="passwordConfirm"
                  insideInput="Moderada"
                  textLabel="Confirme sua senha"
                  ref="passwordConfirm"
                  @input="disabledButton"
                  :force="false"
                />
              </div>
              <v-layout row wrap>
                <div class="check-terms">
                  <v-checkbox
                    v-model="agreedWithTerms"
                    @change="disabledButton"
                    :label="`Aceito os `"
                  ></v-checkbox>
                </div>
                <div class="terms">
                  <a
                    href="javascript:;"
                    @click="showServiceTerms = !showServiceTerms; $gtag('send', 'event', 'Termos De Uso', 'Novo Cadastro', 'Link_Text')"
                  >termos de uso</a>
                </div>
                <div class="terms terms-color">e</div>
                <div class="terms">
                  <a
                    href="javascript:;"
                    @click="showPolicyTerms = !showPolicyTerms; $gtag('send', 'event', 'Política de Privacidade', 'Novo Cadastro', 'Link_Text')"
                  >política de privacidade.</a>
                </div>
              </v-layout>

              <div class="text-xs-center">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 mt-4"
                  large
                  @click="recoverPassword(); $gtag('send', 'event', 'Cadastrar Senha', 'Novo Cadastro', 'Button')"
                  :disabled="disableButtonRegister"
                >CADASTRAR</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputPassword from "@/components/input/InputPassword.vue";
import UserService from "@/scripts/services/user.service";
import PersonService from "@/scripts/services/person.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import CampaignService from "@/scripts/services/campaign.service";
import LoginService from "@/scripts/services/login.service";
import FileImage from "@/components/FileImage.vue";

export default {
  props: ["person", "landingPageConfig"],
  components: {
    Container,
    InputPassword,
    UseTermsModal,
    PrivacyPolicyTermsModal,
    FileImage
  },
  data() {
    return {
      password: "",
      passwordConfirm: "",
      agreedWithTerms: false,
      showPolicyTerms: false,
      showServiceTerms: false,
      levelPassword: 0,
      optin: true,
      userService: new UserService(),
      personService: new PersonService(),
      campaignService: new CampaignService(),
      loginService: new LoginService(),
      disableButtonRegister: true,      
    };
  },
  created() {
    this.$store.dispatch("setRegisterUser", this.person);
  },
  methods: {
    changeLevelPassword(levelPassword) {
      this.levelPassword = levelPassword;
    },
    disabledButton() {
      if (
        this.password.length >= 8 &&
        this.levelPassword >= 2 &&
        this.agreedWithTerms
      )
        if (this.password === this.passwordConfirm)
          this.disableButtonRegister = false;
        else this.disableButtonRegister = true;
      else this.disableButtonRegister = true;
    },
    recoverPassword() {
      if (this.person) {
        this.person.user.password = this.password;
        this.person.user.optin = this.optin;
        this.person.registerInline = true;
        this.personService.registerAndLogin(
          this.person,
          this.callbackRegisterAndLogin
        );
      } else if (this.$route.query.tempHash) {
        this.userService
          .recoverPassword(
            this.$route.query.tempHash,
            this.password,
            this.passwordConfirm,
            this.optin
          )
          .then(
            function (success) {
              this.$router.push({ name: Routes.app.DashboardPF });
            }.bind(this)
          );
      } else {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Usuário não cadastrado.");
      }
    },
    callbackRegister(person) {
      this.$store.dispatch("setRegisterUser", person);
      this.$emit("register");
    },
    callbackRegisterAndLogin(loginResult) {
      this.loginService.saveLoggedUser(this.person.email);
      this.$emit("registerAndLogin", loginResult);
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    },
    gaLightBox() {
      return ga(
        "send",
        "pageview",
        "/home/lightbox/acesso-conta/cadastro-senha"
      );
    },
  },
};
</script>