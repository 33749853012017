import store from '@/store';
import eachDay from 'date-fns/each_day'
import FormatterHelper from "@/scripts/helpers/formatter.helper"

const CommonHelper = {
	init() {
		this.$store = store;
	},
	showLoader() {
		this.$store.commit("SET_LOADER", true);
	},
	hideLoader() {
		this.$store.commit("SET_LOADER", false);
	},
	swal(title, message, type) {
		if (type == 'error' || !type) {
			this.$store.commit("SET_SNACKBAR_MESSAGE", message || title);
		}
		else if (type == 'success') {
			this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", message || title);
		}
		else if (type == 'warning') {
			this.$store.commit("SET_SNACKBAR_WARNING_MESSAGE", message || title);
		}
		else if (type == 'info') {
			this.$store.commit("SET_SNACKBAR_INFO_MESSAGE", message || title);
		}
	},
	isDevelopmentEnvironment() {
		if (process.env.VUE_APP_IS_DEVELOPMENT && process.env.VUE_APP_IS_DEVELOPMENT == 'true')
			return true;
		return false;
	},
	isReleaseEnvironment() {
		if (process.env.VUE_APP_IS_RELEASE && process.env.VUE_APP_IS_RELEASE == 'true')
			return true;
		return false;
	},
	isProductionEnvironment() {
		if (process.env.VUE_APP_IS_PRODUCTION && process.env.VUE_APP_IS_PRODUCTION == 'true')
			return true;
		return false;
	},
	getSubdomain() {
		let hostParts = window.location.host.split('.');
		if (hostParts[0].includes("novo") || hostParts[0].includes("homologacao") || hostParts[0].includes("dev") || hostParts[0].includes("localhost") || hostParts[0].includes("abraceumacausa"))
			return null;
		if(hostParts[0].includes("www") && hostParts[1].includes("lamffirpf"))
			return "www.lamffirpf";
		return hostParts[0];
	},
	clearInputFile(inputFile) {
		//INFELIZMENTE ISSO REALMENTE É NECESSÁRIO NO DIA 08/08/2019. REALMENTE TENTEI!
		if (inputFile) inputFile.type = 'text';
		if (inputFile) inputFile.type = 'file';
	},
	validateCPF(cpf) {
		cpf = cpf.replace(/[^\d]+/g, '');
		var numeros, digitos, soma, i, resultado, digitos_iguais;
		digitos_iguais = 1;
		if (cpf.length < 11) return false;
		for (i = 0; i < cpf.length - 1; i++)
			if (cpf.charAt(i) != cpf.charAt(i + 1)) {
				digitos_iguais = 0;
				break;
			}
		if (!digitos_iguais) {
			numeros = cpf.substring(0, 9);
			digitos = cpf.substring(9);
			soma = 0;
			for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
			resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
			if (resultado != digitos.charAt(0)) return false;
			numeros = cpf.substring(0, 10);
			soma = 0;
			for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
			resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
			if (resultado != digitos.charAt(1)) return false;
			return true;
		} else return false;
	},
	validateCNPJ(cnpj) {

		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj == '') return false;

		if (cnpj.length != 14)
			return false;

		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999")
			return false;

		// Valida DVs
		let tamanho = cnpj.length - 2
		let numeros = cnpj.substring(0, tamanho);
		let digitos = cnpj.substring(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (var i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(1))
			return false;

		return true;

	},
	validateEmail(email) {
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase());
	},
	validateCellPhone(phone) {
		var regex = /^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$/;
		if (phone.length <= 9)
			return false
		else if
			(phone == "0000000000" ||
			phone == "00000000000" ||
			phone == "1111111111" ||
			phone == "11111111111" ||
			phone == "2222222222" ||
			phone == "22222222222" ||
			phone == "3333333333" ||
			phone == "33333333333" ||
			phone == "4444444444" ||
			phone == "44444444444" ||
			phone == "5555555555" ||
			phone == "55555555555" ||
			phone == "6666666666" ||
			phone == "66666666666" ||
			phone == "7777777777" ||
			phone == "77777777777" ||
			phone == "8888888888" ||
			phone == "88888888888" ||
			phone == "9999999999" ||
			phone == "99999999999")
			return false
		else
			return regex.test(String(phone))
	},
	/*setCookie(cookieName, value) {
		Cookie.set(cookieName, value);
	},
	removeCookie(cookieName) {
		Cookie.set(cookieName, '');
	},
	getCookie(cookieName) {
		return Cookie.get(cookieName);
	},*/
	getAge(DOB) {
		var today = new Date();
		var birthDate = new Date(DOB);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	},
	validateYoutubeLink(url) {
		if (url != undefined || url != '') {
			var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			var match = url.match(regExp);
			return match && match[2].length == 11 ? match[2] : ''
		}
	},
	validURL(str) {
		var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
		if (!regex.test(str)) {
			return false;
		} else {
			return true;
		}
	},
	getSubstring(text, length) {
		return text && text.length > length ? text.substring(0, length - 3) + '...' : text
	},
	getDatesBetweenTwo(first, second, pattern = "YYYY-MM-DD") {
		const formatterHelper = new FormatterHelper();
		const dates = eachDay(first, second);
		const formatedDates = [];
		if (dates) {
			for (let i = 0; i < dates.length; i++) {
				formatedDates.push(formatterHelper.formatDateWithPattern(
					dates[i],
					pattern
				));
			}
			return formatedDates;
		}
		return null;
	},
	shuffleArray(array) {
		return array.sort(function() { return 0.5 - Math.random() });
	},
	calculateNumberOfFieldsBasedOnProjectFrequency(frequency, initialDate, finalDate) {
		var dateFrom = new Date(initialDate);
		var dateTo = new Date(finalDate);

		var months =
			(dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
			(dateTo.getMonth() - dateFrom.getMonth());
		return Math.ceil(
			months == 0 ? 1 : months / Number(frequency)
		);
		return array.sort(function () { return 0.5 - Math.random() });
	},
	validateBankAccount(ban) {
		debugger
		var Moip = window.Moip;
		var retorno = {};
		return Moip.BankAccount.validate({
			bankNumber: '341',
			agencyNumber: '8096',
			agencyCheckNumber: '',
			accountNumber: '48604',
			accountCheckNumber: '1',
			valid: function () {
				alert("Sucesso");
			},
			invalid: function (data) {
				debugger
				var errors = "Conta bancária inválida: \n";
				for (let i = 0; i < data.errors.length; i++) {
					errors += data.errors[i].description + "-" + data.errors[i].code + ")\n";
				}
				alert(errors);
			}
		});
		return retorno;
	}
}


/*var Cookie =
{
	set: function (name, value, days) {
		var domain, domainParts, date, expires, host;

		if (days) {
			date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else {
			expires = "";
		}

		host = location.hostname;

		if (host.split('.').length === 1 || host.split('.').reverse()[0] === 'localhost') {
			// no "." in a domain - it's localhost or something similar
			document.cookie = name + "=" + value + expires + "; path=/";
		}
		else {
			// Remember the cookie on all subdomains.
			//
			// Start with trying to set cookie to the top domain.
			// (example: if user is on foo.com, try to set
			//  cookie to domain ".com")
			//
			// If the cookie will not be set, it means ".com"
			// is a top level domain and we need to
			// set the cookie to ".foo.com"
			domainParts = host.split('.');
			let subdomain = domainParts.shift();
			domain = '.' + domainParts.join('.');

			if (subdomain == 'novo' || subdomain == 'homologacao' || subdomain == 'dev') {
				domain = '.' + subdomain + domain;
			}

			document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;

			// check if cookie was successfuly set to the given domain
			// (otherwise it was a Top-Level Domain)
			if (Cookie.get(name) == null || Cookie.get(name) != value) {
				// append "." to current domain
				domain = '.' + host;
				document.cookie = name + "=" + value + expires + "; path=/; domain=" + domain;
			}
		}
	},

	get: function (name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1, c.length);
			}

			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	},

	erase: function (name) {
		Cookie.set(name, '', -1);
	}
};*/

CommonHelper.init();

export default CommonHelper;